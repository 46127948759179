import React from "react";
import { useHistory } from "react-router-dom";

import BlogImage from "components/BlogPage/components/BlogImage";
import { Blog } from "components/BlogPage/types";

import styles from "./BlogListItem.module.scss";

export interface BlogListItemProps {
  className?: string;
  blog: Blog;
}

function BlogListItem(props: BlogListItemProps) {
  const {
    className,
    blog: { coverImage, slug, title, excerpt },
  } = props;
  const history = useHistory();

  function onReadMoreClicked() {
    if (!slug) {
      return;
    }

    history.push(`blog/${slug}`);
  }

  return (
    <div className={`${styles.container} ${className || ""}`}>
      {coverImage && (
        <BlogImage image={coverImage} className={styles.coverImage} />
      )}
      <span className={styles.title}>{title}</span>
      <span className={styles.excerpt}>{excerpt}</span>
      <div
        className={`${styles.readMoreButton} clickable`}
        onClick={onReadMoreClicked}
      >
        <img src={require("static/images/icons/icon-arrow--pink.png")} />
        <span>Read more</span>
      </div>
    </div>
  );
}

export default BlogListItem;
