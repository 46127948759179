import moment from "moment";
import React from "react";

import BlogImage from "components/BlogPage/components/BlogImage";

import { AuthorType } from "../types";
import styles from "./BlogAuthor.module.scss";

export interface BlogAuthorProps {
  className?: string;
  author: AuthorType;
  publishedAt?: string;
}

function BlogAuthor(props: BlogAuthorProps) {
  const {
    className,
    author: { name, picture },
    publishedAt,
  } = props;
  const publishedAtFormatted = publishedAt
    ? moment(publishedAt).format("MMM DD, YYYY")
    : "Not published yet";

  const avatar = picture.url
    ? picture
    : { url: require("static/images/user.png") };

  return (
    <div className={`${styles.container} ${className || ""}`}>
      <BlogImage className={styles.avatar} image={avatar} />
      <div className={styles.rightInfo}>
        <span className={styles.name}>{name}</span>
        <span className={styles.publishedAt}>{publishedAtFormatted}</span>
      </div>
    </div>
  );
}

export default BlogAuthor;
