import { isEmpty } from "lodash";
import qs from "query-string";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  api,
  retrieveAccessToken,
  storeTemporaryAccessToken,
  withApi,
} from "../api";
import { useAuthDetails } from "../components/auth";
import BlogPage from "../components/BlogPage";
import { PRIVACY_PAGE_URL } from "../constants";
import { useGetterSetter } from "../hooks/useGetterSetter";
import { useSentry } from "../hooks/useSentry";
import Account from "../pages/Account";
import Blogs from "../pages/Blogs";
import ForgotPassword from "../pages/ForgotPassword";
import LogIn from "../pages/LogIn";
import LogOut from "../pages/LogOut";
import NotFound from "../pages/NotFound";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/SignUp";
import UserDetails from "../pages/UserDetails";
import { getDeepValue, saveLoginIdentity, trackLoginSuccess } from "../utils";
import AuthRoute from "./AuthRoute";
import UnAuthRoute from "./UnAuthRoute";

const getAccessTokenFromUrlParams = () => {
  const parsedParams = qs.parse(window.location.search);
  return getDeepValue(parsedParams, "access_token");
};

const useLoadAccessTokenFromUrl = (api, setAccessToken) => {
  const apiInstance = useRef(api);

  const [checkedAccessToken, setCheckedAccessToken] = useState(false);
  const { refresh } = useAuthDetails();

  useEffect(() => {
    const accessTokenFromUrl = getAccessTokenFromUrlParams();
    if (!isEmpty(accessTokenFromUrl)) {
      const logUserInFromUrlToken = async () => {
        const res = await apiInstance.current.getUserData();
        const { user } = res.body;
        if (!isEmpty(user)) {
          saveLoginIdentity(user);
          trackLoginSuccess(user);
        }
        storeTemporaryAccessToken(accessTokenFromUrl);

        setAccessToken(accessTokenFromUrl);

        refresh();
      };
      logUserInFromUrlToken();
    }
    setCheckedAccessToken(true);
  }, [refresh, setAccessToken]);

  return checkedAccessToken;
};

const Routes = () => {
  const [accessToken, getAccessToken, setAccessToken] = useGetterSetter(
    retrieveAccessToken()
  );

  const [signUpCompleted, setSignUpCompleted] = useState(false);

  const withApiFn = useMemo(() => {
    return withApi(getAccessToken, setAccessToken);
  }, [getAccessToken, setAccessToken]);

  const apiInstance = useRef(api(getAccessToken, setAccessToken));

  const checkedAccessToken = useLoadAccessTokenFromUrl(
    apiInstance.current,
    setAccessToken
  );

  useSentry(apiInstance.current);

  useEffect(() => {
    async function checkSignUpCompleted() {
      try {
        const res = await apiInstance.current.getUserData();
        const { signup_state } = res.body;
        if (signup_state === "Complete") {
          return setSignUpCompleted(true);
        }

        return setSignUpCompleted(false);
      } catch (e) {
        setSignUpCompleted(false);
      }
    }

    if (accessToken) {
      checkSignUpCompleted();
    }
  }, [accessToken]);

  if (!checkedAccessToken) {
    return (
      <div
        style={{
          flex: 1,
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading
          type={"bubbles"}
          color={"#ff4074"}
          height={"4rem"}
          width={"4rem"}
        />
      </div>
    );
  }

  const AuthRouteComponent = (props) => (
    <AuthRoute
      authorized={!isEmpty(accessToken)}
      setAccessToken={setAccessToken}
      {...props}
    />
  );

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/log_in" />} />

      <UnAuthRoute
        exact
        path="/log_in"
        authorized={!isEmpty(accessToken)}
        signUpCompleted={signUpCompleted}
        render={withApiFn(LogIn)}
      />

      <UnAuthRoute
        exact
        path="/logIn"
        authorized={!isEmpty(accessToken)}
        signUpCompleted={signUpCompleted}
        render={withApiFn(LogIn)}
      />

      <UnAuthRoute
        exact
        path="/signup"
        authorized={!isEmpty(accessToken)}
        signUpCompleted={signUpCompleted}
        render={withApiFn(SignUp)}
      />

      <Route exact path="/forgot_password" component={ForgotPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/forgotPassword" component={ForgotPassword} />

      <Redirect from="/whats_included" to="/details" />
      <Redirect from="/whats-included" to="/details" />
      <Redirect from="/whatsIncluded" to="/details" />

      <Redirect from="/support" to="/details" />

      <Redirect from="/contact" to="/details" />

      <Route
        from="/privacy-policy"
        render={() => window.location.replace(PRIVACY_PAGE_URL)}
      />
      <Redirect from="/privacy_policy" to="/privacy-policy" />
      <Redirect from="/privacypolicy" to="/privacy-policy" />

      <Redirect exact from={"/termsofuse"} to={"/documents/terms.pdf"} />
      <Redirect exact from={"/terms_of_use"} to={"/documents/terms.pdf"} />
      <Redirect exact from={"/terms-of-use"} to={"/documents/terms.pdf"} />

      <AuthRouteComponent
        exact
        path="/details"
        render={withApiFn(UserDetails)}
      />

      <AuthRouteComponent
        exact
        path="/completed-application"
        render={withApiFn(UserDetails)}
      />

      <Route exact path="/reset_password" component={ResetPassword} />

      <AuthRouteComponent exact path="/logout" render={withApiFn(LogOut)} />

      <AuthRouteComponent exact path="/account" render={withApiFn(Account)} />

      <AuthRouteComponent
        exact
        path="/account/:tab"
        render={(props) =>
          withApiFn(Account)({ ...props, tab: props.match.params.tab })
        }
      />

      <Route
        path="/blog"
        render={({ match: { path } }) => {
          return (
            <>
              <Route path={`${path}/`} exact={true} component={Blogs} />
              <Route path={`${path}/:slug`} component={BlogPage} />
            </>
          );
        }}
      />

      <Redirect from="/get_in_touch" to="/details" />

      <Redirect from="/howitworks" to="/details" />

      <Route
        path={"/community"}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
      />
      <Redirect from="/community" to="/details" />

      <Redirect from="/update-payment-details" to="/details" />

      <Route path="/&dID=*">
        <Redirect to="/details" />
      </Route>

      <Redirect from="/documents/privacy.pdf" to="/privacy-policy" />

      <Route
        path="/documents/terms.pdf"
        render={() => {
          window.location.reload();
          return null;
        }}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
