import React from "react";

import { KitItemSizeType } from "api/types";

import styles from "./SizeButton.module.scss";

type SizeButtonProps = {
  className?: string;
  size: KitItemSizeType;
  selected: boolean;
  onClick: (size: KitItemSizeType) => void;
};

function SizeButton(props: SizeButtonProps) {
  const { size, selected, onClick, className } = props;

  return (
    <div
      className={`${styles.container} ${
        selected ? styles.selectedContainer : ""
      } ${className || ""}`}
      onClick={() => onClick(size)}
    >
      <span>{size.size}</span>
    </div>
  );
}

export default SizeButton;
