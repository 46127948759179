import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  authorized: boolean;
  signUpCompleted: boolean;
}

function UnAuthRoute(props: Props) {
  const { authorized, signUpCompleted, render } = props;

  return (
    <Route
      {...props}
      render={({ location, ...rest }) =>
        !authorized ? (
          render && render({ location, ...rest })
        ) : (
          <Redirect
            to={{
              pathname: signUpCompleted ? "/account" : "/details",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default UnAuthRoute;
