import React from "react";

import styles from "./CanWeHelp.module.scss";

export interface CanWeHelpProps {
  onContinue: () => void;
  onCancel: () => void;
}

interface ContactItemProps {
  icon: string;
  text: string;
  onClick: () => void;
}

function ContactItem(props: ContactItemProps) {
  const { icon, text, onClick } = props;

  return (
    <div
      className={`${styles.contactItemContainer} clickable`}
      onClick={onClick}
    >
      <img src={icon} className={styles.contactItemIcon} />
      <span className={styles.contactItemText}>{text}</span>
    </div>
  );
}

function CanWeHelp(props: CanWeHelpProps) {
  const { onContinue, onCancel } = props;

  const onPhoneNumberClick = () => {
    window.open("tel:02080655398");
  };

  const onChatClick = () => {
    window.fcWidget.open();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Can we help?</h1>
        <span className={styles.headerSubTitle}>
          We would love to hear your feedback and try and address your issue.
          <br />
          <br className={"reverse-responsive-break"} />
          Hit the help button below and someone from the Rider Happiness Team
          will be in touch. <br className={"responsive-break"} />
          You can also reach out to us, in any of the places below.
        </span>
      </div>

      <div className={styles.contactItems}>
        <ContactItem
          icon={require("static/images/icons/icon-call.svg")}
          text={"020 8065 5398"}
          onClick={onPhoneNumberClick}
        />
        <ContactItem
          icon={require("static/images/icons/icon-chat.svg")}
          text={"Chat"}
          onClick={onChatClick}
        />
        <ContactItem
          icon={require("static/images/icons/icon-mail.svg")}
          text={"hello@buzzbike.cc"}
          onClick={onCancel}
        />
      </div>

      <div className={styles.bottomButtons}>
        <div
          className={`${styles.button} ${styles.helpButton}`}
          onClick={onCancel}
        >
          Help solve my issue
        </div>
        <div
          className={`${styles.button} ${styles.cancelButton}`}
          onClick={onContinue}
        >
          I still want to cancel
        </div>
      </div>
    </div>
  );
}

export default CanWeHelp;
