import "./index.css";

import { createInstance } from "@optimizely/react-sdk";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const optimizelySdkKey = process.env.REACT_APP_OPTIMIZELY_SDK_KEY;

if (optimizelySdkKey) {
  const optimizelyClientInstance = createInstance({
    datafile: window.optimizelyDatafile,
  });
  window.optimizelyClientInstance = optimizelyClientInstance;
}

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

window.analytics.load(process.env.REACT_APP_SEGMENT_API_KEY);
window.analytics.page();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
