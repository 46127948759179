import { isEmpty, sortBy } from "lodash";
import moment from "moment";

import { DATE_FORMAT } from "constants/index";
import { getMinDeliveryDate } from "pages/UserDetails/formFields";

export function dateToMoment(v: string | moment.MomentInput) {
  let date;
  if (typeof v === "string") {
    date = moment(v, ["DD/MM/YYYY", "DD-MM-YYYY", "YYYY-MM-DD"], true);
    return date.isValid() ? date : null;
  } else {
    date = moment(v);
  }
  return date;
}

export function addWeekdays(date: moment.Moment, days: number) {
  if (date.hour() > 17) {
    days += 1;
  }
  while (days > 0) {
    date = date.add(1, "days");
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return date;
}

export function addMonth(date: moment.Moment, months = 1) {
  date = date.add(months, "month");
  while (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
    date = date.add(-1, "days");
  }
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return date;
}

export function addWeek(date: moment.Moment, weeks = 1) {
  date = date.add(weeks, "weeks");
  while (date.isoWeekday() === 6 || date.isoWeekday() === 7) {
    date = date.add(-1, "days");
  }
  date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return date;
}

export function getEarliestDeliveryDate(
  leadsDay = 0,
  blockedDays: string[] = []
) {
  let minDeliveryDate = getMinDeliveryDate(leadsDay);
  if (!isEmpty(blockedDays)) {
    const sortedBlockedDays = sortBy(blockedDays, (day1, day2) =>
      moment(day1, DATE_FORMAT.SERVER).isSameOrBefore(
        moment(day2, DATE_FORMAT.SERVER)
      )
    );
    sortedBlockedDays.forEach((blockedDay) => {
      const blockedDayMoment = moment(blockedDay, DATE_FORMAT.SERVER);
      if (minDeliveryDate.isSame(blockedDayMoment)) {
        minDeliveryDate = addWeekdays(minDeliveryDate, 1);
      }
    });
  }

  return minDeliveryDate;
}

export function getWeekDaysFromNowToMinDeliveryDate(
  minDeliveryDate: moment.Moment,
  leadDays = 0,
  blockedDays: string[] = []
) {
  if (isEmpty(blockedDays)) {
    return leadDays;
  }

  let weekDays = leadDays;
  blockedDays.forEach((blockedDay) => {
    const blockedDayMoment = moment(blockedDay, DATE_FORMAT.SERVER);
    if (
      blockedDayMoment.isBefore(minDeliveryDate) &&
      blockedDayMoment.isSameOrAfter(moment())
    ) {
      ++weekDays;
    }
  });

  return weekDays;
}
