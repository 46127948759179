import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import {
  Api,
  isSignupComplete,
  retrieveAccessToken,
  retrieveName,
  setContinueSignup as setContinueSignupStorageFlag,
  withApi,
} from "../../api";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useAuthDetails } from "../auth";
import ButtonLink from "../ButtonLink";
import AccountIcon from "../svg/account";
import ChevronDown from "../svg/chevronDown";
import Triangle from "../svg/triangle";

type AccountButtonsProps = {
  api: Api;
  isBlogPage: boolean;
  openNav: boolean;
  buttonClicked: () => void;
};

const getContinueSignup = async (api: Api) => {
  const {
    body: { signup_state },
  } = await api.getUserData();

  return !isSignupComplete(signup_state);
};

const useContinueSignUp = (api: Api) => {
  const [continueSignup, setContinueSignup] = useState<boolean>(false);
  const apiRef = useRef(api);
  const authDetails = useAuthDetails();

  useEffect(() => {
    // update continue signup state when user login/logout changes
    if (authDetails.loading) {
      return;
    }
    if (authDetails.accessToken) {
      // user is logged in
      const refreshContinueSignup = async (api: Api) => {
        try {
          const continueSignup = await getContinueSignup(api);
          setContinueSignup(continueSignup);
          setContinueSignupStorageFlag(continueSignup);
        } catch (e) {
          console.log(
            `Unable to check for continue sign-up state for current user`
          );
        }
      };
      refreshContinueSignup(apiRef.current);
    } else {
      // you can't carry on with signup if you're not authed.
      setContinueSignup(false);
      setContinueSignupStorageFlag(false);
    }
  }, [authDetails]);

  return continueSignup;
};

const usePopup = (defaultOpen = false) => {
  const [isActive, setIsActive] = useState<boolean>(defaultOpen);
  const toggle = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setIsActive((p) => !p);
  }, []);

  const close = useCallback(() => {
    setIsActive(false);
  }, []);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, close);

  return { ref, isActive, toggle, close };
};

const AccountButtons: FC<AccountButtonsProps> = ({
  buttonClicked,
  isBlogPage,
  openNav,
  api,
}: AccountButtonsProps) => {
  const continueSignup = useContinueSignUp(api);

  const authDetails = useAuthDetails();

  const {
    ref,
    isActive: popupActive,
    toggle: toggleMenu,
    close: closeMenu,
  } = usePopup();

  if (authDetails.loading) {
    return null;
  }

  const { accessToken } = authDetails;
  const isAuthorized = !!accessToken;

  if (!isAuthorized) {
    return (
      <>
        <ButtonLink
          to="/log_in"
          onClick={buttonClicked}
          className={`bg-white mv2 mh3 button ${
            isBlogPage ? "Navbar-login-button" : ""
          }`}
          text="Log In"
        />
        <ButtonLink
          to="/signup"
          onClick={buttonClicked}
          className={`bg-pink mv2 mh3 button ${
            isBlogPage ? "Navbar-signup-button" : ""
          }`}
          text="Sign Up"
        />
      </>
    );
  }
  if (openNav) {
    return (
      <>
        {!continueSignup && (
          <ButtonLink
            to="/account"
            onClick={buttonClicked}
            className="mv2 mh3 pv2"
            text="My Account"
          />
        )}
        {continueSignup && (
          <ButtonLink
            to="/details"
            onClick={buttonClicked}
            className="mv2 mh3 pv2"
            text="Complete Sign Up"
          />
        )}
        <ButtonLink
          to="/logout"
          onClick={() => {
            buttonClicked();
          }}
          className="mv2 mh3 pv2"
          text="Log Out"
        />
      </>
    );
  } else {
    return (
      <div className="Account-container" ref={ref}>
        <a className="Account-menu" onClick={toggleMenu} href="#">
          <span>
            <AccountIcon width="18px" height="18px" className="Account-icon" />
          </span>
          <span className="white ph3">{retrieveName()}</span>
          <ChevronDown height="10px" width="15px" />
        </a>
        {popupActive && (
          <div className="Account-popup">
            <Triangle
              fill="rgba(255, 255, 255, 0.75)"
              className="Account-popup-triangle"
              height="10px"
              width="15px"
            />
            {!continueSignup && (
              <Link
                to="/account"
                className="Account-popup-link"
                onClick={closeMenu}
              >
                My Account
              </Link>
            )}
            {continueSignup && (
              <Link
                to="/details"
                className="Account-popup-link"
                onClick={closeMenu}
              >
                Complete Sign Up
              </Link>
            )}
            <Link
              to="/logout"
              className="Account-popup-link"
              onClick={() => {
                closeMenu();
              }}
            >
              Log Out
            </Link>
          </div>
        )}
      </div>
    );
  }
};

export default withApi<AccountButtonsProps>(retrieveAccessToken, () => {})(
  AccountButtons
);
