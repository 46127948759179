import React from "react";

import Tick from "../../../../components/svg/tick";
import styles from "./WhatsIncludedTable.module.scss";

export interface WhatsIncludedTableProps {
  onContinue: () => void;
  onCancel: () => void;
  data: {
    [key: string]: boolean;
  };
}

function WhatsIncludedTable(props: WhatsIncludedTableProps) {
  const { data, onContinue, onCancel } = props;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Can we tempt you with an offer?</h1>
        <span className={styles.headerSubTitle}>
          Keep riding for just <strong>£14.99</strong> for the next 2 months.{" "}
          That’s just 50p / day, all inclusive.
        </span>
      </div>
      <div className={styles.table}>
        <div className={styles.leftCol}>
          {Object.keys(data).map((key) => (
            <div className={styles.row} key={key}>
              <span>{key}</span>
            </div>
          ))}
        </div>
        <div className={styles.rightCol}>
          {Object.keys(data).map((key) => (
            <div className={styles.row} key={`value-${key}`}>
              <Tick stroke={"white"} className={styles.yesIcon} />
            </div>
          ))}
        </div>
        <div className={styles.imageCol}>
          <img src={require("../../../../static/images/woman-with-bike.svg")} />
        </div>
      </div>

      <div className={styles.bottomButtons}>
        <div
          className={`${styles.button} ${styles.claimButton}`}
          onClick={onCancel}
        >
          Claim my discount
        </div>
        <div
          className={`${styles.button} ${styles.cancelButton}`}
          onClick={onContinue}
        >
          I still want to cancel
        </div>
      </div>
    </div>
  );
}

export default WhatsIncludedTable;
