import React from "react";

import { SvgSizeProps } from "./types";

const SearchIcon = ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "8px"}
    height={height || "8px"}
    viewBox="0 0 8 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M2.8108,4.684 C3.8396,4.684 4.6701,3.8535 4.6701,2.8247 C4.6701,1.7954 3.8396,0.9653 2.8108,0.9653 C1.7819,0.9653 0.9514,1.7954 0.9514,2.8247 C0.9514,3.8535 1.7819,4.684 2.8108,4.684 Z M5.2899,4.684 L7.3518,6.75 L6.7361,7.3652 L4.6701,5.3038 L4.6701,4.9774 L4.5586,4.8617 C4.0875,5.2662 3.476,5.5104 2.8108,5.5104 C1.3274,5.5104 0.125,4.3076 0.125,2.8247 C0.125,1.3413 1.3274,0.1389 2.8108,0.1389 C4.2941,0.1389 5.4965,1.3413 5.4965,2.8247 C5.4965,3.4895 5.2527,4.101 4.8478,4.5721 L4.9635,4.684 L5.2899,4.684 Z"
        id="path-hsftrhtesyhgbesd1"
      />
    </defs>
    <g
      id="Page-fdsh8"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Buzzbike_v5sheths" transform="translate(-342.000000, -325.000000)">
        <g id="Group-gsdthte1158" transform="translate(342.000000, 325.000000)">
          <mask id="mask-fdshteshtesh2" fill="white">
            <use xlinkHref="#path-hsftrhtesyhgbesd1" />
          </mask>
          <g id="Clip-gaerhea1157" />
          <polygon
            id="Fill-ahgdrthea1156"
            fill="#000000"
            mask="url(#mask-fdshteshtesh2)"
            points="-1.941 -1.9271 9.4177 -1.9271 9.4177 9.4312 -1.941 9.4312"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SearchIcon;
