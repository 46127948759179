import { WEBSITE_URL } from "../../src/constants";

export const goBack = (history) => {
  // don't ask me why the length is 2 when opening a page
  if (history.length > 2) {
    history.goBack();
  } else {
    window.location.href = WEBSITE_URL;
  }
};
