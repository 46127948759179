import Axios from "axios";

type PostCodeAutocompleteResult = {
  suggestion: string;
  udprn: number;
  urls: {
    udprn: string;
  };
};

export type UdprnResult = {
  findAddress: string;
  postcode: string;
  address_line_one: string;
  address_line_two: string;
  city: string;
};

//TODO: handle 404
export const autocomplete = async (
  p?: string
): Promise<PostCodeAutocompleteResult[]> => {
  if (!p) {
    return new Promise((resolve) => resolve([]));
  }
  const {
    data: { result },
  } = await Axios.get<{
    result: {
      hits: PostCodeAutocompleteResult[];
    };
  }>(
    `https://api.ideal-postcodes.co.uk/v1/autocomplete/addresses?api_key=${process.env.REACT_APP_IDEAL_POSTCODES_API_KEY}&q=${p}`
  );
  return result.hits;
};

export const udprn = async (p?: string): Promise<UdprnResult | undefined> => {
  if (!p || !`${p}`.match(/^\d+$/)) {
    return new Promise((resolve) => resolve(undefined));
  }
  const {
    data: { result },
  } = await Axios.get<{
    result: {
      line_1: string;
      line_2: string;
      post_town: string;
      postcode: string;
    };
  }>(
    `https://api.ideal-postcodes.co.uk/v1/udprn/${p}?api_key=${process.env.REACT_APP_IDEAL_POSTCODES_API_KEY}`
  );

  const city = result.post_town.toLowerCase();

  return {
    findAddress: "",
    postcode: result.postcode,
    address_line_one: result.line_1,
    address_line_two: result.line_2,
    city: city.charAt(0).toUpperCase() + city.slice(1),
  };
};
