import { isEmpty } from "lodash";
import React, {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
  useState,
} from "react";
import { cmToFeetAndInch, feetAndInchToCm } from "utils";

import styles from "./HeightInput.module.scss";

export interface HeightInputProps {
  className?: string;
  height: string;
  onChange: (text: string) => void;
  style?: CSSProperties;
}

enum Unit {
  CM,
  FEET_INCH,
}

const stringToFloat = (s: string): number | undefined => {
  let num: number | undefined;
  try {
    num = Number.parseFloat(s);
    if (Number.isNaN(num)) {
      num = undefined;
    }
  } catch {
    num = undefined;
  }
  return num;
};

type HeightValue =
  | {
      unit: Unit.FEET_INCH;
      feet: number;
      inch: number;
    }
  | {
      unit: Unit.CM;
      cm: number;
    };

const InnerHeightInput = ({
  height,
  onHeightChange,
  style,
  className,
}: {
  height: HeightValue;
  onHeightChange: (height: HeightValue) => void;
  style?: CSSProperties;
  className?: string;
}) => {
  const switchButtonText =
    height.unit === Unit.CM ? "Switch to ft/in" : "Switch to cm";

  const onSwitchButtonClicked = () => {
    if (height.unit == Unit.CM) {
      const [feet, inch] = cmToFeetAndInch(height.cm);
      onHeightChange({ unit: Unit.FEET_INCH, feet, inch });
    } else {
      const cm = feetAndInchToCm(height.feet, height.inch);
      onHeightChange({ unit: Unit.CM, cm });
    }
  };

  const onHeightCmInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const value = isEmpty(target.value) ? "0" : target.value;

    const cm = stringToFloat(value);

    if (cm !== undefined) {
      onHeightChange({ unit: Unit.CM, cm });
    }
  };

  const onHeightFeetInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (height.unit === Unit.CM) {
      return;
    }
    const { target } = event;
    const value = isEmpty(target.value) ? "0" : target.value;

    const feet = stringToFloat(value);

    if (feet !== undefined) {
      onHeightChange({ unit: Unit.FEET_INCH, feet, inch: height.inch });
    }
  };

  const onHeightInchInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (height.unit === Unit.CM) {
      return;
    }
    const { target } = event;
    const value = isEmpty(target.value) ? "0" : target.value;

    const inch = stringToFloat(value);

    if (inch !== undefined) {
      onHeightChange({ unit: Unit.FEET_INCH, feet: height.feet, inch });
    }
  };

  const renderInputs = () => {
    if (height.unit === Unit.CM) {
      const { cm } = height;
      return (
        <div className={styles.inputContainer}>
          <label className={"Form-label"}>Centimeters</label>
          <input
            value={cm === 0 ? "" : `${cm}`}
            className={`Form-input`}
            type={"text"}
            onChange={onHeightCmInputChanged}
          />
        </div>
      );
    }
    const { feet, inch } = height;
    return (
      <>
        <div className={styles.inputContainer}>
          <label className={"Form-label"}>Feet</label>
          <input
            value={feet === 0 ? "" : `${feet}`}
            className={`Form-input`}
            type={"text"}
            onChange={onHeightFeetInputChanged}
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={"Form-label"}>Inches</label>
          <input
            value={inch === 0 ? "" : `${inch}`}
            className={`Form-input`}
            type={"text"}
            onChange={onHeightInchInputChanged}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`${styles.container} ${className}`} style={style}>
      <div className={styles.inputsContainer}>{renderInputs()}</div>
      <span
        className={`${styles.switchButton} clickable`}
        onClick={onSwitchButtonClicked}
      >
        {switchButtonText}
      </span>
    </div>
  );
};

const getInitialHeightValue = (heightCmString: string): HeightValue => {
  const cm = stringToFloat(heightCmString);
  if (cm === undefined) {
    return {
      unit: Unit.FEET_INCH,
      feet: 0,
      inch: 0,
    };
  } else {
    const [feet, inch] = cmToFeetAndInch(cm);
    return {
      unit: Unit.FEET_INCH,
      feet,
      inch,
    };
  }
};

function HeightInput(props: HeightInputProps) {
  const { height: heightCmString, onChange } = props;

  const [currentHeightValue, setCurrentHeightValue] = useState<HeightValue>(
    getInitialHeightValue(heightCmString)
  );
  const [lastCmString, setLastCmString] = useState(heightCmString);

  const onHeightChange = useCallback(
    (newHeight: HeightValue) => {
      setCurrentHeightValue(newHeight);
      if (newHeight.unit === Unit.CM) {
        const newCmString = `${newHeight.cm}`;
        setLastCmString(newCmString);
        onChange(newCmString);
      } else {
        const newCmString = `${feetAndInchToCm(
          newHeight.feet,
          newHeight.inch
        )}`;
        setLastCmString(newCmString);
        onChange(newCmString);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (heightCmString !== lastCmString) {
      // it changed externally
      const cm = stringToFloat(heightCmString);
      if (cm === undefined) {
        return;
      }
      if (currentHeightValue.unit === Unit.CM) {
        setCurrentHeightValue({ unit: Unit.CM, cm });
      } else {
        const [feet, inch] = cmToFeetAndInch(cm);
        setCurrentHeightValue({
          unit: Unit.FEET_INCH,
          feet,
          inch,
        });
      }
    }
  }, [heightCmString, lastCmString, currentHeightValue]);

  return (
    <InnerHeightInput
      height={currentHeightValue}
      onHeightChange={onHeightChange}
      style={props.style}
      className={props.className}
    />
  );
}

export default HeightInput;
