import useIsMounted from "ismounted";
import React, { SyntheticEvent, useState } from "react";

import Button from "../../components/Button";
import ArrowLeft from "../../components/svg/arrowLeft";
import ArrowRight from "../../components/svg/arrowRight";

export type CustomSubmitHandler = (
  event: SyntheticEvent<HTMLElement>,
  options: { direction: "next" | "prev"; noValidate?: boolean }
) => Promise<unknown>;

export type CustomSubmitProps = {
  onSubmit: CustomSubmitHandler;
  prevCopy?: string;
  nextCopy?: string;
  leftArrow?: boolean;
  rightArrow?: boolean;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  hasPrev?: boolean;
  hasNext?: boolean;
};

const isLong = (copy?: string) => copy && copy.length > 10;

const CustomSubmit = (props: CustomSubmitProps) => {
  const isMounted = useIsMounted();
  const [submitting, setSubmitting] = useState(false);

  const submitWrapper = async (f: () => Promise<unknown>) => {
    setSubmitting(true);

    const promise = f ? f() : undefined;
    if (promise) {
      try {
        const r = await promise;
        if (isMounted.current) {
          setSubmitting(false);
          return r;
        }
      } catch (err) {
        if (isMounted.current) {
          setSubmitting(false);
          throw err;
        }
      }
    }
    if (isMounted.current) {
      setSubmitting(false);
    }
  };

  const {
    onSubmit,
    prevCopy,
    nextCopy,
    leftArrow = true,
    rightArrow = true,
    prevDisabled = false,
    nextDisabled = false,
    hasPrev = true,
    hasNext = true,
  } = props;

  const leftArrowContent = leftArrow ? (
    <>
      {" "}
      <ArrowLeft className="CustomSubmit-copy-left-arrow" />{" "}
    </>
  ) : (
    <></>
  );
  const rightArrowContent = rightArrow ? (
    <>
      {" "}
      <ArrowRight className="CustomSubmit-copy-right-arrow" />
    </>
  ) : (
    <></>
  );
  const smaller = isLong(prevCopy) || isLong(nextCopy) ? "-smaller" : "";
  const shouldDisableButtonPrev = prevDisabled || submitting;
  const shouldDisableButtonNext = nextDisabled || submitting;

  return (
    <div className={`CustomSubmit-${hasPrev && hasNext ? 2 : 1}`}>
      {hasPrev ? (
        <Button
          className="CustomSubmit-button bg-black"
          type="button"
          disabled={shouldDisableButtonPrev}
          onClick={(e) =>
            submitWrapper(() =>
              onSubmit(e, { direction: "prev", noValidate: true })
            )
          }
          text={
            <div className="CustomSubmit-copy">
              {leftArrowContent}
              <span className={`CustomSubmit-copy-left-text${smaller} h2`}>
                {prevCopy || "PREV"}
              </span>
            </div>
          }
        />
      ) : (
        <></>
      )}
      {hasNext ? (
        <Button
          className="CustomSubmit-button bg-pink"
          type="button"
          disabled={shouldDisableButtonNext}
          loading={submitting}
          onClick={(e) =>
            submitWrapper(() => onSubmit(e, { direction: "next" }))
          }
          text={
            <div className="CustomSubmit-copy">
              <span className={`CustomSubmit-copy-right-text${smaller} h2`}>
                {nextCopy || "NEXT"}
              </span>
              {rightArrowContent}
            </div>
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CustomSubmit;
