import { isEmpty } from "lodash";
import React, { useCallback, useEffect } from "react";
import { SEGMENT_EVENT } from "types";
import { convertSubscriptionPlanToSegmentProduct } from "utils";

import { SubscriptionPlan } from "api/types";
import RadioButton from "components/RadioButton";

import styles from "./SubscriptionPlans.module.scss";

export interface SubscriptionPlansProps {
  selectedIndex: number;
  onSelectedIndexChange: (index: number) => void;
  plans: SubscriptionPlan[];
}

function SubscriptionPlans(props: SubscriptionPlansProps) {
  const { selectedIndex = -1, onSelectedIndexChange, plans } = props;

  useEffect(() => {
    let selectedPlan = {};
    if (plans && plans.length === 1) {
      selectedPlan = plans[0];
    }
    window.analytics.track(
      SEGMENT_EVENT.PRODUCT_VIEWED,
      convertSubscriptionPlanToSegmentProduct(selectedPlan)
    );
  }, [plans]);

  const onItemClick = useCallback(
    (index: number) => () => {
      window.analytics.track(
        SEGMENT_EVENT.PRODUCT_VIEWED,
        convertSubscriptionPlanToSegmentProduct(plans[index])
      );
      onSelectedIndexChange(index);
    },
    [onSelectedIndexChange, plans]
  );

  if (isEmpty(plans) || plans.length === 1) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p className={`tl f3 mb3 ${styles.title}`}>Select plan</p>
      {plans.map((plan, index) => {
        const {
          amount_to_pay_now,
          equivalent_monthly_payment,
          term_length_in_months,
          product_name,
        } = plan;
        let totalAmountText = `Pay £${amount_to_pay_now} every month`;
        if (term_length_in_months && term_length_in_months > 1) {
          totalAmountText = `Pay £${amount_to_pay_now} for a ${term_length_in_months} month subscription`;
        }
        const isSelected = selectedIndex === index;
        const isMostPopular = index === 1;
        if (isSelected) {
          totalAmountText += "*";
        }

        return (
          <div
            className={`${styles.itemContainer} ${
              isSelected ? styles.itemContainerSelected : ""
            }`}
            key={`subscription-plan-${index}`}
            onClick={onItemClick(index)}
          >
            {isMostPopular && (
              <div className={styles.mostPopularContainer}>
                <span>MOST POPULAR</span>
              </div>
            )}
            <div className={styles.topInfo}>
              <RadioButton
                label={product_name}
                selected={isSelected}
                className={styles.radioButton}
              />
              <span className={styles.amountPerMonth}>
                <span className={styles.amountPerMonthNumber}>
                  £{equivalent_monthly_payment}
                </span>{" "}
                / month
              </span>
            </div>
            <span className={styles.totalAmount}>{totalAmountText}</span>
          </div>
        );
      })}
    </div>
  );
}

export default SubscriptionPlans;
