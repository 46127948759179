import moment from "moment";
import React, { SyntheticEvent } from "react";

import { Api } from "../../api";
import { SubmitHandler } from "../../components/Form/formTypes";
import BikeIcon from "../../components/svg/bike";
import KeyIcon from "../../components/svg/key";
import LockIcon from "../../components/svg/lock";
import highFive from "../../static/images/high-five.png";
import { CancellationFormData } from "./CancellationForm";
import CancellationSummary, {
  CancellationSummaryProps,
} from "./CancellationSummary";
import CollectionDetails, {
  CollectionDetailsSubmitHandler,
} from "./CollectionDetails";
import { AccountGrid } from "./components";
import InterstitialCancellation from "./InterstitialCancellation";
import { useCancellationFunnelTracking } from "./tracking";

export type SubscriptionDetails = {
  isCancelled: boolean;
  name: unknown;
  amount: unknown;
  startDate: string;
  endDate?: string;
};

export type CancellationDetails = {
  collection_date: string;
  address_line_one: string;
  address_line_two: string;
  city: string;
  postcode: string;
  status: string;
};

const START_CANCELLATION = 0;
const INTERSTITIAL = 1;
const READY_TO_CANCEL = 2;
const COLLECTION_DETAILS = 3;
const CANCELLATION_THANKS = 4;

export const StartCancellationTable = ({
  subscription,
  cancellation,
  onStartCancellation,
}: {
  subscription?: SubscriptionDetails;
  cancellation?: CancellationDetails;
  onStartCancellation: (e: SyntheticEvent) => void;
}) => {
  useCancellationFunnelTracking(START_CANCELLATION, "Start Cancellation");
  const showStartCancellation =
    !cancellation && (!subscription || !subscription.isCancelled);
  return (
    <div key="account_subscription_table_0" className="Account-data-wrapper">
      <div className="Account-subscription">
        <h1 className="Account-header">Subscription</h1>
        <div className="Account-subscription-info">
          {subscription ? (
            <>
              <AccountGrid
                label="Plan:"
                value={`${subscription.name}  ${subscription.amount}`}
              />
              <AccountGrid label="Start date:" value={subscription.startDate} />
              {subscription.endDate ? (
                <>
                  <AccountGrid label="End date:" value={subscription.endDate} />
                  <AccountGrid
                    label="Status:"
                    value={cancellation ? cancellation.status : "Cancelled"}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </div>
        {cancellation ? (
          <>
            <h2 className="Account-header">Collection Details</h2>
            <div className="Account-subscription-info">
              <AccountGrid label="Date:" value={cancellation.collection_date} />
              <AccountGrid
                label="Address:"
                value={cancellation.address_line_one}
              />
              <AccountGrid label="" value={cancellation.address_line_two} />
              <AccountGrid label="" value={cancellation.city} />
              <AccountGrid label="" value={cancellation.postcode} />
            </div>
          </>
        ) : showStartCancellation ? (
          <div className="Account-cancel-subscription">
            <a
              className="CustomSubmit-copy"
              href="#"
              onClick={onStartCancellation}
            >
              Cancel Subscription?
            </a>
          </div>
        ) : (
          <></>
        )}
        {!showStartCancellation && (
          <div className={"Account-rejoin-button"}>
            <a
              href={
                "mailto:hello@buzzbike.cc?subject=I%27d%20like%20to%20rejoin%20Buzzbike"
              }
            >
              RE-JOIN BUZZBIKE
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export const CancellationThanksTable = () => {
  useCancellationFunnelTracking(
    CANCELLATION_THANKS,
    "Cancellation Thanks",
    true
  );
  return (
    <div key="account_subscription_table_4" className="Account-data-wrapper">
      <div className="UserDetails-welcome">
        <div className="UserDetails-welcome-icon">
          {" "}
          <img alt="High five" src={highFive} style={{ height: "5rem" }} />{" "}
        </div>
        <h1 className="UserDetails-welcome-header">You&apos;re all set.</h1>
        <p className="UserDetails-welcome-copy">
          Thank you for riding with Buzzbike! Your collection is ordered and
          cancellation request submitted. Look out for an email from us the day
          before your collection date with your two hour time slot. Ride on!{" "}
          <br />
          -Team Buzzbike
        </p>
        <div className="CollectionChecklist-header">
          <div className="CollectionChecklist-header-hr" />
          <span className="CollectionChecklist-header-text">
            Collection&nbsp;Checklist:
          </span>
          <div className="CollectionChecklist-header-hr" />
        </div>
        <div className="CollectionChecklist-body">
          <div className="CollectionChecklist-item">
            <div className="CollectionChecklist-item-icon">
              <BikeIcon width="50px" height="32px" />
            </div>
            <span className="CollectionChecklist-item-text">Buzzbike</span>
          </div>
          <div className="CollectionChecklist-item">
            <div className="CollectionChecklist-item-icon">
              <LockIcon width="26px" height="32px" />
            </div>
            <span className="CollectionChecklist-item-text">Lock</span>
          </div>
          <div className="CollectionChecklist-item">
            <div className="CollectionChecklist-item-icon">
              <KeyIcon width="42px" height="36px" />
            </div>
            <span className="CollectionChecklist-item-text">Key</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ReadyToCancelTable = (props: CancellationSummaryProps) => {
  useCancellationFunnelTracking(READY_TO_CANCEL, "Ready To Cancel");
  return (
    <div key="account_subscription_table_2" className="Account-data-wrapper">
      <div className="Account-subscription">
        <h1 className="Account-header">Cancel Subscription</h1>
        <div style={{ lineHeight: "150%" }}>
          Oh no! we’ll be sad to see you go.
          <br />
          Are you sure you are ready to say farewell to your beloved Buzzbike,
          insurance against theft, on-demand maintenance and rewards the more
          you ride?
        </div>

        <div className="Account-data-subscription">
          <CancellationSummary {...props} />
        </div>
      </div>
    </div>
  );
};

export type RiderAddress =
  | {
      address_line_one: undefined;
      address_line_two: undefined;
      city: undefined;
      postcode: undefined;
    }
  | {
      address_line_one: string;
      address_line_two: string;
      city: string;
      postcode: string;
    };

export const CollectionDetailsTable = ({
  address,
  onSubmit,
  first_name,
  last_name,
}: {
  address: RiderAddress;
  onSubmit: CollectionDetailsSubmitHandler;
  first_name: string;
  last_name: string;
}) => {
  useCancellationFunnelTracking(COLLECTION_DETAILS, "Collection Details");
  return (
    <div key="account_subscription_table_3" className="Account-data-wrapper">
      <div className="Account-subscription">
        <h1 className="Account-header">Arrange Collection</h1>
        <div style={{ lineHeight: "150%" }}>
          Your bike must be returned to Buzzbike HQ by at least your last
          billing date.
          <br />
          Further fees may be applied to cover damaged or lost items, however we
          will contact you to discuss before any charges are added. See terms
          and conditions for full details.
        </div>
        <div className="Account-data-subscription">
          <div className="UserDetails-form-wrapper">
            <CollectionDetails
              onSubmit={onSubmit}
              submitProps={{
                prevCopy: "Go Back",
                nextCopy: "Confirm",
                leftArrow: false,
                rightArrow: false,
              }}
              data={{
                postcode: address.postcode || "",
                address_line_one: address.address_line_one || "",
                address_line_two: address.address_line_two || "",
                city: address.city || "",
                first_name,
                last_name,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ManageSubscription = ({
  api,
  subscription,
  cancellation,
  address,
  first_name,
  last_name,
}: {
  api: Api;
  subscription?: SubscriptionDetails;
  cancellation?: CancellationDetails;
  address: RiderAddress;
  first_name: string;
  last_name: string;
}) => {
  const expectedCancellationDate = moment(new Date()).add(30, "days");
  const [step, setStep] = React.useState(START_CANCELLATION);
  const [{ reason_for_leaving, reason_details }, setCancellationReason] =
    React.useState<{
      reason_for_leaving?: string;
      reason_details?: string;
    }>({});

  const startCancelSubscription = React.useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    setStep((step) => step + 1);
  }, []);

  const quitSubscriptionCancellation = React.useCallback(() => {
    setStep(START_CANCELLATION);
  }, []);

  const continueSubscriptionCancellation = React.useCallback(() => {
    setStep(READY_TO_CANCEL);
  }, []);

  const onCancellationSummarySubmit: SubmitHandler<CancellationFormData> =
    React.useCallback(async (data, e, { direction }) => {
      if (direction === "next") {
        setCancellationReason(data);
        setStep((step) => step + 1);
      } else {
        setStep(START_CANCELLATION);
        return Promise.resolve();
      }
    }, []);

  const handleCollectionDetailsSubmit: CollectionDetailsSubmitHandler =
    React.useCallback(
      async (data, e, { direction }) => {
        if (reason_for_leaving === undefined) {
          // set by previous screen
          return;
        }
        if (direction === "next") {
          // do not want to post findAddress to api
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { collection_date, findAddress, ...restData } = data;
          // form takes care of validating this
          if (collection_date === undefined) {
            return;
          }
          await api.cancelSubscription({
            ...restData,
            collection_date,
            reason:
              reason_for_leaving === "99 other"
                ? `${reason_for_leaving} - ${reason_details}`
                : reason_for_leaving,
          });
          setStep((step) => step + 1);
        } else {
          setStep((step) => step - 1);
          return Promise.resolve();
        }
      },
      [api, reason_for_leaving, reason_details]
    );

  switch (step) {
    case START_CANCELLATION:
      return (
        <StartCancellationTable
          subscription={subscription}
          cancellation={cancellation}
          onStartCancellation={startCancelSubscription}
        />
      );
    case INTERSTITIAL:
      return (
        <InterstitialCancellation
          key={"account_subscription_table_1"}
          onCancel={quitSubscriptionCancellation}
          onContinue={continueSubscriptionCancellation}
          cancellationStep={INTERSTITIAL}
        />
      );
    case READY_TO_CANCEL:
      return (
        <ReadyToCancelTable
          api={api}
          expectedCancellationDate={expectedCancellationDate}
          onSubmit={onCancellationSummarySubmit}
        />
      );
    case COLLECTION_DETAILS:
      return (
        <CollectionDetailsTable
          address={address}
          onSubmit={handleCollectionDetailsSubmit}
          first_name={first_name}
          last_name={last_name}
        />
      );
    default:
      return <CancellationThanksTable />;
  }
};
