import React from "react";

import { AccountDataBlock } from "./components";

export const AccountProfileTable = ({
  first_name,
  last_name,
  username,
  email,
  street,
  phone,
  birthdate,
}: {
  first_name?: string;
  last_name?: string;
  username?: string;
  email?: string;
  street?: React.ReactChild;
  phone?: string;
  birthdate?: string;
}) => (
  <div key="account_profile_table" className="Account-data-wrapper">
    <div className="Account-profile">
      <h1 className="Account-header">Your Profile</h1>
      <div className="Account-data">
        <div className="Account-data-col1">
          <AccountDataBlock label="First name" value={first_name} />
          <AccountDataBlock label="Last name" value={last_name} />
          <AccountDataBlock label="Username" value={username} />
          <AccountDataBlock label="" value="" />
        </div>
        <div className="Account-data-col2">
          <AccountDataBlock label="Email Address" value={email} />
          <AccountDataBlock label="Street Address" value={street} />
          <AccountDataBlock label="Phone Number" value={phone} />
          <AccountDataBlock label="Date of Birth" value={birthdate} />
        </div>
      </div>
    </div>
  </div>
);
