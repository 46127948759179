import React from "react";
import * as ReactSelect from "react-select";
import SyncSelect, { OptionsType, ValueType } from "react-select";
import AsyncSelect, {
  Props as BaseAsyncProps,
} from "react-select/async-creatable";

import ArrowDown from "../svg/arrowDown";
import SearchIcon from "../svg/searchIcon";

type ValueLabelOption<T> = {
  value: ReactSelect.ValueType<T>;
  label?: React.ReactChild | string | number;
  disabled?: boolean;
};

type GenericProps<OptionType> = Pick<
  ReactSelect.Props<OptionType>,
  "placeholder"
> & {
  name: string;
  onChange?: (event?: {
    target: { name: string; value: ReactSelect.ValueType<OptionType> };
  }) => void;
  onBlur?: (event?: {
    target: { name: string; value: ReactSelect.ValueType<OptionType> };
  }) => void;
  externalLabel?: boolean;
};

export type SelectAsyncProps<OptionType> = Pick<
  BaseAsyncProps<ValueLabelOption<OptionType>>,
  "loadOptions" | "isSearchable"
> &
  GenericProps<OptionType> & { value?: OptionType };

export type SelectSyncProps<OptionType> = {
  value?: OptionType;
} & GenericProps<OptionType> & {
    options: ReactSelect.OptionsType<ValueLabelOption<OptionType>>;
  };

export type Props<OptionType> =
  | SelectAsyncProps<OptionType>
  | SelectSyncProps<OptionType>;

type State<OptionType> = ValueLabelOption<OptionType>;

function isAsyncSelect<OptionType>(
  props: Props<OptionType>
): props is SelectAsyncProps<OptionType> {
  return (props as SelectAsyncProps<OptionType>).loadOptions !== undefined;
}

function isMultiSelect<T>(value: ValueType<T>): value is OptionsType<T> {
  if (value === undefined || value === null) {
    return false;
  }
  return Array.isArray(value);
}

class Select<OptionType> extends React.Component<
  Props<OptionType>,
  State<OptionType>
> {
  constructor(props: Props<OptionType>) {
    super(props);

    this.state = this.getInitialValue(props);
  }

  componentDidMount() {
    const initialValue = this.getInitialValue(this.props);
    this.setState(initialValue);
    if (this.props.onChange) {
      this.props.onChange(this.fakeEvent(initialValue.value));
    }
  }

  componentDidUpdate(prevProps: Props<OptionType>) {
    if (
      (prevProps.value !== this.props.value && this.props.externalLabel) ||
      (!isAsyncSelect(prevProps) &&
        !isAsyncSelect(this.props) &&
        prevProps.options !== this.props.options)
    ) {
      const newValue = this.getInitialValue(this.props);
      this.setState(newValue, () => {
        if (this.props.onChange) {
          const event = this.fakeEvent(newValue && newValue.value);
          this.props.onChange(event);
        }
      });
    }
  }

  getInitialValue = (
    props: Props<OptionType>
  ): ValueLabelOption<OptionType> => {
    if (!isAsyncSelect(props)) {
      const found = props.options.find(({ value }) => value === props.value);

      if (!found && props.placeholder) {
        return { value: null };
      }
      if (!found) {
        return props.options[0] || { value: props.value, label: props.value };
      }
      return found;
    }
    return { value: props.value, label: `${props.value}` };
  };

  fakeEvent = (value: ReactSelect.ValueType<OptionType>) => {
    if (value) {
      return { target: { name: this.props.name, value } };
    } else if (this.state) {
      return { target: { name: this.props.name, value: this.state.value } };
    }
    return;
  };

  onChange = (newValue: ValueType<ValueLabelOption<OptionType>>) => {
    if (
      newValue === undefined ||
      newValue === null ||
      isMultiSelect(newValue)
    ) {
      return;
    }
    this.setState(newValue);
    if (this.props.onChange) {
      const event = this.fakeEvent(newValue.value);
      this.props.onChange(event);
    }
  };

  onBlur = () => {
    if (this.props.onBlur) {
      const event = this.fakeEvent(this.state.value);
      this.props.onBlur(event);
    }
  };

  render() {
    const { name, placeholder } = this.props;

    if (isAsyncSelect(this.props)) {
      const { loadOptions, isSearchable } = this.props;

      const styles: ReactSelect.StylesConfig = {
        container: (provided) => ({ ...provided, boxShadow: "none" }),
        control: (provided, state) => ({
          ...provided,
          height: "3rem",
          minHeight: "3rem",
          border: "1px solid rgb(200, 207, 216)",
          flexWrap: "unset",
          boxShadow: state.isFocused ? "0 0 2px 1px #ff4074" : "none",
          "&:hover": { border: "1px solid rgb(200, 207, 216)" },
          color: "#4a4a4a",
          borderRadius: "4px",
        }),

        indicatorSeparator: () => ({ display: "none" }),
        singleValue: (provided) => ({
          ...provided,
          transform: "unset",
          top: "25%",
          color: "rgb(200, 207, 216)",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "rgb(200, 207, 216)",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: "12px",
        }),
      };

      const theme = (theme: ReactSelect.Theme): ReactSelect.Theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "rgb(251, 215, 232)",
          primary50: "rgb(251, 215, 232)",
          primary: "#ff81c5",
        },
      });

      const components: ReactSelect.SelectComponentsConfig<
        ValueLabelOption<OptionType>
      > = {
        DropdownIndicator: (props) => (
          <ReactSelect.components.DropdownIndicator {...props}>
            <SearchIcon width="16px" height="16px" />
          </ReactSelect.components.DropdownIndicator>
        ),
      };

      return (
        <AsyncSelect
          placeholder={placeholder || "Search"}
          value={this.state}
          isSearchable={isSearchable}
          name={name}
          loadOptions={loadOptions}
          onChange={this.onChange}
          onBlur={this.onBlur}
          styles={styles}
          components={components}
          theme={theme}
          cacheOptions
          isOptionDisabled={(option) => option.disabled || false}
          getOptionLabel={(o) =>
            typeof o.label !== "string" ? "" : o.label || ""
          }
          formatCreateLabel={(postCode) => `${postCode}`}
          isValidNewOption={(inputValue) => (inputValue || "").length > 3}
        />
      );
    } else {
      const { options } = this.props;
      const value = this.state && this.state.value ? this.state : undefined;

      const styles: ReactSelect.StylesConfig = {
        container: (provided) => ({ ...provided, boxShadow: "none" }),
        control: (provided, state) => ({
          ...provided,
          height: "3rem",
          minHeight: "3rem",
          border: "1px solid rgb(200, 207, 216)",
          flexWrap: "unset",
          boxShadow: state.isFocused ? "0 0 2px 1px #ff4074" : "none",
          "&:hover": { border: "1px solid rgb(200, 207, 216)" },
          color: "#4a4a4a",
          borderRadius: "4px",
        }),

        indicatorSeparator: (provided) => ({
          ...provided,
          backgroundColor: "rgb(200, 207, 216)",
          height: "42px",
          margin: "2px 0 0 0",
        }),
        singleValue: (provided) => ({
          ...provided,
          transform: "unset",
          top: "25%",
          color: "#4a4a4a",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "rgb(200, 207, 216)",
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: "12px",
        }),
      };

      const theme = (theme: ReactSelect.Theme): ReactSelect.Theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "rgb(251, 215, 232)",
          primary50: "rgb(251, 215, 232)",
          primary: "#ff81c5",
        },
      });

      const components: ReactSelect.SelectComponentsConfig<
        ValueLabelOption<OptionType>
      > = {
        DropdownIndicator: (props) => (
          <ReactSelect.components.DropdownIndicator {...props}>
            <ArrowDown width="22px" height="22px" />
          </ReactSelect.components.DropdownIndicator>
        ),
      };

      return (
        <SyncSelect
          name={name}
          placeholder={placeholder}
          value={value}
          styles={styles}
          options={options}
          onChange={this.onChange}
          components={components}
          theme={theme}
          isSearchable={false}
          isOptionDisabled={(option) => option.disabled || false}
        />
      );
    }
  }
}

export default Select;
