import { useEffect } from "react";

import { SEGMENT_EVENT } from "../../types";

const FUNNEL_VERSION = 1;

export const useCancellationFunnelTracking = (
  step: number,
  stepName: string,
  isLast = false
) => {
  const isFirst = step === 0;

  useEffect(() => {
    if (isFirst && window.analytics) {
      window.analytics.track(SEGMENT_EVENT.CANCELLATION_FUNNEL_STARTED, {
        funnel_version: FUNNEL_VERSION,
        step_name: stepName,
      });
    }
  }, [isFirst, stepName]);

  useEffect(() => {
    if (isLast && window.analytics) {
      window.analytics.track(SEGMENT_EVENT.CANCELLATION_FUNNEL_COMPLETED, {
        funnel_version: FUNNEL_VERSION,
        step_name: stepName,
      });
    }
  }, [isLast, stepName]);

  useEffect(() => {
    if (window.analytics) {
      window.analytics.track(SEGMENT_EVENT.CANCELLATION_FUNNEL_SCREEN_VIEWED, {
        funnel_step: step,
        funnel_version: FUNNEL_VERSION,
        step_name: stepName,
        acValue: `${step}. ${stepName}`,
      });
    }
  }, [step, stepName]);
};
