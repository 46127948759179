import "./style.scss";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stripeJs, {
  PaymentRequestCompleteStatus,
  PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js";
import { get } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { SEGMENT_EVENT } from "types";

import {
  createStripeSubscription,
  retrieveAccessToken,
  startPaymentProcess,
  updatePaymentInfo,
} from "../../api";
import { ApiResponse } from "../../api/types";
import applePay from "../../static/images/icons/icon-apple-pay-white.png";
import googlePay from "../../static/images/icons/icon-google-pay.png";
import paymentMethods from "../../static/images/icons/paymentMethods.jpg";
import { Maybe } from "../../ts-types";
import Button from "../Button";
import { SubmitResponse } from "../Form/formTypes";

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

const CheckoutFormCardDetails: FC<{
  cardHolderName: string;
  setCardHolderName: (name: string) => void;
  billingPostcode: string;
  setBillingPostcode: (postcode: string) => void;
  submitting: boolean;
}> = ({
  cardHolderName,
  setCardHolderName,
  billingPostcode,
  setBillingPostcode,
  submitting,
}) => {
  return (
    <>
      <div className="flex mb2">
        <img
          src={paymentMethods}
          alt="Payment Methods"
          style={{
            height: "2rem",
            margin: "1rem 0 1rem 0",
            width: "auto",
          }}
        />
      </div>
      <div className="Form-row">
        <div className="Form-row-wrapper flex-column">
          <div>
            <label className="Form-label">Cardholder Name</label>
            <input
              className="Form-input"
              name="cardholderName"
              type="text"
              value={cardHolderName || ""}
              onChange={(e) => setCardHolderName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="Form-row mt2">
        <div className="Form-row-wrapper flex-column">
          <div>
            <label className="Form-label">Postcode</label>
            <input
              className="Form-input"
              name="billingPostcode"
              type="text"
              value={billingPostcode || ""}
              onChange={(e) => setBillingPostcode(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="Form-row mt2">
        <div className="Form-row-wrapper flex-column">
          <div>
            <label className="Form-label">Card Number</label>
            <div className="Form-input">
              <CardNumberElement
                options={{ ...createOptions(), disabled: submitting }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="CheckoutBoxes">
        <div className="CheckoutBoxes-input">
          <div className="Form-row-wrapper flex-column">
            <label className="Form-label">Expiry</label>
            <div className="Form-input">
              <CardExpiryElement
                options={{ ...createOptions(), disabled: submitting }}
              />
            </div>
          </div>
        </div>
        <div className="CheckoutBoxes-input">
          <div className="Form-row-wrapper flex-column">
            <label className="Form-label">CVC</label>
            <div className="Form-input">
              <CardCvcElement
                options={{ ...createOptions(), disabled: submitting }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * Handle some kind of error on submitting the checkout form. Looks into both API result details as well as actually JS Errors.
 */
const useHandleError = (
  setSubmitting: (submitting: boolean) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFormPageError: (description: any) => void,
  onFailure?: (failure: { description: string; err: Error }) => void
) => {
  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err?: any) => {
      setSubmitting(false);
      const errors = get(err, "response.data.errors");
      let descriptionHtml;
      let descriptionText;
      if (errors) {
        descriptionHtml = Object.keys(errors).map((key, j) => (
          <p key={j}>Error: {errors[key]}</p>
        ));
        descriptionText = Object.keys(errors).reduce(
          (acc, key) => acc + `Error: ${errors[key]}`,
          ""
        );
      } else {
        if (err.message) {
          descriptionText = descriptionHtml = err.message;
        } else {
          descriptionHtml = get(err, "response.data.description");
          descriptionText = descriptionHtml;
        }
      }

      const desc = descriptionHtml || descriptionText || err.message || "Error";
      setFormPageError(desc);
      if (onFailure) {
        onFailure({ description: desc, err });
      } else {
        throw new Error(descriptionText);
      }
    },
    [setSubmitting, onFailure, setFormPageError]
  );
};

/**
 * Gets the client secret that we use for processing payment details from our API. A refresh function is provided if a new secret is required.
 */
const useGetPaymentProcessSecretOnMount = (): [
  string | null,
  () => Promise<void>
] => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const refresh = useCallback(async () => {
    const { client_secret } = await startPaymentProcess();
    setClientSecret(client_secret);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return [clientSecret, refresh];
};

type PaymentRequestButtonState =
  | {
      loading: true;
    }
  | {
      loading: false;
      supported: false;
    }
  | {
      loading: false;
      supported: true;
      method: "apple" | "google" | "other";
      showPaymentRequest: () => void;
    };

/**
 * Use Stripe's payment request -- e.g. Apple Pay -- feature for payment.
 *
 * This involves checking for support first. Uses the `onConfirmedPaymentMethod` as a callback if a user completes the payment request flow.
 *
 */
const usePaymentRequestButton = (
  stripe: stripeJs.Stripe,
  onConfirmedPaymentMethod: (
    paymentMethodId: string,
    onCompletedProcessing: (status: PaymentRequestCompleteStatus) => void
  ) => Promise<void>,
  totalAmount?: number
): PaymentRequestButtonState => {
  const [result, setResult] = useState<PaymentRequestButtonState>({
    loading: true,
  });

  // keep this in a ref to avoid re-triggering event subscription
  const callbackRef = useRef(onConfirmedPaymentMethod);
  callbackRef.current = onConfirmedPaymentMethod;

  // totalAmount is undefined in UpdatePaymentInfoForm
  const isCheckoutForm = totalAmount !== undefined;
  const label = isCheckoutForm
    ? "Total First Payment"
    : "Updating payment details";
  const amount = totalAmount || 0;

  const paymentRequest = useRef<stripeJs.PaymentRequest>(
    stripe.paymentRequest({
      country: "GB",
      currency: "gbp",
      total: {
        label,
        amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })
  );

  // we have to keep this up to date, but also should not change it whilst mid-flow with payment.
  const lastTotalAmount = useRef(totalAmount);
  if (lastTotalAmount.current !== totalAmount) {
    paymentRequest.current.update({
      total: {
        label: "Total First Payment",
        amount,
      },
    });
    lastTotalAmount.current = totalAmount;
  }

  // attach payment method listener on mount
  useEffect(() => {
    const onGotPaymentMethod = async (ev: PaymentRequestPaymentMethodEvent) => {
      console.log(paymentRequest.current);
      console.log("ON PAYMENT METHOD");
      await callbackRef.current(ev.paymentMethod.id, ev.complete);
    };
    // TODO there's no unsubscribe for this listener...
    paymentRequest.current.on("paymentmethod", onGotPaymentMethod);
  }, []);

  // check if can make payment on mount
  useEffect(() => {
    let isMounted = true;
    const check = async () => {
      const options = await paymentRequest.current.canMakePayment();
      if (!isMounted) {
        return;
      }
      if (options) {
        const method = options.applePay
          ? "apple"
          : options.googlePay
          ? "google"
          : "other";
        const showPaymentRequest = () => {
          paymentRequest.current.show();
          if (isCheckoutForm) {
            window.analytics.track(SEGMENT_EVENT.WALLET_PAYMENT_STARTED, {
              method,
              currency: "gbp",
              revenue: totalAmount,
            });
          }
        };
        setResult({
          loading: false,
          supported: true,
          method,
          showPaymentRequest,
        });
      } else {
        setResult({ loading: false, supported: false });
      }
    };
    check();
    return () => {
      isMounted = false;
    };
  }, [isCheckoutForm, totalAmount]);

  return result;
};

const PaymentRequestButton: FC<{
  stripe: stripeJs.Stripe;
  clientSecret: string;
  totalAmount?: number;
  onConfirmedPaymentMethod: (
    paymentMethodId: string,
    onCompletedProcessing: (status: PaymentRequestCompleteStatus) => void
  ) => Promise<void>;
  parentFormIsSubmitting: boolean;
}> = ({
  stripe,
  clientSecret,
  totalAmount,
  onConfirmedPaymentMethod,
  parentFormIsSubmitting,
}) => {
  const paymentRequest = usePaymentRequestButton(
    stripe,
    onConfirmedPaymentMethod,
    totalAmount
  );
  if (paymentRequest.loading || !paymentRequest.supported) {
    return null;
  }

  return (
    <>
      <Button
        type="button"
        loading={parentFormIsSubmitting}
        disabled={parentFormIsSubmitting || !clientSecret}
        text={
          <span
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              justifyContent: "center",
            }}
          >
            Pay with
            {paymentRequest.method === "apple" ||
            paymentRequest.method === "google" ? (
              <img
                alt="Payment logo"
                style={{
                  marginLeft: 8,
                  height: 24,
                }}
                src={
                  { apple: applePay, google: googlePay }[paymentRequest.method]
                }
              />
            ) : (
              " saved cards"
            )}
          </span>
        }
        className="Form-submit bg-black button"
        style={{
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 32,
          position: "relative",
        }}
        onClick={paymentRequest.showPaymentRequest}
      />
      <div className="or-divider">OR</div>
    </>
  );
};

type Props<T> = {
  displayButton: boolean;
  buttonText?: string;
  showError: boolean;
  onFailure?: (failure: { description: string; err: Error }) => void;
  onSuccess: (body: T) => void;
  onCreated: (submitFn: () => Promise<SubmitResponse | void>) => void;
  data: unknown;
  onCompleteCheckout: (
    accessToken: Maybe<string>,
    subscriptionPlanId?: number
  ) => ApiResponse<T>;
  subscriptionPlanId?: number;
  totalAmount?: number;
  disablePaymentRequestButton: boolean;
};

function CheckoutForm<T>({
  buttonText,
  displayButton,
  showError,
  onCreated,
  onCompleteCheckout,
  subscriptionPlanId,
  onSuccess,
  onFailure,
  totalAmount,
  disablePaymentRequestButton,
}: Props<T>) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const [cardHolderName, setCardHolderName] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");
  const [formPageError, setFormPageError] = useState(null);

  const handleError = useHandleError(
    setSubmitting,
    setFormPageError,
    onFailure
  );

  const [clientSecret, refreshClientSecret] =
    useGetPaymentProcessSecretOnMount();

  const finishCheckingOut = useCallback(async () => {
    try {
      const apiToken = retrieveAccessToken();
      const apiResult = await onCompleteCheckout(apiToken, subscriptionPlanId);
      setSubmitting(false);
      onSuccess(apiResult.body);
    } catch (apiError) {
      await refreshClientSecret();
      handleError(apiError);
    }
  }, [
    onCompleteCheckout,
    setSubmitting,
    onSuccess,
    handleError,
    refreshClientSecret,
    subscriptionPlanId,
  ]);

  const submitUsingPaymentRequest = useCallback(
    async (
      paymentMethodId: string,
      onCompletedProcessing: (status: PaymentRequestCompleteStatus) => void
    ) => {
      if (!stripe || !clientSecret) {
        console.log("bailing");
        onCompletedProcessing("fail");
        return;
      }

      setSubmitting(true);
      const { setupIntent, error: confirmError } =
        await stripe.confirmCardSetup(clientSecret, {
          payment_method: paymentMethodId,
        });

      if (!setupIntent) {
        console.log("DONE CONFIRM CARD SETUP FAIL");
        onCompletedProcessing("fail");
        handleError(confirmError);
        return;
      }

      console.log("CONFIRM CARD SETUP SUCCEED");
      onCompletedProcessing("success");

      await finishCheckingOut();
    },
    [stripe, clientSecret, finishCheckingOut, handleError]
  );

  const submitUsingCardDetails = useCallback(async (): Promise<void> => {
    if (!stripe || !elements) {
      throw new Error("Unable to connect to payment server");
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      throw new Error("Unable to collect payment information");
    }

    if (!clientSecret) {
      throw new Error("Unable to start payment process");
    }

    setSubmitting(true);

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: cardHolderName,
          address: {
            postal_code: billingPostcode,
          },
        },
      },
    });

    if (!setupIntent) {
      handleError(error);
      return;
    }

    await finishCheckingOut();
  }, [
    stripe,
    elements,
    billingPostcode,
    cardHolderName,
    clientSecret,
    finishCheckingOut,
    handleError,
  ]);

  useEffect(() => {
    if (onCreated) {
      onCreated(submitUsingCardDetails);
    }
  }, [onCreated, submitUsingCardDetails]);

  return (
    <>
      {!disablePaymentRequestButton && stripe && clientSecret ? (
        <PaymentRequestButton
          stripe={stripe}
          clientSecret={clientSecret}
          totalAmount={totalAmount}
          onConfirmedPaymentMethod={submitUsingPaymentRequest}
          parentFormIsSubmitting={submitting}
        />
      ) : null}

      <CheckoutFormCardDetails
        cardHolderName={cardHolderName}
        billingPostcode={billingPostcode}
        setBillingPostcode={setBillingPostcode}
        setCardHolderName={setCardHolderName}
        submitting={submitting}
      />

      <div className="Form-row-wrapper flex-column mt4">
        <div
          className="Form-page-error "
          style={{
            marginTop: 0,
            paddingBottom: "0",
            minHeight: "1.6rem",
            display: showError ? "block" : "none",
          }}
        >
          {formPageError || ""}
        </div>
        {displayButton && (
          <div className="Form-submit-wrapper mt2">
            <Button
              type="button"
              text={buttonText || "Pay Now"}
              disabled={submitting || !clientSecret}
              className="Form-submit button"
              style={{
                textTransform: "none",
              }}
              onClick={submitUsingCardDetails}
            />
          </div>
        )}
      </div>
    </>
  );
}

export const UpdatePaymentInfoForm = (
  props: Omit<
    Props<{
      cardholder_name: string;
      last4: number;
      exp_month: number;
      exp_year: number;
    }>,
    "stripe" | "elements" | "onCompleteCheckout"
  >
) => <CheckoutForm onCompleteCheckout={updatePaymentInfo} {...props} />;

export const CreateStripeSubscriptionForm = (
  props: Omit<Props<unknown>, "stripe" | "elements" | "onCompleteCheckout">
) => <CheckoutForm onCompleteCheckout={createStripeSubscription} {...props} />;
