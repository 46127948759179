import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import ReactTooltip from "react-tooltip";

import { KitItemSizeType } from "api/types";
import SizeButton from "components/KitShop/components/SizeButton";
import Tick from "components/svg/tick";

import { KitItemType } from "../KitShop";
import styles from "./KitItem.module.scss";

export interface KitItemProps {
  index: number;
  data: KitItemType;
  selected?: boolean;
  onSelect: (index: number, sizeId?: number) => void;
  selectedSizeId?: number;
}

function KitItem(props: KitItemProps) {
  const { data, selected, onSelect, index, selectedSizeId } = props;
  const selectedSizeFromProp: KitItemSizeType | undefined = useMemo(
    () => data.sizes.find((size) => size.kit_size_id === selectedSizeId),
    [data.sizes, selectedSizeId]
  );

  const addButtonText = selected ? "Remove" : "Add this product";

  function onAddButtonClick() {
    if (hasOnlyOneSize) {
      return onSelect(
        index,
        selectedSizeFromProp
          ? selectedSizeFromProp.kit_size_id
          : data.sizes[0].kit_size_id
      );
    }

    if (selected) {
      return onSelect(
        index,
        selectedSizeFromProp ? selectedSizeFromProp.kit_size_id : undefined
      );
    }

    onSelect(index);
  }

  const tooltipId = `kit-item-tooltip-${data.id}`;

  function generateTooltipContent(dataTip: string) {
    return <span className={styles.tooltipContent}>{dataTip}</span>;
  }

  const onSizeButtonClick = useCallback(
    (size: KitItemSizeType) => {
      onSelect(index, size.kit_size_id);
    },
    [index, onSelect]
  );

  const hasOnlyOneSize = data.sizes && data.sizes.length === 1;

  const shouldShowAddButton =
    hasOnlyOneSize ||
    isEmpty(data.sizes) ||
    (!isEmpty(data.sizes) && selectedSizeFromProp);

  return (
    <div
      className={`${styles.container} ${
        selected ? styles.selectedContainer : ""
      }`}
    >
      <div className={styles.topContent}>
        <img src={data.image_url} className={styles.image} />
        <div className={styles.nameContainer}>
          <div className={styles.name}>{data.name} </div>
          {!isEmpty(data.description) && (
            <div
              className={styles.infoIcon}
              data-for={tooltipId}
              data-tip={data.description}
            >
              i
            </div>
          )}
        </div>
      </div>

      {!isEmpty(data.sizes) && !hasOnlyOneSize && (
        <div className={styles.sizesContainer}>
          {data.sizes.map((size) => (
            <SizeButton
              key={`size-${data.id}-${size.kit_size_id}`}
              size={size}
              selected={
                selectedSizeFromProp
                  ? selectedSizeFromProp.kit_size_id === size.kit_size_id
                  : false
              }
              onClick={onSizeButtonClick}
              className={styles.sizeButton}
            />
          ))}
        </div>
      )}

      <div className={styles.bottomContent}>
        {!isEmpty(data.rrp_price) && (
          <span className={styles.rrpPrice}>RRP: £{data.rrp_price}</span>
        )}
        <span className={styles.price}>Member Price: £{data.price}</span>

        {shouldShowAddButton ? (
          <div
            className={`${styles.addButton} ${
              selected ? styles.selectedAddButton : ""
            }`}
            onClick={onAddButtonClick}
          >
            <span>{addButtonText}</span>
          </div>
        ) : (
          <div className={styles.placeholderAddButton}>
            <span>&nbsp</span>
          </div>
        )}
      </div>
      {selected && <Tick className={styles.tickIcon} />}
      {!isEmpty(data.description) && (
        <ReactTooltip
          id={tooltipId}
          border={true}
          borderColor={"#ff4074"}
          backgroundColor={"#efefef"}
          textColor={"#161616"}
          getContent={generateTooltipContent}
        />
      )}
    </div>
  );
}

export default KitItem;
