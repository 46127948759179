import React, { Component } from "react";

import { sendResetPasswordEmail } from "../api";
import Button from "../components/Button";
import Field from "../components/Field";
import FormContext from "../components/FormContext";
import Header from "../components/Header";
import headerImageMob from "../static/images/img-header--community-mob-n.jpg";
import headerImage from "../static/images/img-header--signup-n.jpg";
import headerImageMobPreview from "../static/images/thumbnails/img-header--community-mob-n.jpg";
import headerImagePreview from "../static/images/thumbnails/img-header--signup-n.jpg";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      showErrors: false,
      successfulContact: false,
    };
  }
  onButtonClick = (e) => {
    e.preventDefault();
    const len_1_200 = (str) => {
      return str.length > 0 && str.length < 200;
    };
    const is_email = (str) => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(str).toLowerCase());
    };
    const is_empty = (str) => {
      if (str.length <= 0) return true;
    };
    const validate = [
      {
        name: "email",
        fn: (value) => {
          if (!(len_1_200(value) && is_email(value)))
            return "Please provide a valid Email address";
        },
      },
    ];

    const errors = {};
    validate.forEach(({ name, fn }) => {
      const value = this.state[name];
      const result = is_empty(value) ? "This field cannot be empty" : fn(value);
      if (result) {
        errors[name] = result;
      }
    });

    let showErrors = false;
    if (Object.keys(errors).length) {
      showErrors = true;
    } else {
      sendResetPasswordEmail(this.state.email)
        .then((response) => {
          //          console.log('response-----', response)
          if (response.status === 201 && response.statusText === "CREATED") {
            this.setState({
              successfulContact: true,
            });
          }
        })
        .catch((err) => {
          console.log("an error occurred", err);
        });
    }
    this.setState({ errors, showErrors });
  };
  onInputChange = (v, e) => {
    const { name } = e.target;

    const obj = {};
    obj[name] = v;
    this.setState(obj);

    let { errors } = this.state;
    if (errors && errors[name]) {
      errors = { ...errors };
      delete errors[name];
    }
    this.setState({
      errors,
    });
  };
  render() {
    return (
      <div>
        <Header
          background={headerImage}
          backgroundPreview={headerImagePreview}
          backgroundMob={headerImageMob}
          backgroundMobPreview={headerImageMobPreview}
          className="noButtonHeader noBanner white-angle third-height"
        />
        <div
          className="w-90 mw6"
          style={{
            maxWidth: "1024px",
            margin: "0px auto",
          }}
        >
          <div className="flex flex-column">
            <FormContext.Provider
              value={{
                showErrors: this.state.showErrors,
                errors: this.state.errors,
                errorFontSize: "12px",
              }}
            >
              <div className="flex flex-row-ns flex-column items-center justify-center">
                <div className="mv5 w-60-ns w-100 form-fields pa3 flex flex-column ">
                  {this.state.successfulContact ? (
                    <div style={{ height: "100%" }}>
                      <div className="h-100 flex items-center justify-center flex-column">
                        <h1 className="db mb5">We have sent you an email.</h1>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row mb2 FieldWrap">
                      <form id="buzzbike-form">
                        <h1 className="f3 mb3 w-100 tc">Reset password</h1>
                        <h3 className="fw1 f5 tc">
                          We will send you an email with instructions on how to
                          reset your password
                        </h3>
                        <p className="lh-copy mb3">{this.props.introText}</p>
                        <Field
                          className="mb1"
                          label="Email Address"
                          name="email"
                          tooltipText="We’ll send the link so you can set a new password"
                          tooltipId="registered_email"
                          onChange={this.onInputChange}
                          value={this.state.email}
                        />
                        <div className="flex flex-row justify-center">
                          <Button
                            type="submit"
                            style={{ maxWidth: 300, width: "100%" }}
                            text={
                              <span style={{ color: "white", fill: "white" }}>
                                Send for help
                              </span>
                            }
                            className={`mt2 bg-pink`}
                            onClick={this.onButtonClick}
                          />
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </FormContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
