import "./index.scss";

import React from "react";
import { Link, LinkProps } from "react-router-dom";

const ButtonLink = (props: LinkProps & { text: React.ReactChild }) => {
  return (
    <Link {...props} className={`${props.className || ""} ButtonLink`}>
      <div className={"ButtonLink-text"}>{props.text}</div>
    </Link>
  );
};

export default ButtonLink;
