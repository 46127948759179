import React, { CSSProperties, ReactNode } from "react";

const customStyle: CSSProperties = {
  backgroundColor: "#f6f6f6",
  color: "#ff4074",
  padding: "10px",
  margin: "0 auto",
  marginTop: "-50%",
  textAlign: "center",
  borderRadius: "3px",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0px 2px 2px 2px rgba(0, 0, 0, 0.03)",
  fontFamily: '"MaisonNeue-Medium"',
  maxWidth: "500px",
  width: "90vw",
  position: "fixed",
  top: "35vh",
  transform: "translate(-50%)",
  boxSizing: "border-box",
  zIndex: 1000,
};
const iconButtonStyle = {
  marginLeft: "20px",
  border: "none",
  display: "block",
  backgroundColor: "transparent",
  cursor: "pointer",
  margin: "0 auto",
  color: "#ff4074",
  marginTop: "20px",
};

const buttonStyle = {
  marginLeft: "20px",
  display: "block",
  backgroundColor: "gray",
  cursor: "pointer",
  margin: "0 auto",
  color: "black",
  marginTop: "20px",
  padding: "5px 25px",
  borderRadius: "4px",
};
const BaseIcon = ({
  color,
  pushRight = true,
  children,
}: {
  color: string;
  pushRight?: boolean;
  children: ReactNode;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ marginRight: pushRight ? "20px" : "0", minWidth: 24 }}
  >
    {children}
  </svg>
);
const CloseIcon = () => (
  <BaseIcon color="#ff4074" pushRight={false}>
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </BaseIcon>
);

type AlertTemplateProps = {
  close: () => void;
  buttonClassName?: string;
  buttonComponent?: unknown;
  buttonText?: string;
  message?: string;
  messageComp?: ReactNode;
  style?: CSSProperties;
  className?: string;
};

const AlertTemplate = ({
  message,
  messageComp,
  close,
  style,
  className,
  buttonComponent,
  buttonText,
  buttonClassName,
}: AlertTemplateProps) => (
  <div
    style={style || className ? {} : customStyle}
    className={className || ""}
  >
    {(message && message) || messageComp}
    {(buttonComponent && buttonComponent) || buttonText ? (
      <button
        onClick={close}
        className={buttonClassName || ""}
        style={!buttonClassName ? buttonStyle : {}}
      >
        {buttonText}
      </button>
    ) : (
      <button onClick={close} style={iconButtonStyle}>
        <CloseIcon />
      </button>
    )}
  </div>
);

export default AlertTemplate;
