import { History } from "history";
import React, { useEffect } from "react";

import { useAuthDetails } from "components/auth";

import { Api } from "../api";
import { WEBSITE_URL } from "../constants";

const LogOutPage = ({ api, history }: { api: Api; history: History }) => {
  const { refresh, loading } = useAuthDetails();

  useEffect(() => {
    if (!loading) {
      window.analytics.reset();
      api.logout();
      refresh();
      window.location.href = WEBSITE_URL;
    }
  }, [refresh, api, history, loading]);

  return (
    <div>
      <div className="bg-black w-100 vh-50" />
      <div className="h5 flex flex-column justify-around w-25-l center mv5 tc">
        <h1>You have successfully logged out</h1>
        <p className="pink">
          <a href={WEBSITE_URL}>Go to home page</a>
        </p>
      </div>
    </div>
  );
};

export default LogOutPage;
