import { isEmpty } from "lodash";
import React from "react";

import { Item, ItemType } from "../types";
import styles from "./BlogItem.module.scss";

export interface ItemProps {
  className?: string;
  item: Item;
}

interface BlockProps {
  childItems: Item[];
  display?: string;
}

function Paragraph(props: BlockProps) {
  const { childItems = [] } = props;

  return (
    <div className={`${styles.paragraph}`}>
      {childItems.map((item, index) => (
        <BlogItem
          key={index}
          item={{ ...item, lastItem: index === childItems.length - 1 }}
        />
      ))}
    </div>
  );
}

interface SpanProps {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  headingType?: string;
}

function Span(props: SpanProps) {
  const { text, bold, italic, underline, headingType = "" } = props;

  function getClassNames() {
    let result = "";

    if (bold) {
      result += ` ${styles.bold}`;
    }
    if (italic) {
      result += ` ${styles.italic}`;
    }
    if (underline) {
      result += ` ${styles.underline}`;
    }
    return result;
  }

  function getTag() {
    switch (headingType) {
      case ItemType.HEADING_ONE:
        return "h1";
      case ItemType.HEADING_TWO:
        return "h2";
      case ItemType.HEADING_THREE:
        return "h3";
      case ItemType.HEADING_FOUR:
        return "h4";
      case ItemType.HEADING_FIVE:
        return "h5";
      case ItemType.HEADING_SIX:
        return "h6";
      default:
        return "span";
    }
  }

  if (isEmpty(text)) {
    return <span className={`${styles.span} ${getClassNames()}`}>&nbsp;</span>;
  }
  const Tag = getTag();
  const isHeading = Tag.includes("h");

  return (
    <Tag
      className={`${
        isHeading ? styles.heading : styles.span
      } ${getClassNames()}`}
    >
      {text}
    </Tag>
  );
}

interface ListProps {
  type: ItemType.BULLETED_LIST | ItemType.NUMBERED_LIST;
  childItems: Item[];
}

function List(props: ListProps) {
  const { type, childItems } = props;
  const ParentTag = type === ItemType.BULLETED_LIST ? "ul" : "ol";

  return (
    <ParentTag className={`${styles.list}`}>
      {childItems.map((item, i1) => {
        //List items
        const { children = [] } = item;
        return children.map((listItem, i2) => {
          //List item children
          return (
            <li key={`listItem-${i1}-${i2}`}>
              {(listItem.children || []).map((listItemChild, i3) => (
                <BlogItem
                  key={`listItemChild-${i1}-${i2}-${i3}`}
                  item={listItemChild}
                />
              ))}
            </li>
          );
        });
      })}
    </ParentTag>
  );
}

interface LinkProps {
  to: string;
  text: string;
}

function Link(props: LinkProps) {
  const { to, text } = props;
  return (
    <a href={to} className={styles.link}>
      {text}
    </a>
  );
}

interface QuoteProps {
  text: string;
}

function Quote(props: QuoteProps) {
  const { text } = props;
  return <span className={styles.quote}>“{text}”</span>;
}

interface SectionDividerProps {
  imageUrl?: string;
}

function SectionDivider(props: SectionDividerProps) {
  const { imageUrl = "winter-riding-blog/dots-divider.png" } = props;
  return (
    <img
      className={styles.sectionDivider}
      src={require(`static/images/${imageUrl}`)}
      alt={"divider"}
    />
  );
}

type BlogTableCell = {
  type: "table_cell";
  children: Item[];
};
type BlogTableRow = {
  type: "table_row";
  children: BlogTableCell[];
};
type BlogTableChild = {
  type: "table_head" | "table_body";
  children: BlogTableRow[];
};
type BlogTableProps = {
  childItems: BlogTableChild[];
};

function BlogTable(props: BlogTableProps) {
  const { childItems } = props;

  function renderPart(part: BlogTableChild) {
    if (isEmpty(part)) {
      return null;
    }
    const isCorrectTablePart = ["table_head", "table_body"].includes(part.type);
    if (!isCorrectTablePart) {
      return null;
    }
    const hasValidRows = part.children.every((row) => row.type === "table_row");
    if (!hasValidRows) {
      return null;
    }

    const isHead = part.type === "table_head";
    const TablePart = isHead ? "thead" : "tbody";
    const rows = part.children;

    return (
      <TablePart>
        {rows.map((row, rowIndex) => {
          const cells = row.children;
          return (
            <tr
              key={`table-${isHead ? "head" : "body"}-row-${rowIndex}`}
              className={isHead ? styles.tableHeadRow : styles.tableBodyRow}
            >
              {cells.map((cell, cellIndex) => {
                const CellTag = isHead ? "th" : "td";
                return (
                  <CellTag key={`table-cell-${rowIndex}-${cellIndex}`}>
                    {cell.children.map((item, itemIndex) => (
                      <BlogItem
                        item={item}
                        key={`table-cell-item-${cellIndex}-${itemIndex}`}
                      />
                    ))}
                  </CellTag>
                );
              })}
            </tr>
          );
        })}
      </TablePart>
    );
  }

  return (
    <div className={styles.tableContainer}>
      <table>{childItems.map((childItem) => renderPart(childItem))}</table>
    </div>
  );
}

function BlogItem(props: ItemProps) {
  const {
    item: {
      type = ItemType.SPAN,
      children = [],
      text = "",
      href = "",
      bold,
      italic,
      underline,
      src,
    },
  } = props;

  function isDivider() {
    return text === "[[section-divider]]";
  }

  function renderItemFromType() {
    switch (type) {
      case ItemType.PARAGRAPH:
        return <Paragraph childItems={children} />;
      case ItemType.SPAN:
        if (isDivider()) {
          return <SectionDivider />;
        }
        return (
          <Span text={text} bold={bold} italic={italic} underline={underline} />
        );
      case ItemType.BULLETED_LIST:
        return <List type={ItemType.BULLETED_LIST} childItems={children} />;
      case ItemType.NUMBERED_LIST:
        return <List type={ItemType.NUMBERED_LIST} childItems={children} />;
      case ItemType.LINK:
        return <Link to={href} text={children[0].text} />;
      case ItemType.QUOTE:
        return <Quote text={children[0].text} />;
      case ItemType.SECTION_DIVIDER:
        return <SectionDivider />;
      case ItemType.HEADING_ONE:
      case ItemType.HEADING_TWO:
      case ItemType.HEADING_THREE:
      case ItemType.HEADING_FOUR:
      case ItemType.HEADING_FIVE:
      case ItemType.HEADING_SIX:
        return (
          <Span
            text={children[0].text}
            bold={bold}
            italic={italic}
            underline={underline}
            headingType={type}
          />
        );
      case ItemType.IMAGE:
        return <img className={styles.image} src={src} alt={"blog-img"} />;
      case ItemType.TABLE:
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <BlogTable childItems={children as any} />;
      default:
        return null;
    }
  }

  return renderItemFromType();
}

export default BlogItem;
