import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import highFive from "static/images/high-five.png";

import { Api } from "../../api";
import {
  ApiOrderSummarySaved,
  assertOrderSummaryLoaded,
} from "../../api/types";
import CopyableText from "../../components/CopyableText/CopyableText";
import RefererSocialShare from "../../components/ReferrerSocialShare";
import { CYCLE_SCHEME_MODE } from "../../constants";
import { RegistrationData } from "./formFields";

type Props = {
  api: Api;
  referrerCode?: string;
  onLoadedReferrerCode: (referrerCode: string) => void;
  registrationData: RegistrationData;
  onLoadedApplicationThankYou: () => void;
};

const ReferrerSection = ({ referrerCode }: { referrerCode: string }) => {
  return (
    <>
      <p className="pink">REFER FRIENDS, RIDE FOR FREE</p>
      <p className="mb4 tc mt4">
        Refer 3 friends to Buzzbike and if they <br />
        sign up before 31 Oct,
        <span className="pink"> you get £100!</span>
        <br />
        <br />
        Each friend you refer gets £10 credit plus a special <br />
        joining rate of <span className="pink">£19.99/month</span> for their
        first 4 months.
      </p>
      <div className="referer-code-container">
        <span>Share your referral code:</span>
        <CopyableText
          className="referer-code-text clickable"
          text={referrerCode}
        />
      </div>
      <RefererSocialShare
        code={referrerCode}
        className={"flex justify-center mt4"}
      />
      <span className={"mt4"}>
        *Find your referral code in your{" "}
        <Link to={"/account"} className={"link"}>
          Account
        </Link>{" "}
        any time.
      </span>
    </>
  );
};

const ApplicationThankYou = ({
  onLoadedApplicationThankYou,
  referrerCode,
  onLoadedReferrerCode,
  api,
  registrationData,
}: Props) => {
  const { company_name } = registrationData;

  useEffect(() => {
    onLoadedApplicationThankYou();
  }, [onLoadedApplicationThankYou]);

  useEffect(() => {
    if (isEmpty(referrerCode)) {
      api.getUserData().then((data) => {
        const referrerCode = get(data, "body.user.referrer_code", "");
        onLoadedReferrerCode(referrerCode);
      });
    }
  }, [referrerCode, onLoadedReferrerCode, api]);

  const [orderSummary, setOrderSummary] =
    useState<ApiOrderSummarySaved | undefined>(undefined);

  useEffect(() => {
    if (orderSummary) {
      return;
    }
    api.getOrderSummary().then((response) => {
      const { body: orderSummary } = response;
      assertOrderSummaryLoaded(orderSummary);
      setOrderSummary(orderSummary);
    });
  }, [api, orderSummary, setOrderSummary]);

  if (
    company_name !== null &&
    company_name !== undefined &&
    company_name !== "NULL"
  ) {
    return (
      <div className="UserDetails-welcome">
        <div className="UserDetails-welcome-icon">
          {" "}
          <img alt="High Five" src={highFive} style={{ height: "5rem" }} />{" "}
        </div>
        <h1 className="UserDetails-welcome-header">Welcome to The Club!</h1>
        <p className="UserDetails-welcome-copy">
          {!CYCLE_SCHEME_MODE
            ? `All that’s left to do is sign your consumer hire agreement, which
          allows you to subscribe to Buzzbike tax free as part of the cycle to
          work scheme. This will be sent to you in your welcome email. Please
          note we won’t be able to get you Buzzbiking until this is complete.`
            : `You’re all set. Look out for your welcome email with confirmation of your delivery date. `}
          Ride on! <br />
          -Team Buzzbike
        </p>
        {referrerCode && !isEmpty(referrerCode) && !CYCLE_SCHEME_MODE ? (
          <ReferrerSection referrerCode={referrerCode} />
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="UserDetails-welcome">
        <div className="UserDetails-welcome-icon">
          {" "}
          <img alt="High Five" src={highFive} style={{ height: "5rem" }} />{" "}
        </div>
        <h1 className="UserDetails-welcome-header">Welcome to The Club!</h1>
        {referrerCode && !isEmpty(referrerCode) ? (
          <ReferrerSection referrerCode={referrerCode} />
        ) : null}
      </div>
    );
  }
};
export default ApplicationThankYou;
