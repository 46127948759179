import React from "react";

import { SvgSizeAndClassProps } from "./types";

export default ({ width, height, className }: SvgSizeAndClassProps) => (
  <svg
    width={width || "6px"}
    height={height || "4px"}
    viewBox="0 0 600 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className || ""}
  >
    <g
      id="Page-2lmlmlm3248"
      stroke="#FE2E8B"
      strokeWidth="80"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M50,50 L300,330 L550,50" />
    </g>
  </svg>
);
