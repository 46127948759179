import "./index.scss";

import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";

import questionmark from "../../static/images/icons/icon-questionmark.png";
import FormContext from "../FormContext";
import arrowRight from "../svg/arrowRight";

const FORMAT = "dd/mm/yyyy";

class Field extends Component {
  state = {
    value: this.props.defaultValue || "",
  };

  static defaultProps = {
    labelPosition: "top",
  };

  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.any,
      })
    ),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    labelPosition: PropTypes.oneOf(["top", "left", "right", "bottom"]),
  };

  onChange = (e) => {
    let { value } = e.target;

    if (this.props.type === "checkbox") {
      value = e.target.checked;
    }

    this.setValue(value, e);
  };

  setValue(value, event) {
    const controlled = this.props.value !== undefined;
    if (!controlled) {
      this.setState({
        value,
      });
    }

    if (this.context && this.context.onChange) {
      this.context.onChange(this.props.name, value);
    }
    if (this.props.onChange) {
      this.props.onChange(value, event);
    }
  }
  render() {
    return (
      <FormContext.Consumer>
        {(context) => {
          this.context = context;
          const { props, state } = this;

          const { labelPosition } = props;
          const value = props.value !== undefined ? props.value : state.value;

          const inputProps = {
            name: props.name,
            type: props.type,
            required: props.required,
            placeholder: props.placeholder,
            onChange: this.onChange,
          };

          if (props.type === "checkbox") {
            inputProps.checked = value;
          } else {
            inputProps.value = value;
          }

          if (props.type === "date") {
            inputProps.format = this.props.format || FORMAT;
          }
          if (props.autoComplete) inputProps.autoComplete = props.autoComplete;

          return (
            <div
              style={props.style}
              className={`${props.className || ""} Field Field--type-${
                this.props.type || "text"
              } flex flex-auto ${
                labelPosition === "left" || labelPosition === "right"
                  ? "flex-row"
                  : "flex-column"
              } Field--label-${labelPosition}`}
            >
              <label>
                {labelPosition === "left" || labelPosition === "top"
                  ? this.renderLabel()
                  : null}

                {this.renderInput(inputProps)}

                {labelPosition === "bottom" || labelPosition === "right"
                  ? this.renderLabel()
                  : null}

                {this.renderError()}
              </label>
            </div>
          );
        }}
      </FormContext.Consumer>
    );
  }

  renderError() {
    if (!this.context) {
      return;
    }
    const { errors, showErrors, errorFontSize } = this.context;

    if (!errors || !showErrors) {
      return null;
    }
    let fontStyle = {};
    if (errorFontSize) fontStyle = { fontSize: errorFontSize };

    let error = errors[this.props.name];
    if (error) {
      if (Array.isArray(error)) {
        error = error.map((e, index) => <p key={index}>{e}</p>);
      } else {
        error = <p style={fontStyle}>{error}</p>;
      }
      return <div className="Field-error db mt1">{error}</div>;
    }
  }

  renderInput(inputProps) {
    // if (inputProps.type === 'date') {
    // return this.renderDateInput(inputProps)
    // }
    if (inputProps.type === "select") {
      return this.renderSelectInput(inputProps);
    }
    if (inputProps.type === "textarea") {
      return <textarea {...inputProps} />;
    }
    return <input {...inputProps} />;
  }

  renderSelectInput(inputProps) {
    return (
      <React.Fragment>
        <select {...inputProps}>
          {this.props.options.map(({ label, value }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </select>
        {this.renderArrow()}
      </React.Fragment>
    );
  }
  renderArrow() {
    return <div className="Field__arrow">{arrowRight}</div>;
  }

  renderLabel() {
    if (!this.props.label) {
      return;
    }

    const { labelPosition } = this.props;
    return (
      <React.Fragment>
        <div
          className={`flex flex-row ${
            labelPosition === "left" || labelPosition === "right" ? "div" : "db"
          }`}
        >
          <div className={`Field__label-text mb2`}>
            <p
              className="dib"
              dangerouslySetInnerHTML={{ __html: this.props.label }}
            />
          </div>
          {!!this.props.tooltipId && (
            <div>
              <div
                className="dib tooltip-wrapper"
                data-for={this.props.tooltipId}
                data-tip={this.props.tooltipText}
              >
                <img src={questionmark} alt="" />{" "}
              </div>
              <ReactTooltip id={this.props.tooltipId} />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Field;
