import * as Sentry from "@sentry/react";
import { History } from "history";
import { useEffect } from "react";

function usePageTracking(history: History) {
  useEffect(() => {
    const unlisten = history.listen((location) => {
      try {
        window.analytics.page(location.pathname);
      } catch (e) {
        Sentry.captureException(e);
      }
    });
    return unlisten;
  }, [history]);
}

export default usePageTracking;
