import React from "react";

import { SvgSizeProps } from "./types";

export default ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "47px"}
    height={height || "40px"}
    viewBox="0 0 47 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="password"
      transform="matrix(0.53, 0.848, -0.848, 0.53, 36.368, -0.068)"
    >
      <path
        id="Shape"
        d="M10,42.8a9.719,9.719,0,0,1-5.485-1.7,10.248,10.248,0,0,1-3.745-4.7A10.547,10.547,0,0,1,6.16,22.82V3.093a.812.812,0,0,1,.3-.672L9.488.154A.769.769,0,0,1,9.95,0a.776.776,0,0,1,.529.21l4.8,4.46a.85.85,0,0,1,.068,1.16L13.2,8.368l2.131,2.4c.015.017.03.035.043.053a.848.848,0,0,1-.14,1.156l-2.32,1.889v1.725l.815,1.639a.889.889,0,0,1,.112.369v5.22a9.739,9.739,0,0,1,3.226,2.255,10.372,10.372,0,0,1,2.153,3.35A10.546,10.546,0,0,1,13.825,42,9.607,9.607,0,0,1,10,42.8ZM9.951,23.673a8.23,8.23,0,0,0-5.97,2.57,9.032,9.032,0,0,0,0,12.41,8.217,8.217,0,0,0,11.938,0,9.023,9.023,0,0,0,0-12.411A8.228,8.228,0,0,0,9.951,23.673ZM10,22.029a9.672,9.672,0,0,1,2.25.266V17.8l-.815-1.64a.873.873,0,0,1-.111-.369V13.466a.838.838,0,0,1,.31-.648l1.948-1.585L11.55,8.943a.849.849,0,0,1-.01-1.105l2.105-2.483L9.923,1.883,7.748,3.518V22.295A9.683,9.683,0,0,1,10,22.029Z"
        transform="translate(0.016 0.099)"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M3.627,7.541A3.7,3.7,0,0,1,0,3.771,3.705,3.705,0,0,1,3.627,0,3.713,3.713,0,0,1,7.255,3.771,3.705,3.705,0,0,1,3.627,7.541Zm0-5.889A2.083,2.083,0,0,0,1.589,3.772,2.082,2.082,0,0,0,3.627,5.891,2.086,2.086,0,0,0,5.666,3.772,2.083,2.083,0,0,0,3.627,1.652Z"
        transform="translate(6.338 30.137)"
      />
    </g>
  </svg>
);
