import React, { HTMLAttributes } from "react";
import { getDeepValue } from "utils";

import { AuthorType } from "components/BlogPage/types";
import DefaultUserAvatar from "static/images/user.png";

import styles from "./BlogFooter.module.scss";

export interface BlogFooter extends HTMLAttributes<HTMLDivElement> {
  author?: AuthorType;
}

function BlogFooter(props: BlogFooter) {
  const { className, author } = props;
  const fromAssets = getDeepValue<boolean>(author, "picture.fromAssets", false);
  const url = getDeepValue<string>(author, "picture.url", DefaultUserAvatar);
  const name = getDeepValue<string>(author, "name");
  const biography = getDeepValue<string>(author, "biography");
  const imgSrc = fromAssets ? require(`static/images/${url}`) : url;

  return (
    <div className={`${styles.container} ${className || ""}`}>
      {author && (
        <div className={styles.authorContainer}>
          <img
            className={styles.authorAvatar}
            src={imgSrc}
            alt={"author-avatar"}
          />
          <div className={styles.authorInfo}>
            <span className={styles.authorLabel}>ABOUT THIS AUTHOR</span>
            <span className={styles.authorName}>{name}</span>
            <span className={styles.authorDescription}>{biography}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogFooter;
