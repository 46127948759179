import React from "react";

import { SvgSizeProps } from "./types";

export default ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "47px"}
    height={height || "30"}
    viewBox="0 0 47 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Path"
      d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"
      transform="translate(36 27)"
    />
    <path
      id="Shape"
      d="M9.18,30a9.377,9.377,0,0,1,0-18.75,8.913,8.913,0,0,1,5.992,2.273L19,9.809,17.33,4.687H15.238a.938.938,0,0,1,0-1.875h5.508a.938.938,0,0,1,0,1.875h-1.48l4.316,13.222a6.108,6.108,0,0,0,1.754-4.315,7.9,7.9,0,0,1,7.8-7.969h1.01V1.875h-4.59a.929.929,0,0,1-.918-.937A.929.929,0,0,1,29.559,0h5.508a.929.929,0,0,1,.918.938v10.5a9.084,9.084,0,0,1,1.836-.189A9.289,9.289,0,0,1,47,20.625a9.42,9.42,0,0,1-4.734,8.2.9.9,0,0,1-.444.118.916.916,0,0,1-.8-.483.948.948,0,0,1,.357-1.274,7.539,7.539,0,0,0,3.788-6.564,7.432,7.432,0,0,0-7.343-7.5,7.216,7.216,0,0,0-1.836.237v4.063l3.862,3.945a.954.954,0,0,1,0,1.326.9.9,0,0,1-1.3,0l-4.13-4.219a.938.938,0,0,1-.269-.662V14.129a7.581,7.581,0,0,0,.116,13.06.948.948,0,0,1,.357,1.274.916.916,0,0,1-.8.483.9.9,0,0,1-.444-.118,9.466,9.466,0,0,1,.774-16.8V7.5h-1.01a6.038,6.038,0,0,0-5.967,6.094,7.9,7.9,0,0,1-7.8,7.969H18.313a9.427,9.427,0,0,1-2.958,5.993A9.046,9.046,0,0,1,9.18,30ZM1.9,21.561A7.408,7.408,0,0,0,9.18,28.125a7.406,7.406,0,0,0,7.284-6.564Zm17.748-9.788h0l-3.208,3.109a9.431,9.431,0,0,1,1.877,4.805h1.056a5.8,5.8,0,0,0,2.651-.634l-2.376-7.281Zm-4.549,4.41h0l-3.614,3.5h4.984a7.571,7.571,0,0,0-1.37-3.5ZM9.18,13.125A7.408,7.408,0,0,0,1.9,19.688H8.813l5.018-4.865A7.142,7.142,0,0,0,9.18,13.125Z"
    />
  </svg>
);
