import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getDeepValue } from "utils";

import { Api } from "../../api";
import { SubmitHandler } from "../../components/Form/formTypes";
import questionMark from "../../static/images/icons/icon-questionmark.png";
import CancellationForm, { CancellationFormData } from "./CancellationForm";

export type CancellationSummaryProps = {
  api: Api;
  expectedCancellationDate: moment.Moment;
  onSubmit: SubmitHandler<CancellationFormData>;
};

type CostItem = {
  label: string;
  price: string;
  isMinus?: boolean;
  tooltip?: string;
};

const CancellationSummary = ({
  api,
  expectedCancellationDate,
  onSubmit,
}: CancellationSummaryProps) => {
  const [{ costs, total, isPayNow }, setSummary] = useState<{
    costs: null | CostItem[];
    total: string | null;
    isPayNow: boolean;
  }>({ costs: null, total: null, isPayNow: false });
  const [error, setError] =
    useState<
      | undefined
      | {
          message?: string;
        }
    >(undefined);

  useEffect(() => {
    api
      .getCancellationSummary(expectedCancellationDate)
      .then((response) => {
        const data = response.body || {};
        const minDateToUnsubscribe =
          getDeepValue<number>(data, "unsubscribe_from_date") || 0;
        if (minDateToUnsubscribe > 0) {
          const minDateMoment = moment(minDateToUnsubscribe * 1000);
          if (minDateMoment.isAfter(moment())) {
            const errMessage = `As you are on a fixed term contract, you cannot unsubscribe until ${minDateMoment.format(
              "Do MMMM, YYYY"
            )}`;
            setError({ message: errMessage });
            return;
          }
        }

        const costs: CostItem[] = [
          {
            label: "Subscription end date",
            price: moment.unix(data.contract_end).format("DD/MM/YYYY"),
            tooltip:
              "As per Buzzbike T&C’s we require a 30 day notice period. You are welcome to keep your Buzzbike until this date or select an earlier collection date.",
          },
          ...(!data.pay_now
            ? [
                {
                  label: "Last billing date",
                  price: moment
                    .unix(data.last_billing_date)
                    .format("DD/MM/YYYY"),
                  tooltip:
                    "This is the same day of the month your subscription has been taken each month.",
                },
              ]
            : []),
          {
            label: "Pro rata final payment",
            price: `£${data.pro_rated_amount_to_pay}`,
            tooltip: data.pay_now
              ? "Your final monthly payment is pro rated based on the subscription end date and charged today."
              : "Your final payment will be pro rated and deducted from your account on your last billing date.",
          },
          {
            label: "Collection",
            price: `£${data.collection_charge}`,
            tooltip:
              "Your Buzzbike must be collected by at least your last billing date and is booked on the next page. Unfortunately we are unable to accept self returns as your Buzzbike is sent off to the Bike Lab for some love before going out to the next Buzzbiker.",
          },
          ...(data.referral_cashback_amount !== "0.00"
            ? [
                {
                  label: "Referral cashback",
                  price: `£${data.referral_cashback_amount}`,
                  isMinus: true,
                  tooltip: "Rewards for referring your friends to Buzzbike",
                },
              ]
            : []),
        ];

        const total = Number.parseFloat(data.final_invoice_total);

        const orderSummary = {
          costs,
          total: `£${total.toFixed(2)}`,
          isPayNow: data.pay_now,
        };
        setSummary(orderSummary);
      })
      .catch((error) => {
        const errorMessage = get(error, "response.data.errors");
        const errorObject = errorMessage ? { message: errorMessage } : error;
        setError(errorObject);
      });
  }, [api, expectedCancellationDate]);

  const rowsCount = (costs && costs.length) || 0;
  if (costs) {
    return (
      <>
        <div className="OrderSummary">
          <div
            className="OrderSummary-cost-wrapper"
            style={{ gridTemplateRows: `repeat(${rowsCount}, 3rem)` }}
          >
            {costs.map((cost, i) => (
              <div
                key={`order_summary_cost_${i}`}
                className="OrderSummary-cost-short"
                style={i === costs.length - 1 ? { border: "none" } : {}}
              >
                <span className="OrderSummary-cost-label">{cost.label}</span>
                <span
                  className={
                    cost.isMinus
                      ? "OrderSummary-cost-minusPrice"
                      : "OrderSummary-cost-price"
                  }
                >
                  {cost.price}
                </span>

                <img
                  className="Form-label-tooltip OrderSummary-tooltip-low "
                  src={questionMark}
                  data-for="Discount-tooltip"
                  alt="tooltip"
                  data-tip={cost.tooltip}
                  data-multiline={true}
                />
                <ReactTooltip id="Discount-tooltip" />
              </div>
            ))}
          </div>
          <div className="OrderSummary-total-short">
            <span className="b OrderSummary-cost-label">
              {isPayNow ? "Total due today" : "Total last payment"}
            </span>
            <span className="pink OrderSummary-cost-price">{total}</span>
            <img
              className="Form-label-tooltip OrderSummary-tooltip-low "
              src={questionMark}
              data-for="Discount-tooltip"
              alt="tooltip"
              data-tip={
                "Further fees may be applied to cover\n" +
                "damaged or lost items, however we will\n" +
                "contact you to discuss before any charges\n" +
                "are added. See terms and conditions for\n" +
                "full details."
              }
              data-multiline={true}
            />
            <ReactTooltip id="Discount-tooltip" />
          </div>
        </div>
        <div style={{ height: "2rem" }} />
        <CancellationForm
          onSubmit={onSubmit}
          error={error}
          reason_for_leaving={undefined}
        />
      </>
    );
  } else if (error) {
    return (
      <div style={{ marginTop: "1rem" }}>
        <div className="Form-page-error">
          {error.message || "An error has occurred"}
        </div>
      </div>
    );
  }
  return (
    <div className="OrderSummary OrderSummary-placeholder">
      <h3>Loading...</h3>
    </div>
  );
};

export default CancellationSummary;
