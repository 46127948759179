import React, { Component, CSSProperties, ImgHTMLAttributes } from "react";

type Props = Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> & {
  preview?: string;
  image: string;
};

type State = {
  currentImage?: string;
  cached?: boolean;
  loading: boolean;
};

export default class ProgressiveImage extends Component<Props, State> {
  state: State = {
    currentImage: this.props.preview,
    loading: true,
  };

  loadingImage: undefined | HTMLImageElement;

  componentDidMount() {
    this.fetchImage(this.props.image);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.image !== this.props.image) {
      this.setState({ currentImage: nextProps.preview, loading: true }, () => {
        this.fetchImage(nextProps.image);
      });
    }
  }

  componentWillUnmount() {
    if (this.loadingImage) {
      this.loadingImage.onload = null;
    }
  }

  fetchImage = (src: string) => {
    const image = new Image();
    image.src = src;

    const cached = image.complete;
    image.onload = () => {
      this.setState({
        currentImage: this.loadingImage ? this.loadingImage.src : undefined,
        loading: false,
        cached,
      });
    };
    this.loadingImage = image;
  };

  style = (loading: boolean, cached?: boolean): CSSProperties => {
    return {
      transition: `${!cached ? "0.05s filter linear" : ""}`,
      filter: `${loading ? "blur(30px)" : ""}`,
      objectFit: "cover",
    };
  };

  render() {
    const { currentImage, loading, cached } = this.state;
    const { alt, className } = this.props;
    return (
      <img
        className={className}
        style={this.style(loading, cached)}
        src={currentImage}
        alt={alt}
      />
    );
  }
}
