import "./index.scss";

import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import { WEBSITE_URL } from "../../constants";
import CloseIcon from "../../static/images/icons/icon-close.png";
import logoWhite from "../../static/images/img-logo.png";
import logoPink from "../../static/images/logo-sm--pink.png";
import AccountButtons from "./accountButtons";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openNav: false,
      scrolled: false,
    };
    this.handleOnMouseClick = this.handleOnMouseClick.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.buttonClicked = this.buttonClicked.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  closeNav = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      openNav: !this.state.openNav,
    });
  };

  //Mobile Nav
  toggleNav = (e) => {
    e.preventDefault();
    if (e.target.className.indexOf("burger-close") >= 0)
      this.setState({ openNav: false });
    else this.setState({ openNav: true });
  };

  scrollListener = () => {
    // if the scroll height is greater than the header height then add the class
    const header = document.querySelector(".Header");
    if (header) {
      if (global.scrollY > 0 && !this.state.scrolled) {
        this.setState({ scrolled: true });
      }
      if (global.scrollY <= 0 && this.state.scrolled) {
        this.setState({ scrolled: false });
      }
      document.documentElement.dataset.scroll = global.scrollY;
    }
  };

  componentDidMount() {
    // get how much the page has scrolled
    if (global.addEventListener) {
      global.addEventListener("scroll", this.scrollListener);
      document.addEventListener("mousedown", this.handleOnMouseClick);
      document.documentElement.dataset.scroll = "0";
    }
  }

  componentWillUnmount() {
    if (global.removeEventListener) {
      global.removeEventListener("scroll", this.scrollListener);
      document.removeEventListener("mousedown", this.handleOnMouseClick);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  buttonClicked() {
    this.setState({
      openNav: !this.state.openNav,
    });
  }

  handleOnMouseClick(event) {
    const className =
      event.target !== undefined && event.target.className !== undefined
        ? event.target.className
        : null;
    if (typeof className === "string" && !!className) {
      if (className.indexOf("nav-link") >= 0) {
        this.setState({ openNav: false });
      }
    }
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ openNav: false });
    }
  }

  render() {
    const { scrolled, openNav } = this.state;
    const isBlogPage = window.location.pathname.includes("/blog");
    const normalLogoSrc = openNav || scrolled ? logoPink : logoWhite;
    const logoSrc = isBlogPage ? logoPink : normalLogoSrc;

    return (
      <div
        className={`Navbar items-end ${this.state.scrolled ? "nav-bg" : ""} ${
          this.state.openNav ? "active" : ""
        }`}
      >
        <h1 className="mw4 w-20-1 w-100 pv2 ph2 ml2 dn-l">
          <a className="flex items-center" href={WEBSITE_URL}>
            <img
              className={"pageLogo"}
              src={logoSrc}
              alt={this.props.siteTitle}
            />
            {isBlogPage && <span className={"Navbar-blog-label"}>blog</span>}
          </a>
        </h1>

        <div
          className={`${this.state.openNav ? "active" : ""} ${
            this.state.scrolled ? "smaller-nav" : ""
          } Navbar__content flex flex-row-l flex-column items-center-l items-start justify-between-l ph3-l pv3 pv4-l`}
          ref={this.setWrapperRef}
        >
          <h1 className="pv2 ph2 ml2 db relative">
            <a className="flex items-center" href={WEBSITE_URL}>
              <img
                className="mw-100-l w-100 navbarLogo"
                src={logoSrc}
                alt={this.props.siteTitle}
              />
              {isBlogPage && (
                <span
                  className={`Navbar-blog-label Navbar-blog-label-content ${
                    scrolled ? "Navbar-blog-label-content-scrolled" : ""
                  }`}
                >
                  blog
                </span>
              )}
            </a>
          </h1>
          <div className="closePopUp  dn-l" onClick={this.closeNav}>
            <img className="close-icon" src={CloseIcon} alt="" />
          </div>

          <div className="nav-container mt2 mt0-l flex flex-row-l flex-column items-center-l items-start">
            {this.props.pages.map((navItem, i) => {
              if (navItem.external) {
                return (
                  <a
                    href={navItem.path}
                    target="_blank"
                    rel="noreferrer"
                    className={`items-center flex ph3 pv2 nav-link ${
                      isBlogPage && !this.state.scrolled ? "nav-link-blog" : ""
                    }`}
                    key={i}
                  >
                    {navItem.navName}
                  </a>
                );
              }
              return (
                <NavLink
                  key={i}
                  activeClassName="nav-current"
                  className={`items-center flex ph3 pv2 nav-link ${
                    isBlogPage && !this.state.scrolled ? "nav-link-blog" : ""
                  }`}
                  to={navItem.path}
                  // onClick={this.closeNav}
                >
                  {navItem.navName}
                </NavLink>
              );
            })}
            <AccountButtons
              isBlogPage={isBlogPage}
              buttonClicked={this.buttonClicked}
              openNav={this.state.openNav}
            />
          </div>
          <div className="social-links">
            <a
              className="social-link social-link--facebook"
              href="https://www.facebook.com/buzzbike.cc/"
            >
              Facebook
            </a>
            <a
              className="social-link social-link--instagram"
              href="https://www.instagram.com/buzzbike/"
            >
              Instagram
            </a>
            <a
              className="social-link social-link--twitter"
              href="https://twitter.com/buzzbike"
            >
              Twitter
            </a>
            <a
              className="social-link social-link--vimeo"
              href="https://vimeo.com/user46481994"
            >
              Vimeo
            </a>
            <a
              className="social-link social-link--youtube"
              href="https://www.youtube.com/channel/UCNK-r9k30BE1FiWqfx0lSlQ"
            >
              Youtube
            </a>
          </div>
        </div>
        <div
          className={`${this.state.openNav ? "openNav" : ""} burger dn-l`}
          onClick={this.toggleNav}
        >
          <div className={`burger-bar ${isBlogPage ? "bg-black" : ""}`} />
          <div className={`burger-bar ${isBlogPage ? "bg-black" : ""}`} />
          <div className={`burger-bar ${isBlogPage ? "bg-black" : ""}`} />
        </div>
      </div>
    );
  }
}
export default withRouter(Navbar);
