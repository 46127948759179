import { concat, debounce, DebouncedFunc, shuffle } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";

import Form from "../../components/Form";
import { FieldRow, SubmitHandler } from "../../components/Form/formTypes";
import { chain, checkNonEmpty } from "../../utils/validateHelpers";

const reasonsForLeavingOptions: {
  value: string;
  label: string;
  disabled?: boolean;
}[] = [
  { value: "1 Leaving London", label: "Leaving London" },
  { value: "2 Buying a bike", label: "Buying a bike" },
  {
    value: "3 Not riding enough",
    label: "Not riding enough",
  },
  {
    value: "4 Change in commute",
    label: "Change in commute",
  },
  {
    value: "5 Unhappy with the service",
    label: "Unhappy with the service",
  },
  {
    value: "6 Signing up to another service",
    label: "Signing up to another service",
  },
  {
    value: "7 Issues with the Buzzbike",
    label: "Issues with the Buzzbike",
  },
  { value: "8 Too expensive", label: "Too expensive" },
  { value: "9 The Weather", label: "The Weather" },
  {
    value: "10 Lack of confidence cycling",
    label: "Lack of confidence cycling",
  },
];

export type CancellationFormData = {
  reason_for_leaving: string;
  reason_details: string;
};
type Props = {
  error?: unknown;
  reason_for_leaving?: string;
  onSubmit: SubmitHandler<CancellationFormData>;
};

export default function CancellationForm(props: Props) {
  const { error, reason_for_leaving, onSubmit } = props;
  const [reasonForLeaving, setReasonForLeaving] =
    useState<string | undefined>(reason_for_leaving);

  const shuffledReasonsOptions = concat(
    {
      value: "",
      label: "Select...",
      disabled: true,
    },
    shuffle(reasonsForLeavingOptions),
    { value: "99 other", label: "Other - please specify" }
  );

  const fields: FieldRow<CancellationFormData>[] = [
    [
      {
        name: "reason_for_leaving",
        label: "Reason for Leaving?",
        labelClassName: "pink",
        options: shuffledReasonsOptions,
        validate: chain([checkNonEmpty()]),
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (e.target) {
            setReasonForLeaving(e.target.value);
          }
        },
      },
    ],
    [
      {
        name: "reason_details",
        type: "text",
        labelClassName: "hidden",
        style: reasonForLeaving === "99 other" ? {} : { display: "none" },
        placeholder: "Other reason",
      },
    ],
  ];

  const debouncedOnSubmit: DebouncedFunc<SubmitHandler<CancellationFormData>> =
    debounce(onSubmit, 1000, {
      leading: true,
    });

  useEffect(() => {
    setReasonForLeaving(reason_for_leaving);
  }, [reason_for_leaving]);

  if (error) {
    return <></>;
  }
  return (
    <Form<CancellationFormData>
      fields={fields}
      submitProps={{
        prevCopy: "Go Back",
        nextCopy: "Arrange Collection",
        leftArrow: false,
        rightArrow: false,
      }}
      onSubmit={debouncedOnSubmit}
      key="account_cancel_sub_form"
      id="account_cancel_sub_form"
    />
  );
}
