/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: this is an old file, it's been converted to TS but some any/ignore remain. Fix what you can!
import "./style.scss";
import "react-day-picker/lib/style.css";

import { flatten, get, isEmpty, isEqual, isFunction, trim } from "lodash";
import React, { SyntheticEvent } from "react";
import InputMask from "react-input-mask";
import ReactTooltip from "react-tooltip";

import { DateInputProps } from "components/DateInput/DateInput";
import HeightInput from "components/HeightInput/HeightInput";
import CustomSubmit from "pages/UserDetails/customSubmit";
import questionMark from "static/images/icons/icon-questionmark.png";

import DateInput from "../DateInput";
import {
  AsyncSelectFormField,
  CheckboxFormField,
  DateFormField,
  DynamicFormField,
  DynamicFormFieldDefinition,
  FieldRow,
  FieldType,
  HeightFormField,
  InputFormField,
  isAsyncSelectField,
  isCallbackField,
  isCheckboxField,
  isDateField,
  isDynamicField,
  isHeightField,
  isHtmlField,
  isSelectField,
  Props,
  State,
  SyncSelectFormField,
} from "./formTypes";
import Select from "./Select";
import { ErrorMapping, ErrorName, ValueMapping, ValueName } from "./types";

const fieldValue = <T,>(n: unknown): ValueName<keyof T> => {
  return `${n}_value` as ValueName<keyof T>;
};

const fieldError = <T,>(n: unknown): ErrorName<keyof T> => {
  return `${n}_error` as ErrorName<keyof T>;
};

export default class Form<TData> extends React.Component<
  Props<TData>,
  State<TData>
> {
  constructor(props: Props<TData>) {
    super(props);

    this.validators = {};
    this.crossValidators = {};
    this.conditions = {};

    this.state = this.getInitialState(props);
  }

  validators: {
    [K in keyof TData]?: (v: TData[K] | string) => string | null;
  };
  crossValidators: {
    [K in keyof TData]?: {
      name: keyof TData;
      error: string;
    };
  };
  conditions: {
    [K in keyof TData]?: boolean | (() => boolean);
  };

  componentDidUpdate(prevProps: Props<TData>) {
    if (!isEqual(prevProps.data, this.props.data)) {
      const allFields = flatten(this.props.fields) as DynamicFormField<
        TData,
        unknown,
        keyof TData
      >[];
      const newState = allFields.filter(isDynamicField).reduce((acc, field) => {
        if (!field.name) {
          return acc;
        }
        const key = fieldValue<TData>(field.name);
        const currentValue = this.state[key];
        const newValue = this.props.data
          ? this.props.data[field.name]
          : undefined;

        const isBlank = typeof currentValue === "string" && currentValue === "";
        const updatedValue = isBlank && newValue ? newValue : currentValue;

        return { ...acc, [key]: updatedValue };
      }, {});
      this.setState(newState);
    }
  }

  getFieldDefaultValue = (field: any): any => {
    if (this.isCheckbox(field)) {
      return false;
    }
    return field.defaultValue || "";
  };

  getInitialState = (props: Props<TData>): State<TData> =>
    props.fields.reduce((acc, row) => {
      const rowFields = Object.values(row) as DynamicFormField<
        TData,
        unknown,
        keyof TData
      >[];
      return rowFields.filter(isDynamicField).reduce((innerAcc, field) => {
        if (!field.name) {
          return innerAcc;
        }
        if (field.validate) {
          this.validators[field.name] = field.validate;
        }
        if (field.crossValidate) {
          this.crossValidators[field.name] = field.crossValidate;
        }

        if (field.condition) {
          this.conditions[field.name] = field.condition;
        }

        const defaultValue = this.getFieldDefaultValue(field);
        const fieldName = field.name;
        const propsValue = props.data && props.data[fieldName];

        return {
          ...innerAcc,
          [fieldValue<TData>(field.name)]: propsValue || defaultValue,
          [fieldError<TData>(field.name)]: null,
        };
      }, acc);
    }, {} as State<TData>);

  validate = (name: keyof TData, value?: any): string | null | undefined => {
    // @ts-ignore
    if (this.conditions[name] && !this.conditions[name]()) {
      return;
    }
    const validate = this.validators[name];
    const val =
      value ||
      (this.state[fieldValue(name)] as unknown as TData[typeof name]) ||
      "";

    if (validate) {
      return validate(val);
    }
    const crossValidate = this.crossValidators[name];
    if (crossValidate) {
      if (val !== this.state[fieldValue(crossValidate.name)]) {
        return crossValidate.error;
      }
    }
    return null;
  };

  validateAll = (): ErrorMapping<TData> => {
    const allFields = flatten(this.props.fields) as DynamicFormField<
      TData,
      unknown,
      keyof TData
    >[];
    return allFields.filter(isDynamicField).reduce((acc, field) => {
      // TODO there was a bug here, using window.name. preserved in case relying on it...
      // !this.conditions[name] || this.conditions[name]();
      const byCondition = true;
      return field.name && byCondition
        ? {
            ...acc,
            [fieldError<TData>(field.name)]: this.validate(field.name),
          }
        : acc;
    }, {});
  };

  hasErrors = () => {
    const errors = this.validateAll();
    const filteredErrors = Object.values(errors).filter(
      (errorValue) => !isEmpty(errorValue)
    );
    return !isEmpty(filteredErrors);
  };

  isCheckbox = (field: any) => field.type === "checkbox";

  handleDateInput =
    (name: keyof TData, onChange: any, updateOthers?: any) =>
    (value?: string) => {
      const valueForField = this.state[fieldValue<TData>(name)];
      if (typeof valueForField === "string" && valueForField === value) {
        return;
      }

      this.setState({
        [fieldValue<TData>(name)]: value,
      } as unknown as Pick<State<TData>, ValueName<typeof name>>);

      if (updateOthers) {
        updateOthers(value).then((others: any) => {
          this.setState(
            Object.keys(others).reduce((acc, x: any) => {
              acc[fieldValue<TData>(x)] = others[x];
              return acc;
            }, {} as Pick<State<TData>, keyof ValueMapping<TData>>)
          );
        });
      }

      if (onChange) {
        const { newState } =
          onChange(event, { ...this.state, [fieldValue(name)]: value }) || {};
        this.setState(newState);
      }
    };

  handleInput = (onChange: any, updateOthers?: any) => (event: any) => {
    const { target } = event;
    const value = this.isCheckbox(target) ? target.checked : target.value;
    const name = target.name as keyof TData;

    //no change?
    if (this.state[fieldValue<TData>(name)] === value) {
      return;
    }

    this.setState({
      [fieldValue<TData>(name)]: value,
    } as Pick<State<TData>, keyof ValueMapping<TData>>);

    if (updateOthers) {
      updateOthers(value).then((others: any) => {
        if (others) {
          this.setState(
            Object.keys(others).reduce((acc, x: any) => {
              acc[fieldValue<TData>(x)] = others[x];
              acc[fieldError<TData>(x)] = this.validate(x, others[x]) as any;
              return acc;
            }, {} as Pick<State<TData>, keyof ValueMapping<TData> | keyof ErrorMapping<TData>>)
          );
        }
      });
    }

    if (onChange) {
      const { newState } =
        onChange(event, { ...this.state, [fieldValue(name)]: value }) || {};
      this.setState(newState);
    }
  };

  handleHeightInput = (name: string) => (text: string) => {
    this.setState({
      [fieldValue<TData>(name)]: text,
    } as unknown as Pick<State<TData>, keyof ValueMapping<TData>>);
  };

  getData = () => {
    const allFields = flatten(this.props.fields) as DynamicFormField<
      TData,
      unknown,
      keyof TData
    >[];
    return allFields.filter(isDynamicField).reduce((acc, field) => {
      if (!field.name) {
        return acc;
      }
      const condition = this.conditions[field.name];
      // @ts-ignore
      if (!condition || condition()) {
        acc[field.name] = this.state[
          fieldValue<TData>(field.name)
        ] as unknown as TData[typeof field.name];
      }
      return acc;
    }, {} as TData);
  };

  onSubmit = async (
    event: SyntheticEvent<HTMLElement>,
    additionalData?: any
  ): Promise<unknown> => {
    event.preventDefault();

    let errors;
    if (additionalData && additionalData.noValidate) {
      errors = {};
    } else {
      errors = this.validateAll();
    }
    this.setState({
      ...errors,
      formPageError: undefined,
    } as Pick<State<TData>, "formPageError" | keyof ErrorMapping<TData>>);

    if (Object.values(errors).filter((x) => x).length === 0) {
      const { onSubmit } = this.props;

      const submit = onSubmit;

      if (submit) {
        const formData = this.getData();
        // @ts-ignore
        return submit(
          formData,
          event,
          additionalData,
          this.handleErrorOrResponse
        )
          .then((responseAndHandlers) => {
            if (!responseAndHandlers) {
              return;
            }
            const { response, onSuccess, onFailure, newState } =
              responseAndHandlers;
            const hasErrors =
              response &&
              response.status === 200 &&
              this.handleFieldErrorsInResponse(response);
            if (hasErrors) {
              onFailure && onFailure(response);
            } else {
              if (newState) {
                const { data, ...others } = newState;
                if (data) {
                  Object.keys(data).map((key) => {
                    this.setState({
                      [fieldValue<TData>(key as keyof TData)]:
                        data[key as keyof typeof data],
                    } as unknown as Pick<State<TData>, keyof ValueMapping<TData>>);
                  });
                }
                this.setState(others);
              }
              onSuccess && onSuccess(response);
            }
            return response;
          })
          .catch((err) => {
            return this.handleError(err);
          });
      }
    }
  };

  handleErrorOrResponse = (errorOrResponse: any) => {
    const body = errorOrResponse.body
      ? errorOrResponse
      : get(errorOrResponse, "response");
    return (
      (body && this.handleFieldErrorsInResponse(body)) ||
      this.handleError(errorOrResponse)
    );
  };

  handleError = (err: any) => {
    const response = err.response || err;
    let description =
      get(response, "body.description") ||
      get(response, "data.description") ||
      response.message;

    //if we get here and there are field_errors, it means that they correspond to a different page
    // (because ones on the current page are picked up in the handleFieldErrorsInResponse function)
    if (!description || description === "") {
      const errors =
        get(err, "response.data.field_errors") ||
        get(err, "response.data.errors") ||
        {};
      const keys = Object.keys(errors);
      description =
        keys.length > 0
          ? keys.map((key, j) => <p key={j}>{errors[key]}</p>)
          : err.message;
    }

    if (typeof description === "string" && description.trim().length === 0) {
      description = get(err.response, "statusText") || "An error occurred";
    }

    this.setState({ formPageError: description });
    console.error(description);
  };

  /**
   *
   * @param response
   * @returns {boolean} true if the field-errors in the response are handled.
   * If there are no field-errors (relevant for this page) false is returned.
   */
  handleFieldErrorsInResponse = (response: any) => {
    const body = response.body || response.data;

    const fieldErrors: {
      [k in keyof TData]?: string;
    } = get(body, "field_errors");
    if (!fieldErrors) {
      return false;
    }

    const fieldErrorNames = Object.keys(fieldErrors) as (keyof TData)[];
    const allFields = flatten(this.props.fields) as DynamicFormField<
      TData,
      unknown,
      keyof TData
    >[];
    const stepErrors = allFields
      .filter(isDynamicField)
      .filter((f) => f.name && fieldErrorNames.includes(f.name))
      .map((f) => f.name)
      .reduce((acc, x) => {
        acc[x] = fieldErrors[x];
        return acc;
      }, {} as typeof fieldErrors);
    if (Object.keys(stepErrors).length > 0) {
      return !this.updateFieldErrors(stepErrors);
    } else {
      return false;
    }
  };

  //returns true if there's no errors for the fields on this form.
  updateFieldErrors = (
    fieldErrors: {
      [k in keyof TData]?: string;
    }
  ) => {
    const errState: ErrorMapping<TData> = {};
    const allFields = flatten(this.props.fields) as DynamicFormField<
      TData,
      unknown,
      keyof TData
    >[];
    allFields.filter(isDynamicField).forEach((field) => {
      if (fieldErrors[field.name]) {
        errState[fieldError<TData>(field.name)] = fieldErrors[field.name];
      }
    });

    this.setState({ ...errState } as any);

    return errState === {};
  };

  onBlur = (event: any) => {
    const { target } = event;
    const isCheckbox = target.type === "checkbox";
    let value;

    if (isCheckbox) {
      value = target.checked;
    } else {
      value = trim(target.value);
      target.value = value;
    }

    const name = target.name as keyof TData;
    const error = this.validate(name);

    this.setState({
      [fieldValue<TData>(name)]: value,
      [fieldError<TData>(name)]: error,
    } as Pick<State<TData>, keyof ValueMapping<TData> | keyof ErrorMapping<TData>>);
  };

  onDayInputBlur = (event: any) => {
    const { target } = event;
    const name = target.name as keyof TData;
    const error = this.validate(name);

    this.setState({
      [fieldError<TData>(name)]: error,
    } as unknown as Pick<State<TData>, keyof ErrorMapping<TData>>);
  };

  renderLabel = <TField extends TData[keyof TData], Key extends keyof TData>(
    field: DynamicFormFieldDefinition<TData, TField, Key>,
    tooltipId?: string
  ) => {
    return (
      <label className={`Form-label ${field.labelClassName || ""}`}>
        {field.label}
        {tooltipId && (
          <div>
            <img
              className="Form-label-tooltip"
              src={field.tooltipImage || questionMark}
              alt="tooltip"
              data-for={tooltipId}
              data-tip={field.tooltip}
              data-multiline={true}
            />
            <ReactTooltip id={tooltipId} />
          </div>
        )}
      </label>
    );
  };

  renderDateField = <Key extends keyof TData>(
    field: DateFormField<TData, Key>,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => {
    const value: FieldType<typeof field> = (this.state as any)[
      fieldValue(field.name)
    ];

    const config: DateInputProps["config"] = {
      disabled: !!field.disabled,
      name: field.name as string,
      type: field.type,
      value,
      onChange: this.handleDateInput(field.name, field.onChange),
      onBlur: this.onDayInputBlur,
      style: field.style,
      placeholder: field.placeholder,
      disableWeekend: field.disableWeekend,
      ...field.props,
    };

    return (
      <div>
        {label}
        <DateInput config={config} />
        {field.after && field.after.html}
        {!field.noError && errorText}
      </div>
    );
  };

  renderCheckboxField = <Key extends keyof TData>(
    field: CheckboxFormField<TData, Key>,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => {
    const value: FieldType<typeof field> = this.state[fieldValue(field.name)];
    return (
      <div>
        <div className={field.className || "flex"}>
          <input
            disabled={!!field.disabled}
            className={`mr2 ${field.disabled ? "Form-input-disabled" : ""}`}
            name={field.name as string}
            type={field.type}
            checked={value}
            onChange={this.handleInput(field.onChange)}
            onBlur={this.onBlur}
            style={field.style}
          />
          {label}
        </div>
        {field.after && field.after.html}
        {!field.noError && errorText}
      </div>
    );
  };

  renderHeightField = <Key extends keyof TData>(
    field: HeightFormField<TData, Key>,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => {
    const value: string = this.state[fieldValue(field.name)];
    return (
      <div>
        <div className={field.className || "flex flex-column"}>
          {label}
          <HeightInput
            height={value}
            className={`mr2 ${field.disabled ? "Form-input-disabled" : ""}`}
            onChange={this.handleHeightInput(field.name as string)}
            style={field.style}
          />
        </div>
        {field.after && field.after.html}
        {!field.noError && errorText}
      </div>
    );
  };

  renderAsyncSelectField = <
    TField extends TData[keyof TData],
    Key extends keyof TData
  >(
    field: AsyncSelectFormField<TData, TField, Key>,
    value: TField,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => (
    <div>
      {label}
      <Select
        name={field.name as string}
        value={value}
        onChange={this.handleInput(field.onChange, field.updateOthers)}
        loadOptions={field.loadOptions}
        placeholder={field.placeholder}
        isSearchable={field.isSearchable}
        onBlur={this.onBlur}
        {...field.props}
      />
      {errorText}
    </div>
  );

  renderSyncSelectField = <
    TField extends TData[keyof TData],
    Key extends keyof TData
  >(
    field: SyncSelectFormField<TData, TField, Key>,
    value: TField,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => (
    <div>
      {label}
      <Select
        name={field.name as string}
        value={value}
        onChange={this.handleInput(field.onChange, field.updateOthers)}
        options={field.options}
        placeholder={field.placeholder}
        {...field.props}
      />
      {errorText}
    </div>
  );

  renderInputField = <Key extends keyof TData>(
    field: InputFormField<TData, Key>,
    value: string,
    label: React.ReactChild,
    errorText: React.ReactChild
  ) => {
    return (
      <div>
        {label}
        <InputMask
          mask=""
          disabled={!!field.disabled}
          className={`Form-input ${
            field.disabled ? "Form-input-disabled" : ""
          }`}
          name={field.name as string}
          type={field.type}
          value={value}
          onChange={this.handleInput(field.onChange)}
          onBlur={this.onBlur}
          style={field.style}
          placeholder={field.placeholder}
          {...field.props}
          {...field.inputMask}
        />
        {field.after && field.after.html}
        {!field.noError && errorText}
      </div>
    );
  };

  renderField = <TField extends TData[keyof TData], Key extends keyof TData>(
    field: DynamicFormFieldDefinition<TData, TField, Key>,
    i: number,
    j: number
  ) => {
    const tooltipId = field.tooltip && `${field.name}_${i}_${j}`;

    const label = this.renderLabel(field, tooltipId);

    const value: FieldType<typeof field> = this.state[fieldValue(field.name)];

    const errorText = (
      <div className="Form-error" id={`${field.name}_err`}>
        {this.state[fieldError<TData>(field.name)] || ""}
      </div>
    );

    if (isDateField(field)) {
      return this.renderDateField(field, label, errorText);
    }

    if (isSelectField(field)) {
      if (isAsyncSelectField(field)) {
        return this.renderAsyncSelectField(field, value, label, errorText);
      } else {
        return this.renderSyncSelectField(field, value, label, errorText);
      }
    }

    if (isCheckboxField(field)) {
      return this.renderCheckboxField(field, label, errorText);
    }

    if (isHeightField(field)) {
      return this.renderHeightField(field, label, errorText);
    }

    return this.renderInputField(field, value, label, errorText);
  };

  renderRow = (row: FieldRow<TData>, i: number) => (
    <div key={`form_row_${i}`} className="Form-row">
      {row
        .filter((field) => {
          if (field.hide) {
            return false;
          }
          if (isFunction(field.condition)) {
            return field.condition();
          }
          return !field.condition;
        })
        .map(
          <TField extends TData[keyof TData], Key extends keyof TData>(
            field: DynamicFormField<TData, TField, Key>,
            j: number
          ) => {
            const wrapperClassName = `Form-row-wrapper ${
              field.direction || "flex-column"
            } ${field.justify || ""} ${j < row.length - 1 ? "mr4" : ""}`;

            let child;
            if (isHtmlField(field)) {
              child = field.html;
            } else if (isCallbackField(field)) {
              child = field.renderHtml(this.props.data);
            } else {
              child = this.renderField(field, i, j);
            }

            return (
              <div
                key={`${this.props.id}_form_field_${i}_${j}`}
                className={wrapperClassName}
              >
                {child}
              </div>
            );
          }
        )}
    </div>
  );

  render() {
    const { fields, submitText, className, submitProps } = this.props;

    const props = { nextCopy: submitText, ...submitProps };

    const submit = (
      <CustomSubmit
        onSubmit={this.onSubmit}
        {...props}
        nextDisabled={this.hasErrors()}
      />
    );

    return (
      <form
        onSubmit={(...rest) => {
          return this.onSubmit(...rest);
        }}
        className={`Form ${className || ""}`}
      >
        {fields.map(this.renderRow)}
        <div className="Form-page-error">{this.state.formPageError || ""}</div>
        <div className="Form-submit-wrapper">{submit}</div>
      </form>
    );
  }
}
