import React from "react";

import { SvgSizeProps } from "./types";

export default ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "24px"}
    height={height || "29px"}
    viewBox="0 0 24 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="unlocked" transform="translate(0.3 0.3)">
      <path
        id="Shape"
        d="M19.221,28H3.779A3.768,3.768,0,0,1,0,24.251V14.118a3.768,3.768,0,0,1,3.779-3.748H18.818V7.519c0-3.574-3.283-6.482-7.318-6.482S4.181,3.945,4.181,7.519a.522.522,0,0,1-1.045,0C3.137,3.373,6.888,0,11.5,0s8.364,3.373,8.364,7.519v2.911A3.746,3.746,0,0,1,23,14.118V24.251A3.768,3.768,0,0,1,19.221,28ZM3.779,11.408a2.725,2.725,0,0,0-2.733,2.711V24.251a2.726,2.726,0,0,0,2.733,2.712H19.221a2.726,2.726,0,0,0,2.733-2.712V14.118a2.725,2.725,0,0,0-2.733-2.711Z"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.6"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M2.5,7A2.3,2.3,0,0,1,0,5V2A2.3,2.3,0,0,1,2.5,0,2.3,2.3,0,0,1,5,2V5A2.3,2.3,0,0,1,2.5,7Zm0-6A1.148,1.148,0,0,0,1.25,2V5A1.148,1.148,0,0,0,2.5,6,1.148,1.148,0,0,0,3.75,5V2A1.148,1.148,0,0,0,2.5,1Z"
        transform="translate(9 15)"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.6"
      />
    </g>
  </svg>
);
