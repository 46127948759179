import moment from "moment";
import preval from "preval.macro";
import React from "react";

import packageJson from "../../../package.json";

const buildTimestamp = preval`module.exports = Number(new Date().getTime()/1000).toFixed(0);`;

const ts = moment.unix(buildTimestamp);
const mins = ts.hour() * 60 + ts.minute();
const buildTsString = `${ts.format("YYYY-MM-DD")}-${mins}`;

class VersionNumber extends React.Component {
  render() {
    return (
      <div>
        {packageJson.version}
        {"."}
        {buildTimestamp} {"("}
        {this.getBuildTsString()}
        {")"}
      </div>
    );
  }
}

VersionNumber.propTypes = {};

VersionNumber.defaultProps = {};

export { VersionNumber, buildTsString };
