import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { GET_POSTS } from "graphql-root/graphcms";
import { isEmpty } from "lodash";
import React from "react";
import ReactLoading from "react-loading";
import { getDeepValue } from "utils";

import { Blog } from "components/BlogPage/types";
import BlogListItem from "pages/Blogs/components/BlogListItem";

import styles from "./Blogs.module.scss";

function Blogs() {
  const { loading, error, data } = useQuery(GET_POSTS, {
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <ReactLoading
          type={"bubbles"}
          color={"#ff4074"}
          height={"4rem"}
          width={"4rem"}
        />
      </div>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return null;
  }

  const blogs = getDeepValue<Blog[]>(data, "posts", []);
  if (isEmpty(blogs)) {
    return null;
  }

  return (
    <>
      <div className={"Header"} />
      <div className={styles.container}>
        {(blogs || []).map((blog, index) => (
          <BlogListItem
            className={`${styles.listItem} ${
              index === 0 ? styles.firstListItem : ""
            }`}
            blog={blog}
            key={`blog-item-${index}`}
          />
        ))}
      </div>
    </>
  );
}

export default Blogs;
