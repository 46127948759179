import React from "react";

import { SvgComponentProps } from "./types";

export default ({ width, height, className, stroke }: SvgComponentProps) => (
  <svg
    width={width || "20px"}
    height={height || "20px"}
    viewBox="0 0 100 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className || ""}
  >
    <g
      id="dasghishfihgfsine4nrtn"
      stroke={stroke || "#FFFFFF"}
      strokeWidth="15"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M10,56 L33,80 L90,23" />
    </g>
  </svg>
);
