import { SEGMENT_EVENT } from "./types";

const dataLayer = window.dataLayer || [];

type AnalyticsEvent = {
  event: string;
};

export const gtm = {
  pageview: () => {},
  event: (event: AnalyticsEvent) => {
    console.log("firing ", event);
    dataLayer.push(event);
    if (window.analytics) {
      window.analytics.track(SEGMENT_EVENT.ONBOARDING_FUNNEL, {
        funnelStep: event.event,
        acValue: event.event,
      });
    }
  },
};
