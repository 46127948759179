import React from "react";

import { SvgSizeProps } from "./types";

export default ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "11px"}
    height={height || "11px"}
    viewBox="0 0 11 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M10.5671,0.8477 C10.5663,1.1561 10.3574,1.4251 10.0589,1.5026 L10.0589,9.7463 L9.5494,9.2368 L8.5309,10.2553 L7.5124,9.2368 L6.4939,10.2553 L5.4754,9.2368 L4.4568,10.2553 L3.4383,9.2368 L2.4177,10.2553 L1.3992,9.2368 L0.8901,9.7463 L0.8901,1.5026 C0.5588,1.4179 0.3424,1.0997 0.3856,0.7605 C0.4288,0.4213 0.718,0.1674 1.06,0.168397056 L9.8883,0.168397056 C10.2632,0.1688 10.5669,0.4727 10.5671,0.8477 Z M9.7189,8.9265 L9.7189,1.0171 L1.2294,1.0171 L1.2294,8.9265 L1.3992,8.7566 L2.4181,9.7752 L3.4383,8.7566 L4.4552,9.7752 L5.4749,8.7566 L6.4935,9.7752 L7.512,8.7566 L8.5305,9.7752 L9.549,8.7566 L9.7189,8.9265 Z M10.0585,1.1415 L10.0581,1.1415 C10.1916,1.0648 10.2566,0.908 10.2168,0.7595 C10.1771,0.6107 10.0422,0.5076 9.8883,0.508 L1.06,0.508 C0.906,0.5076 0.7711,0.6107 0.7315,0.7595 C0.6916,0.908 0.7567,1.0648 0.8901,1.1415 L0.8901,0.6779 L10.0585,0.6779 L10.0585,1.1415 Z"
        id="path-dfsagfsag1"
      />
      <path
        d="M3.1148,0.6952 C3.2086,0.6952 3.2847,0.7713 3.2847,0.865 C3.2847,0.9588 3.2086,1.0349 3.1148,1.0349 L0.5683,1.0349 C0.4745,1.0349 0.3985,0.9588 0.3985,0.865 C0.3985,0.7713 0.4745,0.6952 0.5683,0.6952 L3.1148,0.6952 Z"
        id="path-dfsagfsag3"
      />
      <path
        d="M3.1714,1.3185 C3.2652,1.3185 3.3413,1.3946 3.3413,1.4884 C3.3413,1.5821 3.2652,1.6582 3.1714,1.6582 L0.6249,1.6582 C0.5311,1.6582 0.4551,1.5821 0.4551,1.4884 C0.4551,1.3946 0.5311,1.3185 0.6249,1.3185 L3.1714,1.3185 Z"
        id="path-dfsagfsag5"
      />
      <path
        d="M2.1719,0.5069 C2.2657,0.5069 2.3417,0.5829 2.3417,0.6767 C2.3417,0.7705 2.2657,0.8465 2.1719,0.8465 L0.474,0.8465 C0.3802,0.8465 0.3042,0.7705 0.3042,0.6767 C0.3042,0.5829 0.3802,0.5069 0.474,0.5069 L2.1719,0.5069 Z"
        id="path-dfsagfsag7"
      />
      <path
        d="M0.3985,0.675 C0.3985,0.63 0.4165,0.5868 0.4483,0.555 C0.4801,0.5232 0.5233,0.5052 0.5683,0.5052 L0.908,0.5052 C1.0018,0.5052 1.0778,0.5812 1.0778,0.675 C1.0778,0.7688 1.0018,0.8449 0.908,0.8449 L0.5683,0.8449 C0.4745,0.8449 0.3985,0.7688 0.3985,0.675 Z"
        id="path-dfsagfsag9"
      />
      <path
        d="M3.2847,1.2984 C3.2847,1.3921 3.2086,1.4682 3.1148,1.4682 L0.5683,1.4682 C0.4745,1.4682 0.3985,1.3921 0.3985,1.2984 C0.3985,1.2046 0.4745,1.1285 0.5683,1.1285 L3.1148,1.1285 C3.2086,1.1285 3.2847,1.2046 3.2847,1.2984 Z"
        id="path-dfsagfsag11"
      />
      <path
        d="M1.0925,3.1515 C0.9842,3.1515 0.896,3.0643 0.895,2.956 L0.895,1.2442 C0.8888,0.9814 1.0253,0.7357 1.252,0.6025 C1.4786,0.4692 1.7596,0.4692 1.9862,0.6025 C2.2128,0.7357 2.3494,0.9814 2.3432,1.2442 C2.3432,1.3532 2.2548,1.4417 2.1457,1.4417 C2.0366,1.4417 1.9482,1.3532 1.9482,1.2442 C1.96,1.1192 1.8998,0.9983 1.7932,0.9322 C1.6864,0.8663 1.5517,0.8663 1.4449,0.9322 C1.3383,0.9983 1.2782,1.1192 1.29,1.2442 L1.29,2.956 C1.2889,3.0643 1.2007,3.1515 1.0925,3.1515 Z"
        id="path-dfsagfsag13"
      />
      <path
        d="M1.8168,1.1515 L0.6317,1.1515 C0.5227,1.1515 0.4342,1.063 0.4342,0.954 C0.4342,0.8449 0.5227,0.7564 0.6317,0.7564 L1.8168,0.7564 C1.9259,0.7564 2.0143,0.8449 2.0143,0.954 C2.0143,1.063 1.9259,1.1515 1.8168,1.1515 Z"
        id="path-dfsagfsag15"
      />
      <path
        d="M1.5536,1.1639 L0.6317,1.1639 C0.5227,1.1639 0.4342,1.0755 0.4342,0.9664 C0.4342,0.8573 0.5227,0.7689 0.6317,0.7689 L1.5536,0.7689 C1.6627,0.7689 1.7511,0.8573 1.7511,0.9664 C1.7511,1.0755 1.6627,1.1639 1.5536,1.1639 Z"
        id="path-dfsagfsag17"
      />
    </defs>
    <g
      id="Page-asdfgdfs28"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Buzzbike_v5" transform="translate(-76.000000, -285.000000)">
        <g id="Group" transform="translate(76.000000, 285.000000)">
          <g id="Group-sdgsadfgasfg2979">
            <g id="Group-sdgsadfgasfg2978">
              <mask id="mask-dsagsafg2" fill="white">
                <use xlinkHref="#path-dfsagfsag1" />
              </mask>
              <g id="Clip-dasgfafdsgasdf2977" />
              <polygon
                id="Fill-asfdgsadfg2976"
                fill="#000000"
                mask="url(#mask-dsagsafg2)"
                points="-1.6859 -1.8976 12.6331 -1.8976 12.6331 12.3213 -1.6859 12.3213"
              />
            </g>
          </g>
          <g
            id="Group-sdgsadfgasfg2981"
            transform="translate(5.000000, 6.000000)"
          >
            <mask id="mask-dsagsafg4" fill="white">
              <use xlinkHref="#path-dfsagfsag3" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2980" />
            <polygon
              id="Fill-asfdgsadfg2979"
              fill="#000000"
              mask="url(#mask-dsagsafg4)"
              points="-1.6675 -1.3707 5.3506 -1.3707 5.3506 3.1008 -1.6675 3.1008"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2984"
            transform="translate(5.000000, 3.000000)"
          >
            <mask id="mask-dsagsafg6" fill="white">
              <use xlinkHref="#path-dfsagfsag5" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2983" />
            <polygon
              id="Fill-asfdgsadfg2982"
              fill="#000000"
              mask="url(#mask-dsagsafg6)"
              points="-1.6109 -0.7474 5.4072 -0.7474 5.4072 3.7241 -1.6109 3.7241"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2987"
            transform="translate(6.000000, 5.000000)"
          >
            <mask id="mask-dsagsafg8" fill="white">
              <use xlinkHref="#path-dfsagfsag7" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2986" />
            <polygon
              id="Fill-asfdgsadfg2985"
              fill="#000000"
              mask="url(#mask-dsagsafg8)"
              points="-1.7618 -1.5591 4.4077 -1.5591 4.4077 2.9125 -1.7618 2.9125"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2990"
            transform="translate(5.000000, 5.000000)"
          >
            <mask id="mask-dsagsafg10" fill="white">
              <use xlinkHref="#path-dfsagfsag9" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2989" />
            <polygon
              id="Fill-asfdgsadfg2988"
              fill="#000000"
              mask="url(#mask-dsagsafg10)"
              points="-1.6675 -1.5607 3.1438 -1.5607 3.1438 2.9108 -1.6675 2.9108"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2993"
            transform="translate(5.000000, 2.000000)"
          >
            <mask id="mask-dsagsafg12" fill="white">
              <use xlinkHref="#path-dfsagfsag11" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2992" />
            <polygon
              id="Fill-asfdgsadfg2991"
              fill="#000000"
              mask="url(#mask-dsagsafg12)"
              points="-1.6675 -0.9374 5.3506 -0.9374 5.3506 3.5341 -1.6675 3.5341"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2996"
            transform="translate(2.000000, 3.000000)"
          >
            <mask id="mask-dsagsafg14" fill="white">
              <use xlinkHref="#path-dfsagfsag13" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2995" />
            <polygon
              id="Fill-asfdgsadfg2994"
              fill="#000000"
              mask="url(#mask-dsagsafg14)"
              points="-1.1712 -1.5635 4.4094 -1.5635 4.4094 5.2174 -1.1712 5.2174"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg2999"
            transform="translate(2.000000, 5.000000)"
          >
            <mask id="mask-dsagsafg16" fill="white">
              <use xlinkHref="#path-dfsagfsag15" />
            </mask>
            <g id="Clip-dasgfafdsgasdf2998" />
            <polygon
              id="Fill-asfdgsadfg2997"
              fill="#000000"
              mask="url(#mask-dsagsafg16)"
              points="-1.6317 -1.3095 4.0803 -1.3095 4.0803 3.2174 -1.6317 3.2174"
            />
          </g>
          <g
            id="Group-sdgsadfgasfg3002"
            transform="translate(2.000000, 4.000000)"
          >
            <mask id="mask-dsagsafg18" fill="white">
              <use xlinkHref="#path-dfsagfsag17" />
            </mask>
            <g id="Clip-dasgfafdsgasdf3001" />
            <polygon
              id="Fill-asfdgsadfg3000"
              fill="#000000"
              mask="url(#mask-dsagsafg18)"
              points="-1.6317 -1.2971 3.8171 -1.2971 3.8171 3.2299 -1.6317 3.2299"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
