import { get } from "lodash";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import { Api } from "../../api";
import Form from "../../components/Form";
import { FieldOnChange, SubmitHandler } from "../../components/Form/formTypes";
import ResetPasswordIcon from "../../components/svg/resetPassword";
import {
  chain,
  checkEmail,
  checkLong,
  checkNonEmpty,
} from "../../utils/validateHelpers";
import { AlertHandler } from "./types";

export type AccountResetPasswordForm = {
  email: string;
  current_password: string;
  new_password: string;
  password_confirmation: string;
};

export const emptyFormData = {
  data: {
    current_password: "",
    new_password: "",
    password_confirmation: "",
    new_password_error: "",
    password_confirmation_error: "",
  },
  nextDisabled: true,
};

const passwordPageOnChange: FieldOnChange<AccountResetPasswordForm> = (
  event,
  state
) => {
  const {
    current_password_value,
    new_password_value,
    password_confirmation_value,
    new_password_error,
    password_confirmation_error,
  } = state;
  const newState = {
    nextDisabled: !(
      current_password_value !== "" &&
      new_password_value !== "" &&
      password_confirmation_value !== "" &&
      new_password_error === null &&
      password_confirmation_error === null &&
      new_password_value === password_confirmation_value
    ),
  };
  return { newState };
};

export const ResetPasswordTable = ({
  api,
  onAlert,
  email,
}: {
  // changePassword: SubmitHandler<AccountResetPasswordForm>;
  onAlert: AlertHandler;
  api: Api;
  email?: string;
}) => {
  const onSubmit: SubmitHandler<AccountResetPasswordForm> = useCallback(
    async (formData, event, additionalData) => {
      const clear = additionalData && additionalData.direction === "prev";
      if (clear) {
        return Promise.resolve({
          response: {},
          newState: emptyFormData,
        });
      }

      const { current_password, new_password, password_confirmation } =
        formData;

      //call the step's onSubmit
      if (new_password === password_confirmation) {
        return api
          .changePassword(current_password, new_password)
          .then(
            //return function that provides a handler to be executed after the form has finished processing results.
            (response) => {
              return {
                response,
                onSuccess: onAlert(
                  "Your password has been changed",
                  "",
                  <ResetPasswordIcon
                    width="50px"
                    height="50px"
                    className="svg-black-to-pink"
                  />
                ),
                newState: emptyFormData,
              };
            }
          )
          .catch((err) => {
            //console.log(err.response)
            if (get(err, "response.status", 0) === 403) {
              throw Error("Invalid email address or password");
            }
            throw err;
          });
      }
    },
    [api, onAlert]
  );
  return (
    <div key="account_reset_password" className="Account-data-wrapper">
      <div className="Account-reset-password">
        <h1 className="Account-header">Reset Password</h1>
        <Form<AccountResetPasswordForm>
          onSubmit={onSubmit}
          key="account_reset_password_form"
          id="account_reset_password_form"
          fields={[
            [
              {
                name: "email",
                type: "email",
                label: "Email address",
                disabled: true,
                validate: chain([checkNonEmpty(), checkEmail()]),
              },
            ],
            [
              {
                name: "current_password",
                type: "password",
                props: { autoComplete: "off" },
                label: (
                  <span className="relative w-100">
                    <span>Current Password</span>
                    <Link
                      to="/forgot_password"
                      className="pink absolute"
                      style={{ right: 0 }}
                    >
                      <span className="Account-reset-password-forgot-text">
                        Forgot your password?
                      </span>
                      <span className="Account-reset-password-forgot-text-mobile">
                        Forgot?
                      </span>
                    </Link>
                  </span>
                ),
                validate: checkNonEmpty(),
                onChange: passwordPageOnChange,
              },
            ],
            [
              {
                name: "new_password",
                type: "password",
                label: "Password",
                props: { autoComplete: "new-password" },
                crossValidate: {
                  name: "password_confirmation",
                  error: "Passwords must match",
                  forward: true,
                },
                validate: checkLong(8)(),
                onChange: passwordPageOnChange,
              },
            ],
            [
              {
                name: "password_confirmation",
                type: "password",
                label: "Password confirmation",
                props: { autoComplete: "new-password" },
                crossValidate: {
                  name: "new_password",
                  error: "Passwords must match",
                },
                validate: checkLong(8)(),
                onChange: passwordPageOnChange,
              },
            ],
            [{ html: <div style={{ height: "4rem" }} /> }],
          ]}
          submitProps={{
            leftArrow: false,
            rightArrow: false,
            nextCopy: " Save ",
            prevCopy: "Cancel",
          }}
          data={{ ...emptyFormData.data, email: email || "" }}
        />
      </div>
    </div>
  );
};
