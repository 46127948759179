import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import Button from "../components/Button";
import { goBack } from "../utils/history";

const NotFoundPage = ({ history }) => {
  useEffect(() => {
    Sentry.captureMessage("Page Not Found");
  });

  return (
    <div>
      <div className="bg-black w-100 vh-50" />
      <div className="h5 flex flex-column justify-around w-25-l center mv5 tc">
        <h1>Page Not Found</h1>
        <p></p>
        <Button
          className="bg-black w-50 center"
          onClick={() => goBack(history)}
          text="Go back"
        />
      </div>
    </div>
  );
};

export default withRouter(NotFoundPage);
