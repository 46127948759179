export const UTM_PARAMS_STORAGE_KEY = "utm_params";
export const HAS_ONE_BIKE_SIZE = true;
export const FREE_DELIVERY_MODE = true;
export const PROMO_CODE_STORAGE_KEY = "promo_code";
export const EXTERNAL_SUPPORT_URL = "https://support.buzzbike.cc/support/home";
export const APP_STORE_LINK =
  "https://apps.apple.com/us/app/buzzbike-cycle-club/id1232221686";

export const DATE_FORMAT = {
  SHORT: "DD/MM/YYYY",
  SERVER: "YYYY-MM-DD",
};

export const WEBSITE_URL = "https://www.buzzbike.cc";
export const PRIVACY_PAGE_URL = WEBSITE_URL + "/privacy";

export const COLLECTION_LEAD_TIME_WEEKDAYS = 10;

export const LOWEST_SUBSCRIPTION_PRICE: number | undefined = undefined;

export const DISABLED_DAYS_DELIVERY = [
  "2020-12-25",
  "2020-12-28",
  "2021-1-1",
  "2020-12-29",
  "2020-12-30",
  "2020-12-31",
  "2021-1-4",
  "2021-4-1",
  "2021-4-2",
  "2021-4-21",
  "2021-5-3",
  "2021-5-31",
  "2021-6-1",
  "2021-9-14",
  "2021-9-20",
  "2021-10-13",
];

export const DISABLED_COLLECTION_DAYS_OF_WEEKS = [2, 3];

export const CANCELLATION_DISCOUNT_AMOUNT = "20%";

export const ESTIMATED_MONTHLY_COST_AFTER_CANCELLATION_DISCOUNT = "£24";

export const ESTIMATED_DAILY_COST_AFTER_CANCELLATION_DISCOUNT = "80p";

export const ENABLED_DAYS_DELIVERY = ["2021-05-30"];

export const INVALID_POSTCODE_MESSAGE =
  "We’re sorry to say that our delivery zones don’t cover your address at the moment, so we won’t be able to deliver to you at this time!";

export const CYCLE_SCHEME_MODE: boolean =
  process.env.REACT_APP_CYCLE_SCHEME_MODE == "true" ? true : false;
