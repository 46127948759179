import { Moment } from "moment";

import { Maybe } from "../ts-types";

export type ApiResponseResult<TResponse> = {
  status: number;
  body: TResponse;
};

export type ApiResponse<TResponse> = Promise<ApiResponseResult<TResponse>>;

// Gets the underlying type of an API call
type ApiFunction<T = unknown> = (...args: never) => ApiResponse<T>;

export type ApiResultType<TApiFunction extends ApiFunction> =
  TApiFunction extends ApiFunction<infer TResult> ? TResult : never;

type GenderString = "male" | "female" | "other/non-binary";

// This type is generic in the database
export type ApiRawRegistrationData = {
  address_line_one?: unknown;
  address_line_two?: unknown;
  city?: unknown;
  postcode?: unknown;
  first_name?: string;
  last_name?: unknown;
  birth_date?: string;
  company_name?: Maybe<string>;
  nickname?: string;
  phone_number_mobile?: string;
};

export type SelectedKitItemType = {
  kit_inventory_id: number;
  kit_size_id?: number;
};

export type RegistrationData = ApiRawRegistrationData & {
  delivery_address_line_one?: unknown;
  delivery_address_line_two?: unknown;
  delivery_city?: unknown;
  delivery_postcode?: unknown;
  delivery_last_name?: unknown;
  delivery_first_name?: unknown;
  delivery_date?: Moment | string;
  selected_items?: SelectedKitItemType[];
  promotion_code?: string;

  gender?: GenderString;
  height_cm?: string;
  confident?: string;
};

type ApiOrderSummaryBeforeSaving = {
  plan: {
    amount: string;
    description: string;
  };
  delivery_charge: string;
  delivery_discounted_from?: string;
};

export type KitCategory = "Bike Size" | "Helmet" | "Lights";

export enum KitCategoryOptions {
  "Bike Size" = "bike_size_id",
  "Helmet" = "helmet_id",
  "Lights" = "lights_id",
}

export type ApiKitSpec = {
  [k in KitCategory]: ApiKitItemType;
};

type ApiOrderSummarySavedOnly = {
  kit_spec: ApiKitSpec;
  kit_total_to_pay?: string;
  discount?: {
    amount: string;
    description: string;
  };
  address_line_one: string;
  address_line_two: Maybe<string>;
  city: string;
  postcode: string;
  first_name: string;
  last_name: string;
  delivery_date: string;
  rider_id: number;
  kit_summary: string;
  selected_delivery_lead_time_in_weekdays: number;
  created: string;
  updated: string;
  id: number;
  handledSegmentOrderCompletedEvent?: Maybe<boolean>;
};

export type ApiOrderSummarySaved = ApiOrderSummaryBeforeSaving &
  ApiOrderSummarySavedOnly;

export type ApiOrderSummary =
  | ApiOrderSummarySaved
  | ApiOrderSummaryBeforeSaving;

export function assertOrderSummaryLoaded(
  summary: ApiOrderSummary
): asserts summary is ApiOrderSummarySaved {
  if (!("id" in summary)) {
    throw new TypeError("Summary data not loaded");
  }
}

export type OptionalRiderInfo = {
  gender?: GenderString;
  height_cm?: string;
  confident?: string;
};

type CancellationInvoiceSummary = {
  invoice_number: string;
  contract_end: number;
  last_billing_date: number;
  pro_rated_amount_to_pay: string;
  collection_charge: string;
  final_invoice_total: string;
  existing_collection_charge: number;
  referral_cashback_amount: string;
  pay_now: boolean;
};

export type ApiCancellationSummary = CancellationInvoiceSummary;

type Company = {
  id: string;
  name: string;
  url: string;
  badges: null;
  last_journey: null;
  last_badge: null;
  distance_cycled: null;
  segment_id: number;
  segment: {
    asset_folder: string;
    id: string;
    name: string;
    payment_method_required: "True" | "False";
    subscription_required: "False" | "True";
  };
};

type Place = {
  id: number;
  address_line_one: string;
  address_line_two: string;
  city: string;
  name: string;
  postcode: string;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
};

export type ApiLoadedRider = {
  app_debug_mode: boolean;
  bike: null;
  birth_date: string;
  company: Company;
  company_name: Maybe<string>;
  confident: Maybe<string>;
  contract_start_date: string;
  email2: Maybe<string>;
  email: string;
  full_name: string;
  gender: Maybe<string>;
  has_accepted_contract: boolean;
  height_cm: Maybe<string>;
  home_address: string;
  home_address_line1: string;
  home_postcode: string;
  homeplaces: Place[];
  id: string;
  payment_method_created: boolean;
  phone_number_mobile: string;
  promotion_code: Maybe<string>;
  rides_remaining: number;
  rides_required: number;
  workplaces: Place[];
};
export type ApiRider = EmptyDict | ApiLoadedRider;

export function assertRiderLoaded(
  rider: ApiRider
): asserts rider is ApiLoadedRider {
  if (!("id" in rider)) {
    throw new TypeError("Rider data not loaded");
  }
}

export type ApiUser = {
  first_name: string;
  last_name: string;
  email: string;
  display_name?: string;
  referrer_code?: string;
  id: string;
  hdyhau?: string;
  is_admin: boolean;
  test_mode: boolean;
};

export type ApiGetUserData = {
  access_token: undefined;
  user: ApiUser;
  rider: ApiRider;
  signup_state:
    | "RegistrationSteps"
    | "Complete"
    | "KitOrder"
    | "PaymentRequired"
    | "Terms";
};

export type ApiGetSubscription = {
  subscription?: {
    name?: string;
    amount?: string;
    interval?: string;
    start_date: string;
    end_date?: string;
    interval_count?: number;
  };
  cancellation?: {
    contract_end_date: string;
    collection_date: string;
    address_line_one: string;
    address_line_two: string;
    city: string;
    postcode: string;
    status: "Cancellation Requested";
  };
};

// comes directly from Stripe
export type ApiCreateStripeSubscription = {
  id: string;
  subscription: string;
};

type PaymentInfo = {
  cardholder_name: string;
  last4: number;
  exp_month: number;
  exp_year: number;
};

export type ApiUpdatePaymentInfo = PaymentInfo;

export type ApiRedeemResetPasswordToken = {
  is_redeemed: boolean;
  next_path: string;
};

// these API methods return an empty dictionary. A little hard to check this in TS.
export type EmptyDict = {
  [k in keyof undefined]: never;
};

export type ApiSendResetPasswordEmail = EmptyDict;

export type ApiPostContactForm = EmptyDict;
export type ApiPostContactFormData = {
  first_name: string;
  last_name: string;
  mobile_number?: string;
  email: string;
  brand: string;
  position: string;
  message: string;
};

export type ApiPostSubscribeMail = EmptyDict;
export type ApiPostSubscribeMailData = {
  email_address: string;
};

export type ApiUpdateRiderInfo = EmptyDict;

export type ApiGetPaymentInfo = EmptyDict | PaymentInfo;

export type ApiCancelSubscription = CancellationInvoiceSummary;
export type ApiCancelSubscriptionData = {
  first_name: string;
  last_name: string;
  address_line_one: string;
  address_line_two?: string;
  city: string;
  postcode: string;
  collection_date: string;
  reason: string;
};

export type ApiChangePassword = never;

export type ApiHDYHAUValues = { description: string; id: string }[];

export type ApiScheduleDelivery = ApiOrderSummarySavedOnly;
export type ApiScheduleDeliveryData = Pick<
  ApiOrderSummarySavedOnly,
  | "first_name"
  | "last_name"
  | "address_line_one"
  | "address_line_two"
  | "city"
  | "postcode"
  | "delivery_date"
> & {
  selected_items: number[];
};

export type KitItemSizeType = {
  description: Maybe<string>;
  kit_size_id: number;
  size: string;
};

export interface ApiKitItemType {
  name: string;
  variation: string;
  price: string;
  code: string;
  delivery_lead_time_in_weekdays: number;
  id: number;
  in_stock: boolean;
  image_url: string;
  rrp_price: string | null;
  description: string | null;
  display_order: Maybe<number>;
  sizes: KitItemSizeType[];
}

export type ApiKitAvailability = {
  [k in KitCategory]: ApiKitItemType[];
} & {
  selected_delivery_lead_time_in_weekdays: number;
};

export type ApiUpdateRegistrationData = Pick<
  ApiRawRegistrationData,
  | "first_name"
  | "last_name"
  | "nickname"
  | "phone_number_mobile"
  | "company_name"
  | "birth_date"
>;
// this one is too tricky to type right now
export type ApiUpdateRegistrationDataInput = unknown;

export type ApiCreateRider = {
  user: ApiUser;
  rider: ApiLoadedRider;
};
export type ApiCreateRiderData = RegistrationData;

export type ApiSignup = { access_token: string };

type UtmParamNames =
  | "utm_source"
  | "utm_medium"
  | "utm_campaign"
  | "utm_term"
  | "utm_content";

export type UtmParamsValue = {
  [key in UtmParamNames]?: string;
};

export type ApiSignupData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  hdyhau: string;
  wants_marketing_emails: boolean;
} & UtmParamsValue;

export type SubscriptionPlan = {
  amount_to_pay_now: string;
  delivery_fee: string;
  description: string;
  equivalent_monthly_payment: string;
  id: number;
  product_name: string;
  term_length_in_months: number;
};

export type ApiStartPaymentProcess = {
  setup_intent_id: string;
  client_secret: string;
};

export type ApiLogin = Omit<ApiGetUserData, "access_token"> & {
  access_token: string;
};

export type DeliveryRestriction = {
  restricted_dates: string[];
};
