import React from "react";

import CopyableText from "../../components/CopyableText/CopyableText";
import RefererSocialShare from "../../components/ReferrerSocialShare/ReferrerSocialShare";

export const AccountReferralTable = ({
  referrerCode,
}: {
  referrerCode?: string;
}) => (
  <div key="account_profile_referral" className="Account-data-wrapper">
    <div className="Account-referral">
      <h1 className="Account-referral-header">REFER FRIENDS, RIDE FOR FREE</h1>
      <div>
        <p className="mb4">
          Refer 3 friends to Buzzbike and if they <br />
          sign up before 31 Oct,
          <span className="pink"> you get £100!</span>
          <br />
          <br />
          Each friend you refer gets £10 credit plus a special <br />
          joining rate of <span className="pink">£19.99/month</span> for their
          first 4 months.
        </p>
        <div className="Account-referral-code-wrapper">
          <span>Personal Referral Code:</span>
          <CopyableText
            className="Account-referral-code clickable"
            text={referrerCode || ""}
          />
        </div>
        <div className="flex">
          <span className="flex items-center">Share with friends:</span>
          <RefererSocialShare
            code={referrerCode || ""}
            className={"Account-referral-share"}
          />
        </div>
      </div>
    </div>
  </div>
);
