import "./PreFooter.scss";

import React, { Component } from "react";
import { Link } from "react-router-dom";

import Button from "../Button";

interface PreFooterProps {
  className?: string;
  headingText: string;
  linkTo?: string;
  togglePopUp?: (value: string) => void;
  buttonText: string;
}

interface State {
  inputValue: string;
}

class PreFooter extends Component<PreFooterProps, State> {
  constructor(props: PreFooterProps) {
    super(props);
    this.state = {
      inputValue: "",
    };
  }

  emailInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  onSignupClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { togglePopUp } = this.props;
    e.preventDefault();
    togglePopUp && togglePopUp(this.state.inputValue);
  };

  render() {
    return (
      <div
        className={
          this.props.className
            ? this.props.className + " PreFooter"
            : " PreFooter"
        }
      >
        <div className="prefooter__content">
          <div className="mw85 w-90 center flex  flex-column justify-between items-center">
            <h2 className="ttu f-headline headerText">
              {this.props.headingText}
            </h2>
            {this.props.linkTo ? (
              <div className="w-100 w-auto-ns flex flex-column flex-row-ns">
                <input
                  placeholder="your@email.com"
                  value={this.state.inputValue}
                  type="text"
                  onChange={this.emailInput}
                  className="w-100 w-auto-ns dn db-ns"
                />
                <Link to="/signup" className="PreFooter-link">
                  {this.props.buttonText}
                </Link>
              </div>
            ) : (
              <div className="w-100 w-auto-ns flex flex-column flex-row-ns">
                <input
                  placeholder="your@email.com"
                  value={this.state.inputValue}
                  type="text"
                  onChange={this.emailInput}
                  className="w-100 w-auto-ns dn db-ns"
                />
                <Button
                  onClick={this.onSignupClick}
                  className="bg-black"
                  text={this.props.buttonText}
                />
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    );
  }
}

export default PreFooter;
