import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { getDeepValue } from "utils";

import { Api } from "../../api";
import { KitCategory, SubscriptionPlan } from "../../api/types";
import { CYCLE_SCHEME_MODE, FREE_DELIVERY_MODE } from "../../constants";
import QuestionMarkImage from "../../static/images/icons/icon-questionmark.png";

type Props = {
  api: Api;
  selectedSubscriptionPlan: SubscriptionPlan;
  promotionCode?: string;
  onSetDiscountAmount: (discountAmount: number) => void;
  onTotalChange: (amount: number) => void;
};
type Cost = {
  label: string;
  price: string;
  originalPrice?: string;
  duration?: string;
  size?: string;
};
type State = {
  costs: null | Cost[];
  discount?: null | string;
  total: null | string;
  error?: Error;
  isMonthlyPlan: boolean;
};

const formatAmount = (amount: string) => {
  const wholeString = Number(amount).toFixed(0);
  const wholeNumber = Number.parseInt(wholeString);
  if (wholeNumber === Number.parseInt(amount)) {
    return wholeString;
  }
  return amount;
};

const OrderSummary = ({
  api,
  selectedSubscriptionPlan,
  promotionCode,
  onSetDiscountAmount,
  onTotalChange,
}: Props) => {
  const [{ costs, discount, total, error, isMonthlyPlan }, setState] =
    useState<State>({
      costs: null,
      discount: null,
      total: null,
      isMonthlyPlan: true,
    });

  const defaultNote =
    "* Your first monthly subscription will be charged one working day before delivery and billed each month on this day until cancelled.";
  const [bottomNote, setBottomNote] = useState(defaultNote);

  useEffect(() => {
    const subscriptionTermLength =
      getDeepValue<number>(selectedSubscriptionPlan, "term_length_in_months") ||
      0;
    const isMonthlyPlan = subscriptionTermLength <= 1;

    const loadOrderSummary = async () => {
      api
        .getOrderSummary(
          selectedSubscriptionPlan ? selectedSubscriptionPlan.id : undefined
        )
        .then((response) => {
          const { body: data } = response;
          if (!data) {
            throw Error("Unable to get order summary");
          }

          const discountAmount = Number.parseFloat(
            getDeepValue<string>(data, "discount.amount") || "0"
          );

          onSetDiscountAmount(discountAmount);

          if (!isMonthlyPlan) {
            setBottomNote(
              `* Your ${subscriptionTermLength} months subscription will be charged in full today. At the end of this period you will be switched to a monthly plan, which will be charged on this day each month until cancelled.`
            );
          }

          const costs: Cost[] = [];

          if (!isMonthlyPlan) {
            costs.push({
              label: `Subscription (${subscriptionTermLength} Months)`,
              price: `£${data.plan.amount}`,
            });
          }

          if ("kit_spec" in data) {
            Object.keys(data.kit_spec).forEach((k) => {
              const item = data.kit_spec[k as KitCategory];
              if (k !== "Bike Size") {
                const cost: Cost = {
                  label: `${item.name}${item.variation}`,
                  price:
                    Number.parseFloat(item.price) > 0 ? `£${item.price}` : "",
                };
                if (!isEmpty(item.sizes)) {
                  const selectedSize = item.sizes[0];
                  cost.size = selectedSize.size;
                }
                costs.push(cost);
              }
            });
          }
          const delivery_charge = Number.parseFloat(
            data.delivery_charge || "0"
          );

          if (FREE_DELIVERY_MODE && data.delivery_discounted_from) {
            const originalPrice = Number.parseFloat(
              data.delivery_discounted_from || "0"
            );
            costs.push({
              label: "Delivery",
              price:
                delivery_charge === 0 ? `Free` : `£${data.delivery_charge}`,
              originalPrice:
                originalPrice !== delivery_charge
                  ? `£${data.delivery_discounted_from}`
                  : undefined,
            });
          } else {
            costs.push({
              label: "Delivery",
              price: `£${data.delivery_charge}`,
            });
          }

          let total =
            Number.parseFloat(
              "kit_total_to_pay" in data ? data.kit_total_to_pay || "0" : "0"
            ) + delivery_charge;

          if (!isMonthlyPlan && selectedSubscriptionPlan) {
            total += parseFloat(selectedSubscriptionPlan.amount_to_pay_now);
          }

          if (!isMonthlyPlan) {
            total -= Number.parseFloat(
              getDeepValue<string>(data, "discount.amount") || "0"
            );
          }
          total = Math.max(0, total);

          const discount =
            "discount" in data && data.discount
              ? `-£${formatAmount(data.discount.amount)} / ${
                  data.discount.description
                }`
              : undefined;

          onTotalChange(total * 100);
          const orderSummary = {
            costs,
            discount,
            total: `£${total.toFixed(2)}`,
            isMonthlyPlan,
          };
          setState(orderSummary);
        })
        .catch((error) => {
          setState((prevState) => ({ ...prevState, error }));
        });
    };
    loadOrderSummary();
  }, [
    api,
    selectedSubscriptionPlan,
    promotionCode,
    onSetDiscountAmount,
    onTotalChange,
  ]);

  if (costs) {
    return (
      <>
        <div className="OrderSummary">
          <div className="OrderSummary-cost-wrapper">
            {costs.map((cost, i) => (
              <div
                key={`order_summary_cost_${i}`}
                className="OrderSummary-cost"
                style={
                  !(discount && !isMonthlyPlan) && i === costs.length - 1
                    ? { border: "none" }
                    : {}
                }
              >
                <div className={"OrderSummary-cost-label-wrapper"}>
                  <span className="OrderSummary-cost-label">{cost.label}</span>
                  {cost.size && (
                    <span className="OrderSummary-cost-size">
                      Size: {cost.size}
                    </span>
                  )}
                </div>
                <div className="OrderSummary-cost-value">
                  {FREE_DELIVERY_MODE && (
                    <span className="OrderSummary-cost-originalPrice">
                      {cost.originalPrice}
                    </span>
                  )}
                  <span className="OrderSummary-cost-price">{cost.price}</span>
                  <span className="OrderSummary-cost-per">
                    {cost.duration && "/"}
                  </span>

                  <span className="OrderSummary-cost-duration">
                    {cost.duration}
                  </span>
                </div>
              </div>
            ))}
            {discount && !isMonthlyPlan && (
              <div className="OrderSummary-discount">
                <span className="OrderSummary-cost-label pink">Discount</span>
                <div className="OrderSummary-cost-price-discount">
                  <span className="OrderSummary-cost-price tr pink">
                    {discount}
                  </span>
                  <img
                    className="Form-label-tooltip OrderSummary-tooltip "
                    src={QuestionMarkImage}
                    data-for="Discount-tooltip"
                    alt="tooltip"
                    data-tip={
                      "Discount will be applied to your first subscription payment."
                    }
                    data-multiline={true}
                  />
                  <ReactTooltip id="Discount-tooltip" />
                </div>
              </div>
            )}
          </div>
          <div className="OrderSummary-total">
            <span className="b OrderSummary-cost-label">
              Total First Payment
            </span>
            <span className="pink OrderSummary-cost-price">{total}</span>
          </div>
        </div>
        {!CYCLE_SCHEME_MODE && (
          <span className={"OrderSummary-bottomNote"}>{bottomNote}</span>
        )}
      </>
    );
  } else if (error) {
    return (
      <div style={{ marginTop: "1rem" }}>
        <div className="Form-page-error">
          {error.message || "An error has occurred"}
        </div>
      </div>
    );
  }
  return <h1 className="b1 b--silver br3">Loading...</h1>;
};

export default OrderSummary;
