import React, { ReactNode, useEffect } from "react";

import { retrieveAccessToken, retrieveName, retrieveUserId } from "../api";

type AuthDetailsState =
  | {
      loading: true;
      refresh: () => void;
    }
  | {
      loading: false;
      refresh: () => void;
      accessToken: string | null;
      name: string | null;
      userId: string | null;
    };

export const AuthDetails = React.createContext<AuthDetailsState>({
  loading: true,
  refresh: () => {},
});

export const useAuthDetails = () => {
  return React.useContext(AuthDetails);
};

/**
 * This synchronises the current user Id from Segment with Optimizely.
 *
 * Segment handles this automatically, but does not consider anonymous users.
 * Our function covers this case.
 *
 */
export const synchroniseOptimizelyId = async () => {
  return new Promise((resolve) => {
    window.analytics.ready(function () {
      if (typeof window.optimizelyClientInstance !== "undefined") {
        const segmentId = window.analytics.user().id();
        if (segmentId !== null) {
          window.optimizelyClientInstance.setUser({
            id: segmentId,
          });
          resolve(segmentId);
        } else {
          const anonymousId = window.analytics.user().anonymousId();
          window.optimizelyClientInstance.setUser({
            id: anonymousId,
          });
          resolve(anonymousId);
        }
      }
    });
  });
};

export const AuthWrapper = (props: {
  children: (authDetails: AuthDetailsState) => ReactNode;
}) => {
  const refresh = React.useCallback(() => {
    const accessToken = retrieveAccessToken();
    const details = {
      accessToken,
      name: retrieveName(),
      userId: retrieveUserId(accessToken),
    };
    setAuthDetailsState({
      loading: false,
      refresh,
      ...details,
    });
    synchroniseOptimizelyId();
  }, []);

  const [authDetailsState, setAuthDetailsState] =
    React.useState<AuthDetailsState>({
      loading: true,
      refresh,
    });

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <AuthDetails.Provider value={authDetailsState}>
      {props.children(authDetailsState)}
    </AuthDetails.Provider>
  );
};
