import * as Sentry from "@sentry/react";
import axios, { AxiosResponse } from "axios";

const apiInstance = axios.create();

function trackResponse(response: AxiosResponse) {
  try {
    const {
      config: { url, method },
      data,
      status,
    } = response;
    if (process.env.NODE_ENV === "development") {
      console.debug("API response", { status, url, method, data });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

apiInstance.interceptors.response.use((response) => {
  trackResponse(response);
  return response;
});

export default apiInstance;
