import React, { useCallback } from "react";

import { ApiKitItemType, KitCategory, SelectedKitItemType } from "api/types";

import KitItem from "./components/KitItem";
import styles from "./KitShop.module.scss";

export interface KitShopProps {
  data: KitItemType[];
  selectedItems?: SelectedKitItemType[];
  onSelectItem: (id: number, sizeId?: number) => void;
}

export interface KitItemType extends ApiKitItemType {
  type: KitCategory;
}

function KitShop(props: KitShopProps) {
  const { data, onSelectItem: onSelectItemProp, selectedItems = [] } = props;

  const onSelectItem = useCallback(
    (index: number, sizeId?: number) => {
      const { id } = data[index];
      onSelectItemProp(id, sizeId);
    },
    [onSelectItemProp, data]
  );

  return (
    <div className={styles.container}>
      {data.map((item: KitItemType, index) => {
        const mappedSelectedItem = selectedItems.find(
          (selectedItem) => selectedItem.kit_inventory_id == item.id
        );

        return (
          <KitItem
            key={`kit-item-$${index}`}
            index={index}
            data={item}
            onSelect={onSelectItem}
            selected={!!mappedSelectedItem}
            selectedSizeId={
              mappedSelectedItem ? mappedSelectedItem.kit_size_id : undefined
            }
          />
        );
      })}
    </div>
  );
}

export default KitShop;
