import * as Sentry from "@sentry/react";
import { isEmpty, round } from "lodash";
import converter from "number-to-words";
import { SEGMENT_EVENT, SegmentProduct } from "types";

import { ApiUser, SubscriptionPlan } from "api/types";

export function openUrl(url: string) {
  try {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function importAll<T>(r: { keys(): string[]; (id: string): T }): T[] {
  return r.keys().map(r);
}

export function feetAndInchToCm(feet: number, inch: number): number {
  return round(feet / 0.032808 + inch / 0.3937, 0);
}

export function cmToFeetAndInch(cm: number): [number, number] {
  const totalInches = cm * 0.3937;
  return [Math.floor(totalInches / 12), round(totalInches % 12, 0)];
}

export function getDeepValue<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: { [key: string]: any } | undefined,
  path: string,
  defaultValue?: T
): T | undefined {
  const result = path
    .replace(/\[/g, ".")
    .replace(/\]/g, "")
    .split(".")
    .reduce((o, k) => (o || {})[k], object);
  return (result as T | undefined) || defaultValue;
}

export function getSubscriptionTotalAmountSuffix(length = 0) {
  switch (length) {
    case 0:
    case 1:
      return "every month";

    case 12:
      return "every year";

    default:
      return `every ${converter.toWords(length)} months`;
  }
}

export function convertSubscriptionPlanToSegmentProduct(
  subscriptionPlan: SubscriptionPlan | Record<string, never>,
  index?: number
): SegmentProduct | Record<string, never> {
  if (isEmpty(subscriptionPlan)) {
    return {};
  }

  return {
    product_id: subscriptionPlan.id.toString(),
    position: index,
    name: subscriptionPlan.product_name,
    price: Number.parseFloat(subscriptionPlan.amount_to_pay_now),
  };
}

export const saveLoginIdentity = (user: {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
}) => {
  try {
    const { first_name: firstName, last_name: lastName, email, id } = user;
    window.analytics.identify(id, {
      firstName,
      lastName,
      email,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const trackLoginSuccess = (user: ApiUser) => {
  try {
    window.analytics.track(SEGMENT_EVENT.LOGIN, {
      ...user,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
};
