import "react-day-picker/lib/style.css";

import { isEmpty } from "lodash";
import moment from "moment";
import React, { InputHTMLAttributes, useCallback, useMemo } from "react";
import { DayPickerInputProps, Modifier } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils from "react-day-picker/moment";

import { DATE_FORMAT } from "../../constants";
import styles from "./DateInput.module.scss";

type DateInputPropsConfig = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "value" | "min" | "max" | "disabled" | "name" | "type"
> & {
  value: string | undefined;
  onChange: (dateString?: string) => void;
  min: string;
  max: string;
  disabled?: boolean | string;
  disableWeekend?: boolean;
  initialMonth?: string;
  name: string;
  type: "date";
  disabledDays?: string[];
  enabledDays?: string[];
  disabledDaysOfWeek?: number[];
};

export type DateInputProps = DayPickerInputProps & {
  config: DateInputPropsConfig;
};

function DateInput(props: DateInputProps) {
  const {
    config: {
      value,
      onChange,
      min,
      max,
      disabled = false,
      disableWeekend = false,
      initialMonth: initialMonthProp,
      disabledDays: disabledDaysProp = [],
      disabledDaysOfWeek: disabledDaysOfWeekProps,
      enabledDays = [],
      ...rest
    },
  } = props;

  const onDayChange = useCallback(
    (selectedDay: Date) => {
      if (!selectedDay) {
        return onChange(undefined);
      }
      const formattedDate = moment(selectedDay).format(DATE_FORMAT.SERVER);
      onChange(formattedDate);
    },
    [onChange]
  );

  const dayValue = !isEmpty(value)
    ? moment(value, DATE_FORMAT.SERVER).toDate()
    : undefined;

  const disabledDays = useMemo(() => {
    if (isEmpty(min) && isEmpty(max)) {
      return undefined;
    }
    const result: Modifier[] = disabledDaysProp.map((day) => {
      return new Date(day);
    });

    if (!isEmpty(min)) {
      result.push({
        before: new Date(min),
      });
    }
    if (!isEmpty(max)) {
      result.push({
        after: new Date(max),
      });
    }

    function isEnabledDay(day: Date) {
      return (
        (day.getDay() === 0 || day.getDay() === 6) &&
        !enabledDays.includes(moment(day).format(DATE_FORMAT.SERVER))
      );
    }

    if (disableWeekend) {
      if (isEmpty(enabledDays)) {
        result.push({ daysOfWeek: [0, 6] });
      } else {
        result.push(isEnabledDay);
      }
    }
    if (disabledDaysOfWeekProps) {
      result.push({ daysOfWeek: disabledDaysOfWeekProps });
    }
    return result;
  }, [
    min,
    max,
    disabledDaysProp,
    disableWeekend,
    disabledDaysOfWeekProps,
    enabledDays,
  ]);

  let initialMonth = !isEmpty(min) ? new Date(min) : undefined;
  if (initialMonthProp) {
    initialMonth = new Date(initialMonthProp);
  }

  if (disabled) {
    return (
      <div className={`${styles.container} ${styles.disabledContainer}`} />
    );
  }

  const modifiersStyles = {
    today: {
      backgroundColor: "#ff4074",
      color: "white",
    },
  };

  return (
    <DayPickerInput
      value={dayValue}
      inputProps={{
        className: `${styles.container}`,
        ...rest,
        type: "text",
      }}
      formatDate={MomentLocaleUtils.formatDate}
      parseDate={MomentLocaleUtils.parseDate}
      format={DATE_FORMAT.SHORT}
      onDayChange={onDayChange}
      dayPickerProps={{ disabledDays, initialMonth, modifiersStyles }}
    />
  );
}

export default DateInput;
