import isEmail from "isemail";
import { isEmpty } from "lodash";

const validPostalAreas = "BR CR E EC HA IG N NW SE SM SW UB W WC";
const validPostalDistricts =
  "DA1 DA5 DA6 DA7 DA8 DA14 DA15 DA16 DA17 DA18 EN1 EN2 EN3 EN4 EN5 KT1 KT2 KT3 KT4 KT5 KT6 KT7 KT8 KT9 KT10 KT11 " +
  "KT12 KT13 KT17 KT18 KT19 KT20 KT21 RM1 RM2 RM3 RM4 RM5 RM6 RM7 RM8 RM9 RM10 RM11 RM12 RM13 RM19 TW1 TW3 TW3 TW4 " +
  "TW5 TW6 TW7 TW8 TW9 TW10 TW11 TW12 TW13 TW14 TW15 TW16 TW17 TW18 WD2 WD3 WD5 WD6 WD7 WD17 WD18 WD19 WD23 WD24 WD25";

type CheckFunction = (v: string) => null | string;

export const chain = (checks: CheckFunction[]) => (v: string) =>
  checks.reduce((acc: null | string, check) => (acc ? acc : check(v)), null);

export const checkLong =
  (n: number) =>
  (msg = `At least ${n} characters needed`) =>
  (v: string) =>
    v.length >= n ? null : msg;

export const checkNonEmpty =
  (msg = "Field cannot be empty") =>
  (v?: string | number) => {
    return v && typeof v === "string"
      ? v.match(/[^\s]/)
        ? null
        : msg
      : v
      ? null
      : msg;
  };

export const checkEmail =
  (msg = "Please insert a valid email address") =>
  (v: string) =>
    isEmail.validate(v) ? null : msg;

export const checkTicked = (msg: string) => (v: boolean | string | number) => {
  const casted = typeof v === "boolean" ? v : v === "true" || v === 1;
  return casted ? null : msg;
};

export const checkPostalCode = (msg: string) => (v: string) => {
  if (isEmpty(v)) {
    return msg;
  }
  const postalArea = v.match(/^[a-zA-Z][a-zA-Z]?/i);
  const postalDistrict = v.match(/^[a-zA-Z]+\d\d?/i);

  if (isEmpty(postalArea) && isEmpty(postalDistrict)) {
    return msg;
  }

  const extractedPostalArea = postalArea ? postalArea[0] : "";
  const extractedPostalDistrict = postalDistrict ? postalDistrict[0] : "";

  if (
    !validPostalAreas.split(" ").includes(extractedPostalArea) &&
    !validPostalDistricts.split(" ").includes(extractedPostalDistrict)
  ) {
    return msg;
  }

  return null;
};
