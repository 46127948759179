import "./index.scss";

import React, { Component } from "react";
import { withAlert } from "react-alert";

import { postSubscribeMail } from "../../api";
import Arrow from "../../static/images/icons/icon-arrow-dark.png";
import Facebook from "../../static/images/icons/icon-facebook.png";
import Instagram from "../../static/images/icons/icon-instagram.png";
import Twitter from "../../static/images/icons/icon-twitter.png";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsmail: "",
    };
    this.sendMail = this.sendMail.bind(this);
    this.onNewsletterEmailInput = this.onNewsletterEmailInput.bind(this);
  }
  onNewsletterEmailInput(e) {
    this.setState({ newsmail: e.target.value });
  }
  sendMail() {
    postSubscribeMail({ email_address: this.state.newsmail })
      .then((response) => {
        if (response.status === 201 && response.statusText === "CREATED") {
          this.setState({ newsmail: "" });
          this.props.alert.success(
            <div style={{ color: "white" }}>Thanks for subscribing.</div>
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  render() {
    return (
      <footer>
        <div className="mw85 w-90 center pv5">
          <div className="flex flex-wrap">
            <div className="w-100 w-50-m w-25-l">
              <h4 className="f2 ttu tc tl-m tl-l mb3">USEFUL INFO</h4>
              <div className="flex flex-row flex-column-m flex-column-l mb4 useful-links justify-center tl">
                <a className="lh-copy ph3 mb2 ph0-ns" href="/privacy-policy">
                  Privacy Policy
                </a>
                <a
                  className="lh-copy ph3 mb2 ph0-ns"
                  href="/documents/terms.pdf"
                >
                  Terms of Use
                </a>
                <a
                  className="lh-copy ph3 mb2 ph0-ns"
                  href="https://support.buzzbike.cc/support/home"
                >
                  FAQs
                </a>
                <a
                  className="lh-copy ph3 mb2 ph0-ns"
                  href="https://apply.workable.com/buzzbike/?utm_source=buzzbike-www&utm_campaign=footer-link&utm_medium=site-referral"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </div>
            </div>
            <div className="w-100 w-50-m w-25-l mb4">
              <h4 className="f2 ttu tc tl-m tl-l mb3">Contact Us</h4>
              <p className="tc tl-m tl-l lh-copy">
                <a href="mailto:hello@buzzbike.cc">hello@buzzbike.cc</a>
              </p>
              <br />
              <p
                className="tc tl-m tl-l lh-copy clickable"
                onClick={() => window.open("tel:02038599356")}
              >
                0203 859 9356
              </p>
              <br />
              <p className="tc tl-m tl-l lh-copy db">
                Buzzbike <br />
                Unit V116, Vox Studios <br />
                1-45 Durham Street <br />
                Vauxhall, SE11 5JH
              </p>
            </div>
            <div className="w-100 w-50-m w-20-l mb4">
              <h4 className="f2 ttu tc tl-m tl-l mb3">Follow Us</h4>
              <div className="flex w-100-ns flex-row-ns justify-center justify-none">
                <a
                  className="social"
                  href="https://www.facebook.com/buzzbike.cc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mr2 " src={Facebook} />
                </a>
                <a
                  className="social"
                  href="https://www.instagram.com/buzzbike/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="mr2 " src={Instagram} />
                </a>
                <a
                  className="social"
                  href="https://twitter.com/buzzbike"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="" src={Twitter} />
                </a>
              </div>
            </div>
            <div className="flex w-100 w-50-m w-30-l flex-column mb4">
              <h4 className="f2 ttu tc tl-m tl-l mb3">STAY CONNECTED</h4>
              <p className="tc tl-m tl-l lh-copy">
                Be the first to hear about Buzzbike availability, news, events
                and discounts:{" "}
              </p>
              <div className="newsletter flex justify-between items-center bg-white mt2">
                <input
                  placeholder="your@email.com"
                  type="text"
                  className="h2 bg-white w-80 ml1 bn"
                  onChange={this.onNewsletterEmailInput}
                  value={this.state.newsmail}
                />
                <div className="w-10 submit">
                  <button className="bn bg-white" onClick={this.sendMail}>
                    <img src={Arrow} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-footer bt bw1 pv3">
          <span className="footer-fca">
            Buzz Bikes Limited, registered company number 08495611, is
            authorised and regulated by the Financial Conduct Authority under
            registration number 779146.
          </span>
          <span className="footer-copyright">
            &copy; {new Date().getFullYear()} Buzzbike
          </span>
        </div>
      </footer>
    );
  }
}

export default withAlert()(Footer);
