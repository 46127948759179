import { Api } from "api";
import { isEmpty } from "lodash";
import React, { ChangeEvent, useCallback, useState } from "react";
import { getDeepValue } from "utils";

import Button from "components/Button";
import { PROMO_CODE_STORAGE_KEY } from "constants/index";

import { CYCLE_SCHEME_MODE } from "../../constants";
import styles from "./PromotionCodeSection.module.scss";

export interface PromotionCodeSectionProps {
  api: Api;
  onApplied: () => void;
  promotionCode?: string;
  finalAmount?: number;
  isMonthlyPlan?: boolean;
}

function PromotionCodeSection(props: PromotionCodeSectionProps) {
  const {
    api,
    onApplied,
    promotionCode,
    finalAmount = 0,
    isMonthlyPlan = false,
  } = props;
  const [code, setCode] = useState(
    localStorage.getItem(PROMO_CODE_STORAGE_KEY) || ""
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onInputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setCode(value);
      if (error) {
        setError(null);
      }
    },
    [error]
  );

  function onApplyButtonClicked() {
    setLoading(true);
    api
      .updatePromotionCode(code)
      .then(() => {
        setLoading(false);
        onApplied();
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage =
          getDeepValue<string>(error, "response.data.error") ||
          getDeepValue<string>(
            error,
            "response.data.field_errors.promotion_code[0]"
          ) ||
          "Apply promotion code failed.";
        setError(errorMessage);
      });
  }

  if (!isMonthlyPlan) {
    return null;
  }

  if (!isEmpty(promotionCode)) {
    if (CYCLE_SCHEME_MODE) {
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <span className={styles.successMessage}>
              Your Cyclescheme redemption code <strong>{promotionCode}</strong>{" "}
              has been applied. Payment for your annual subscription will be
              taken from your monthly salary.
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.container}>
          <div className={styles.content}>
            <span className={styles.successMessage}>
              Promo code <strong>{promotionCode}</strong> has been applied{" "}
              {isMonthlyPlan &&
                `reducing your subscription payment to £${finalAmount}`}
            </span>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <input
          className={styles.input}
          placeholder={"Enter promo code"}
          value={code}
          onChange={onInputChanged}
          autoComplete={"off"}
        />

        <Button
          className={styles.button}
          type="button"
          disabled={loading || isEmpty(code)}
          loading={loading}
          onClick={onApplyButtonClicked}
          text={"APPLY NOW"}
        />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default PromotionCodeSection;
