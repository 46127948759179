import React from "react";

import { SvgComponentProps } from "./types";

const ArrowRight = ({
  fill,
  stroke,
  width,
  height,
  className,
  style,
}: SvgComponentProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width || "24px"}
    height={height || "24px"}
    viewBox="0 0 24 24"
    enableBackground="new 0 0 24 24"
    style={{ verticalAlign: "middle", ...style }}
    xmlSpace="preserve"
    className={className || ""}
  >
    <g id="Bounding_Boxesjnjknknkn">
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
    <g id="Outline_1_kbnjbgf">
      <path
        fill={fill}
        stroke={stroke}
        d="M12,4l-1.41,1.41L16.17,11H4v2h12.17l-5.58,5.59L12,20l8-8L12,4z"
      />
    </g>
  </svg>
);

export default ArrowRight;
