import React from "react";

import { SvgSizeProps } from "./types";

const ArrowDown = ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "10px"}
    height={height || "10px"}
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <polygon
        id="path-jkgfhkfkjkf1"
        points="9.7535 4.6076 5.2083 9.1528 0.6632 4.6076 1.4644 3.8065 4.6402 6.9769 4.6402 0.0625 5.7765 0.0625 5.7765 6.9769 8.9523 3.8065"
      />
    </defs>
    <g
      id="Page-hgsd4"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Buzzbike_v5dfsgh" transform="translate(-446.000000, -354.000000)">
        <g id="Group-fdshhgds432" transform="translate(446.000000, 354.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-jkgfhkfkjkf1" />
          </mask>
          <g id="Clip-hsrgh431" />
          <polygon
            id="Fill-fdn430"
            fill="#282828"
            mask="url(#mask-2)"
            points="11.8194 -2.0035 11.8194 11.2188 -1.4028 11.2188 -1.4028 -2.0035"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
