import React, { Component } from "react";

import { redeemResetPasswordToken } from "../api";
import Button from "../components/Button";
import Field from "../components/Field";
import FormContext from "../components/FormContext";
import Header from "../components/Header";
import ArrowRight from "../components/svg/arrowRight";
import headerImageMob from "../static/images/img-header--community-mob-n.jpg";
import headerImage from "../static/images/img-header--signup-n.jpg";
import headerImageMobPreview from "../static/images/thumbnails/img-header--community-mob-n.jpg";
import headerImagePreview from "../static/images/thumbnails/img-header--signup-n.jpg";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password_confirmation: "",
      errors: {},
      showErrors: false,
      successfulContact: false,
      responseCode: 0,
    };
  }
  onButtonClick = (e) => {
    e.preventDefault();
    const getTokenFromQueryString = (qs) => {
      const parts = qs.substring(1).split("&");

      return parts.reduce((acc, part) => {
        const [name, value] = part.split("=");

        acc[name] = value;
        return value;
      }, {});
    };
    const is_empty = (str) => {
      if (str.length <= 0) return true;
    };
    const validate = [
      {
        name: "password",
        fn: (value) => {
          if (!value) {
            return "Password cannot be empty";
          } else if (value.length <= 7) {
            return "Your password must be more than 8 characters long";
          }
        },
      },
      {
        name: "password_confirmation",
        fn: (value) => {
          if (value !== this.state.password) {
            return "Passwords do not match";
          }
        },
      },
    ];

    const errors = {};
    validate.forEach(({ name, fn }) => {
      const value = this.state[name];
      const result = is_empty(value) ? "This field cannot be empty" : fn(value);
      if (result) {
        errors[name] = result;
      }
    });

    let showErrors = false;
    if (Object.keys(errors).length) {
      showErrors = true;
    } else {
      // added function wrong parameters
      redeemResetPasswordToken(
        this.state.password,
        getTokenFromQueryString(this.props.location.search)
      )
        .then((response) => {
          if (response.status === 200 && response.statusText === "OK") {
            this.setState({
              successfulContact: true,
              responseCode: 200,
            });
          } else if (response.status === 409) {
            this.setState({
              successfulContact: true,
              responseCode: 409,
            });
          }
        })
        .catch((err) => {
          console.log("An error occurred", err);
        });
    }
    this.setState({ errors, showErrors });
  };
  onInputChange = (v, e) => {
    const { name } = e.target;

    const obj = {};
    obj[name] = v;
    this.setState(obj);

    let { errors } = this.state;
    if (errors && errors[name]) {
      errors = { ...errors };
      delete errors[name];
    }
    this.setState({
      errors,
    });
  };
  render() {
    let responseNotify = "";
    if (this.state.responseCode === 409)
      responseNotify = "This token is already redeemed.";
    else if (this.state.responseCode === 200)
      responseNotify =
        "Thank you. Your password has been reset. Please login into the app";
    return (
      <div>
        <Header
          background={headerImage}
          backgroundPreview={headerImagePreview}
          backgroundMob={headerImageMob}
          backgroundMobPreview={headerImageMobPreview}
          className="noButtonHeader noBanner white-angle third-height"
        />
        <div
          className="w-90 mw6"
          style={{
            maxWidth: "1024px",
            margin: "0px auto",
          }}
        >
          <div className="flex flex-column">
            <FormContext.Provider
              value={{
                showErrors: this.state.showErrors,
                errors: this.state.errors,
                errorFontSize: "12px",
              }}
            >
              <div className="flex flex-row-ns flex-column items-center justify-center">
                <div className="mv5 w-60-ns w-100 form-fields pa3 flex flex-column ">
                  {this.state.successfulContact ? (
                    <div style={{ height: "100%" }}>
                      <div className="h-100 flex items-center justify-center flex-column">
                        <h1 className="db mb5">{responseNotify}</h1>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row mb2 FieldWrap">
                      <form id="buzzbike-form">
                        <h3 className="f2 mb3">Enter New Password</h3>
                        <p className="lh-copy mb3">{this.props.introText}</p>
                        <Field
                          className="mb3"
                          label="Password"
                          name="password"
                          type="password"
                          value={this.state.password}
                          onChange={this.onInputChange}
                        />
                        <Field
                          className="mb3"
                          label="Password Confirmation"
                          name="password_confirmation"
                          type="password"
                          value={this.state.password_confirmation}
                          onChange={this.onInputChange}
                        />
                        <div className="flex flex-row justify-center">
                          <Button
                            type="submit"
                            style={{ maxWidth: 500, width: "100%" }}
                            text={
                              <span style={{ color: "white", fill: "white" }}>
                                Reset <ArrowRight />
                              </span>
                            }
                            className={`mt2 bg-pink`}
                            onClick={this.onButtonClick}
                          />
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </FormContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
