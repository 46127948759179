import React from "react";

import { SvgSizeAndClassProps } from "./types";

export default ({ width, height, className }: SvgSizeAndClassProps) => (
  <svg
    width={width || "10px"}
    height={height || "9px"}
    className={className || ""}
    viewBox="0 0 12 11"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M9.7606,1.6551 L9.7602,1.6551 C11.7412,3.6361 11.7425,6.8477 9.7627,8.8302 C8.3117,10.2817 6.129,10.716 4.2329,9.9305 C2.3367,9.1453 1.1005,7.2948 1.1009,5.2424 L1.1009,5.1854 L0.6501,5.6362 C0.5984,5.687 0.529,5.7155 0.4567,5.716 C0.3462,5.7162 0.2462,5.6496 0.2038,5.5474 C0.1615,5.4453 0.185,5.3275 0.2633,5.2495 L1.1806,4.3322 C1.2318,4.2807 1.3015,4.2518 1.3742,4.2518 C1.4469,4.2518 1.5165,4.2807 1.5678,4.3322 L2.4826,5.2495 C2.534,5.3007 2.563,5.3703 2.563,5.443 C2.563,5.5158 2.534,5.5854 2.4826,5.6366 C2.4309,5.6874 2.3615,5.716 2.2892,5.7164 C2.2167,5.7164 2.1473,5.6876 2.0958,5.6366 L1.645,5.1858 L1.645,5.2428 C1.6446,7.4078 3.177,9.2698 5.3016,9.6859 C7.426,10.1022 9.5478,8.9556 10.3641,6.9506 C11.1803,4.9456 10.4626,2.643 8.6514,1.4572 C6.8403,0.2711 4.443,0.5335 2.9313,2.0831 C2.8257,2.1914 2.6524,2.1933 2.5442,2.0877 C2.4359,1.9821 2.434,1.8088 2.5396,1.7005 C3.4866,0.7289 4.7837,0.1771 6.1406,0.168300333 C7.4975,0.1599 8.8014,0.6954 9.7606,1.6551 Z"
        id="path-4th35bstorw4h1"
      />
      <path
        d="M3.1728,0.2922 C4.0624,0.2949 4.7822,1.0166 4.7826,1.9062 L4.7826,2.6826 L5.2834,2.6826 C5.434,2.683 5.5561,2.8051 5.5565,2.9557 L5.5565,5.6782 C5.5561,5.8288 5.434,5.9509 5.2834,5.9513 L1.0606,5.9513 C0.91,5.9509 0.7879,5.8288 0.7875,5.6782 L0.7875,2.9532 C0.7879,2.8026 0.91,2.6805 1.0606,2.6801 L1.5614,2.6801 L1.5614,1.9037 C1.5614,1.0137 2.2828,0.2922 3.1728,0.2922 Z M2.1097,1.9037 L2.1097,2.6801 L4.236,2.6801 L4.236,1.9037 C4.236,1.3165 3.76,0.8405 3.1728,0.8405 C2.5857,0.8405 2.1097,1.3165 2.1097,1.9037 Z M5.012,5.4026 L5.012,3.2242 L1.3345,3.2242 L1.3345,5.4026 L5.012,5.4026 Z"
        id="path-4th35bstorw4h3"
      />
    </defs>
    <g
      id="Page-graeht28"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Buzzbike_v5aerht" transform="translate(-397.000000, -257.000000)">
        <g id="Group-grwaihgo4w2" transform="translate(397.000000, 257.000000)">
          <g id="Group-grwaihgo4w3012">
            <mask id="mask-gwi4ijtnigwrbknf2" fill="white">
              <use xlinkHref="#path-4th35bstorw4h1" />
            </mask>
            <g id="Clip-gaow4jhir3011" />
            <polygon
              id="Fill-3tpqjegwmnsdbf3010"
              fill="#000000"
              mask="url(#mask-gwi4ijtnigwrbknf2)"
              points="-1.8828 -1.8976 13.3126 -1.8976 13.3126 12.3828 -1.8828 12.3828"
            />
          </g>
          <g
            id="Group-grwaihgo4w3015"
            transform="translate(3.000000, 2.000000)"
          >
            <mask id="mask-gwi4ijtnigwrbknf4" fill="white">
              <use xlinkHref="#path-4th35bstorw4h3" />
            </mask>
            <g id="Clip-gaow4jhir3014" />
            <polygon
              id="Fill-3tpqjegwmnsdbf3013"
              fill="#000000"
              mask="url(#mask-gwi4ijtnigwrbknf4)"
              points="-1.2785 -1.7738 7.6225 -1.7738 7.6225 8.0173 -1.2785 8.0173"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
