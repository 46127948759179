import { DebouncedFunc } from "lodash";
import React, {
  ChangeEvent,
  CSSProperties,
  InputHTMLAttributes,
  SyntheticEvent,
} from "react";
import { Props as InputMaskProps } from "react-input-mask";

import { CustomSubmitProps } from "../../pages/UserDetails/customSubmit";
import { DateInputProps } from "../DateInput/DateInput";
import { SelectAsyncProps, SelectSyncProps } from "./Select";
import { ErrorMapping, ValueMapping } from "./types";

type ValuesOf<T> = T[keyof T];

type GenericFormField = {
  hide?: boolean;
  condition?: boolean | (() => boolean);
  direction?: string;
  justify?: string;
};

type DynamicFormFieldHtml = {
  html: React.ReactChild;
} & GenericFormField;

export type FieldOnChange<TForm> = (
  e: ChangeEvent<HTMLInputElement>,
  state: State<TForm>
) => void | { newState: Partial<State<TForm>> };

export type GenericDefinition<TForm, TField, Key> = {
  name: Key;
  tooltip?: string;
  label?: React.ReactChild;
  labelClassName?: string;
  tooltipImage?: string;
  disabled?: string | boolean;
  noError?: boolean;
  after?: {
    html: React.ReactChild;
  };
  style?: CSSProperties;
  placeholder?: string;
  onChange?: FieldOnChange<TForm>;
  defaultValue?: TField;
  validate?: (v: TField | string) => string | null;
  crossValidate?: {
    name: keyof TForm;
    error: string;
    forward?: boolean;
  };
};

export type CheckboxFormField<TForm, Key extends keyof TForm> =
  TForm[Key] extends boolean
    ? GenericDefinition<TForm, TForm[Key], Key> & {
        type: "checkbox";
        className?: string;
      } & GenericFormField
    : never;

export type HeightFormField<TForm, Key extends keyof TForm> =
  TForm[Key] extends string
    ? GenericDefinition<TForm, TForm[Key], Key> & {
        type: "height";
        className?: string;
      } & GenericFormField
    : never;

export type DateFormField<TForm, Key extends keyof TForm> = TForm[Key] extends
  | string
  | undefined
  ? {
      type: "date";
      disableWeekend?: boolean;
      props: Omit<
        DateInputProps["config"],
        | "disabled"
        | "name"
        | "type"
        | "value"
        | "onChange"
        | "onBlur"
        | "style"
        | "placeholder"
        | "disableWeekend"
      >;
    } & GenericFormField &
      GenericDefinition<TForm, TForm[Key], Key>
  : never;

export type SyncSelectFormField<TForm, TField, Key> = {
  options: { label: React.ReactChild; value: TField }[];
  placeholder?: string;
  props?: Omit<
    SelectSyncProps<TField>,
    "name" | "value" | "onChange" | "options" | "placeholder"
  >;
  updateOthers?: (p?: string) => Promise<Partial<TForm>>;
  defaultValue?: TField | null;
} & GenericFormField &
  Omit<GenericDefinition<TForm, TField, Key>, "defaultValue">;

export type AsyncSelectFormField<TForm, TField, Key> = {
  loadOptions: (
    inputValue?: string
  ) => Promise<{ label: string; value: TField }[]>;
  isSearchable?: boolean;
  placeholder?: string;
  props?: Omit<
    SelectAsyncProps<TField>,
    | "name"
    | "value"
    | "onChange"
    | "loadOptions"
    | "placeholder"
    | "isSearchable"
  >;
  updateOthers?: (p?: string) => Promise<Partial<TForm>>;
} & GenericFormField &
  GenericDefinition<TForm, TField, Key>;

export type SelectFormField<TForm, TField, Key> =
  | SyncSelectFormField<TForm, TField, Key>
  | AsyncSelectFormField<TForm, TField, Key>;

export type InputFormField<TForm, Key extends keyof TForm> =
  TForm[Key] extends string
    ? {
        type: "text" | "email" | "password";
        inputMask?: InputMaskProps;
      } & GenericFormField &
        GenericDefinition<TForm, TForm[Key], Key> & {
          props?: InputHTMLAttributes<HTMLInputElement>;
        }
    : never;

export type DynamicFormFieldDefinition<TForm, TField, Key extends keyof TForm> =

    | CheckboxFormField<TForm, Key>
    | DateFormField<TForm, Key>
    | SelectFormField<TForm, TField, Key>
    | InputFormField<TForm, Key>
    | HeightFormField<TForm, Key>;

export function isCheckboxField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormFieldDefinition<TForm, TField, Key>
): field is CheckboxFormField<TForm, Key> {
  return (field as CheckboxFormField<TForm, Key>).type === "checkbox";
}

export function isHeightField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormFieldDefinition<TForm, TField, Key>
): field is HeightFormField<TForm, Key> {
  return (field as HeightFormField<TForm, Key>).type === "height";
}

export function isDateField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormFieldDefinition<TForm, TField, Key>
): field is DateFormField<TForm, Key> {
  return (field as DateFormField<TForm, Key>).type === "date";
}

export type FieldType<T> = T extends DynamicFormFieldDefinition<
  infer _ /* eslint-disable-line @typescript-eslint/no-unused-vars */,
  infer TField,
  infer _
>
  ? TField
  : never;

export function isSelectField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormFieldDefinition<TForm, TField, Key>
): field is SelectFormField<TForm, TField, Key> {
  return (
    (field as SyncSelectFormField<TForm, TField, Key>).options !== undefined ||
    (field as AsyncSelectFormField<TForm, TField, Key>).loadOptions !==
      undefined
  );
}

export function isAsyncSelectField<TForm, TField, Key extends keyof TForm>(
  field: SelectFormField<TForm, TField, Key>
): field is AsyncSelectFormField<TForm, TField, Key> {
  return (
    (field as AsyncSelectFormField<TForm, TField, Key>).loadOptions !==
    undefined
  );
}

export type DynamicFormFieldCallback<TForm> = {
  renderHtml: (state: TForm | undefined) => React.ReactChild;
} & GenericFormField;

export type DynamicFormField<TForm, TField, Key extends keyof TForm> =
  | DynamicFormFieldHtml
  | DynamicFormFieldDefinition<TForm, TField, Key>
  | DynamicFormFieldCallback<TForm>;

export function isHtmlField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormField<TForm, TField, Key>
): field is DynamicFormFieldHtml {
  return (field as DynamicFormFieldHtml).html !== undefined;
}

export function isCallbackField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormField<TForm, TField, Key>
): field is DynamicFormFieldCallback<TForm> {
  return (field as DynamicFormFieldCallback<TForm>).renderHtml !== undefined;
}

export function isDynamicField<TForm, TField, Key extends keyof TForm>(
  field: DynamicFormField<TForm, TField, Key>
): field is DynamicFormFieldDefinition<TForm, TField, Key> {
  return !isHtmlField(field) && !isCallbackField(field);
}

export type FieldRow<TForm> = ValuesOf<
  {
    [FieldKey in keyof TForm]: DynamicFormField<
      TForm,
      TForm[FieldKey],
      FieldKey
    >;
  }
>[];

export type SubmitResponse = { status?: number };

export type SubmitHandler<TData> = (
  formData: TData,
  event: SyntheticEvent<HTMLElement>,
  additionalData: { direction?: string },
  handleErrorOrResponse: (errorOrResponse: unknown) => unknown
) => Promise<void | {
  response?: SubmitResponse;
  onSuccess?: (response?: SubmitResponse) => void;
  onFailure?: (response?: SubmitResponse) => void;
  newState?: {
    data?: Partial<TData>;
  };
}>;

export type FormSubmitProps = Omit<
  CustomSubmitProps,
  "onSubmit" | "nextCopy"
> & { nextCopy?: string };
export type Props<TData> = {
  fields: FieldRow<TData>[];
  submitText?: string;
  className?: string;
  submitProps?: FormSubmitProps;
  data?: TData;
  id?: string;
  onSubmit: SubmitHandler<TData> | DebouncedFunc<SubmitHandler<TData>>;
};

export type State<TData> = {
  formPageError?: React.ReactChild;
  nextDisabled?: boolean;
} & ValueMapping<TData> &
  ErrorMapping<TData>;
