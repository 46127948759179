import React from "react";

import styles from "./PinkBanner.module.scss";

const PinkBanner = () => {
  return (
    <div className={styles.PinkBanner}>
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
      <div className={styles["pink-line"]} />
    </div>
  );
};

export default PinkBanner;
