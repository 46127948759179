/* eslint-disable @typescript-eslint/no-explicit-any */
import "./style.scss";

import moment from "moment";
import React from "react";
import { getDeepValue } from "utils";

import { ApiLoadedRider, assertRiderLoaded, EmptyDict } from "api/types";

import { Api } from "../../api";
import Header from "../../components/Header";
import AccountIcon from "../../components/svg/account";
import PaymentInfoIcon from "../../components/svg/paymentInfo";
import ReferralIcon from "../../components/svg/referral";
import ResetPasswordIcon from "../../components/svg/resetPassword";
import SubscriptionIcon from "../../components/svg/subscription";
import headerImage from "../../static/images/img-header--signup-n.jpg";
import headerImagePreview from "../../static/images/thumbnails/img-header--signup-n.jpg";
import AlertTemplate from "../../templates/Alert";
import { AccountProfileTable } from "./AccountProfileTable";
import { AccountReferralTable } from "./AccountReferralTable";
import { AlertContext } from "./components";
import { ManagePayments, PaymentDetails } from "./ManagePaymentsTables";
import {
  CancellationDetails,
  ManageSubscription,
  RiderAddress,
  SubscriptionDetails,
} from "./ManageSubscriptionTables";
import { ResetPasswordTable } from "./ResetPasswordTable";
import { AlertHandler } from "./types";

const headerImageMob = headerImage;
const headerImageMobPreview = headerImagePreview;

const ACCOUNT_PROFILE = 0;
const MANAGE_SUBSCRIPTION = 1;
const MANAGE_PAYMENTS = 2;
const REFERRAL = 3;
const PASSWORD = 4;

const AlertWrapper = (props: {
  visible: boolean;
  title?: string;
  message?: string;
  icon: unknown;
  hide: () => void;
}) => {
  if (props.visible) {
    return (
      <div className="alert-wrapper">
        <AlertTemplate
          className="alert box"
          buttonText="OK"
          buttonClassName="alert-button"
          messageComp={
            <div className="alert-message">
              {props.icon}
              <h3>{props.title}</h3>
              <div className="alert-message-body">{props.message}</div>
            </div>
          }
          close={props.hide}
        />
      </div>
    );
  }
  return <></>;
};

const tabNames = {
  profile: ACCOUNT_PROFILE,
  subscription: MANAGE_SUBSCRIPTION,
  paymentInfo: MANAGE_PAYMENTS,
  referral: REFERRAL,
  password: PASSWORD,
};

type Props = {
  api: Api;
  tab?: keyof typeof tabNames;
};

type BeforeLoad = {
  id?: undefined;
  referrerCode?: undefined;
  email?: undefined;
  first_name?: undefined;
  last_name?: undefined;
  username?: undefined;
  street?: undefined;
  phone?: undefined;
  birthdate?: undefined;
  address?: undefined;
};

type AfterLoad = {
  id: string;
  referrerCode: string;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  street: React.ReactChild;
  phone: string;
  birthdate: string;
  address: RiderAddress;
};

type State = {
  selectedTab: number;
  showAlert: boolean;
  alertMessage?: string;
  alertTitle?: string;
  alertIcon?: React.ReactChild;
  formPageError?: React.ReactChild;

  subscription?: SubscriptionDetails;
  cancellation?: CancellationDetails;
  paymentDetails?: PaymentDetails | EmptyDict;
  nextDisabled?: boolean;

  reason_for_leaving?: string;
  reason_details?: string;
} & (BeforeLoad | AfterLoad);

const tabs = [
  {
    text: "Profile",
    altText: "Profile",
    image: (
      <AccountIcon width="32px" height="32px" stroke="#000000" fill="#000000" />
    ),
  },
  {
    text: "Subscription",
    altText: "Subscription",
    image: <SubscriptionIcon width="32px" height="32px" />,
  },
  {
    text: "Payment Info",
    altText: "Payment",
    image: <PaymentInfoIcon width="32px" height="32px" />,
  },
  {
    text: "Referral",
    altText: "Referral",
    image: <ReferralIcon width="32px" height="32px" />,
  },
  {
    text: "Reset Password",
    altText: "Password",
    image: <ResetPasswordIcon width="32px" height="32px" />,
  },
];

const getAddress = (
  rider: ApiLoadedRider
):
  | {
      address_line_one: undefined;
      address_line_two: undefined;
      city: undefined;
      postcode: undefined;
    }
  | {
      address_line_one: string;
      address_line_two: string;
      city: string;
      postcode: string;
    } => {
  if (!rider || !rider.homeplaces) {
    return {
      address_line_one: undefined,
      address_line_two: undefined,
      city: undefined,
      postcode: undefined,
    };
  }
  const [homeplace] = rider.homeplaces;
  if (!homeplace) {
    return {
      address_line_one: undefined,
      address_line_two: undefined,
      city: undefined,
      postcode: undefined,
    };
  }
  const { address_line_one, address_line_two, city, postcode } = homeplace;

  return { address_line_one, address_line_two, city, postcode };
};

const getAddressText = ({
  address_line_one,
  address_line_two,
  city,
  postcode,
}:
  | {
      address_line_one: undefined;
      address_line_two: undefined;
      city: undefined;
      postcode: undefined;
    }
  | {
      address_line_one: string;
      address_line_two?: string;
      city?: string;
      postcode?: string;
    }): React.ReactChild => {
  if (!address_line_one) {
    return "";
  }

  if (!address_line_two) {
    return (
      <span>
        {address_line_one}
        <br />
        {city}, {postcode}
      </span>
    );
  }
  return (
    <span>
      {address_line_one},<br />
      {address_line_two}
      <br />
      {city}, {postcode}
    </span>
  );
};

const getBirthDate = (rider: any): string => {
  if (!rider || !rider.birth_date) {
    return "";
  }
  const [year, month, day] = rider.birth_date.split("-");
  return `${day}/${month}/${year}`;
};

const getPaymentInfo = async (api: Api) => {
  const response = await api.getPaymentInfo();
  return response.body || {};
};

const getSubscriptionDetails = async (api: Api) => {
  const response = await api.getSubscription();
  const data = response.body || { subscription: {} };
  const plan = data.subscription || undefined;

  const name = plan && plan.name;
  const planInterval = getDeepValue<string>(plan, "interval") || "";
  const planIntervalCount = getDeepValue<number>(plan, "interval_count") || 0;
  const intervalCount = planIntervalCount > 1 ? `${planIntervalCount} ` : "";
  const interval = planIntervalCount > 1 ? `${planInterval}s` : planInterval;
  const amount =
    plan && plan.amount ? `£${plan.amount}/${intervalCount}${interval}` : "";
  const startDate =
    (plan &&
      plan.start_date &&
      moment(plan.start_date).format("ddd DD/MM/YYYY")) ||
    "";
  const endDate =
    (plan && plan.end_date && moment(plan.end_date).format("ddd DD/MM/YYYY")) ||
    "";

  const { cancellation } = data;

  const subscriptionCancelled = cancellation || endDate;

  if (cancellation) {
    cancellation.collection_date = moment(cancellation.collection_date).format(
      "ddd DD/MM/YYYY"
    );
  }

  return {
    subscription: {
      startDate,
      amount,
      name,
      endDate,
      isCancelled: !!subscriptionCancelled,
    },
    cancellation,
  };
};

const getUserData = async (api: Api) => {
  const {
    body: { user, rider },
  } = await api.getUserData();

  assertRiderLoaded(rider);

  const address = getAddress(rider);
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    address,
    street: getAddressText(address),
    birthdate: getBirthDate(rider),
    username: user.display_name,
    phone: rider.phone_number_mobile,
    id: `${user.id}`,
    referrerCode: user.referrer_code,
  };
};

const TabsBar = ({
  selectedTab,
  onSelectTab,
}: {
  selectedTab: number;
  onSelectTab: (i: number) => void;
}) => (
  <div className="Account-tabs">
    {tabs.map((t, i) => (
      <div
        key={`account_tab-${i}`}
        className={`Account-tab ${
          i === selectedTab ? "Account-tab--active" : ""
        }`}
        role="button"
        onClick={() => onSelectTab(i)}
      >
        {t.image}
        <div className="Account-tab-title">{t.text}</div>
        <div className="Account-tab-title-alt">{t.altText}</div>
      </div>
    ))}
  </div>
);

export default class Account extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const selectedTab = (props.tab && tabNames[props.tab]) || ACCOUNT_PROFILE;

    this.state = {
      selectedTab,
      showAlert: false,
      alertMessage: "",
      alertTitle: "",
    };
  }

  async componentDidMount() {
    try {
      const userData = await getUserData(this.props.api);
      const subscriptionDetails = await getSubscriptionDetails(this.props.api);
      const paymentDetails = await getPaymentInfo(this.props.api);
      this.setState({ ...userData, ...subscriptionDetails, paymentDetails });
    } catch (err) {
      this.setState({ formPageError: err.message });
    }
  }

  alert: AlertHandler = (
    msg: string,
    title: string,
    icon: React.ReactChild
  ) => {
    this.setState({
      showAlert: true,
      alertTitle: title,
      alertMessage: msg,
      alertIcon: icon,
    });
  };

  hideAlert = () => {
    this.setState({
      showAlert: false,
      alertTitle: undefined,
      alertMessage: undefined,
      alertIcon: undefined,
    });
  };

  onPaymentDetailsUpdated = (paymentDetails: PaymentDetails) => {
    this.setState({ paymentDetails });
  };

  renderTable = (selectedTab: number) => {
    if (this.state.id === undefined) {
      return null;
    }
    switch (selectedTab) {
      case ACCOUNT_PROFILE:
        return <AccountProfileTable {...this.state} />;
      case MANAGE_SUBSCRIPTION:
        return (
          <ManageSubscription
            api={this.props.api}
            address={this.state.address}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            subscription={this.state.subscription}
            cancellation={this.state.cancellation}
          />
        );
      case MANAGE_PAYMENTS: {
        const { paymentDetails } = this.state;
        return (
          <ManagePayments
            paymentDetails={
              paymentDetails && "cardholder_name" in paymentDetails
                ? paymentDetails
                : undefined
            }
            nextDisabled={this.state.nextDisabled}
            onPaymentDetailsUpdated={this.onPaymentDetailsUpdated}
          />
        );
      }
      case REFERRAL:
        return <AccountReferralTable referrerCode={this.state.referrerCode} />;
      default:
        return (
          <ResetPasswordTable
            api={this.props.api}
            onAlert={this.alert}
            email={this.state.email}
          />
        );
    }
  };

  render() {
    return (
      <div className="Account">
        <Header
          headerCopy="Your Account"
          background={headerImage}
          backgroundPreview={headerImagePreview}
          backgroundMob={headerImageMob}
          backgroundMobPreview={headerImageMobPreview}
          className="half-height tc noBanner white-angle"
        />
        {this.state.formPageError ? (
          <div key="page_error" className="Account-error">
            <div className="pink tc-l">{this.state.formPageError}</div>
          </div>
        ) : (
          <AlertContext.Provider value={this.alert}>
            <AlertWrapper
              hide={this.hideAlert}
              visible={this.state.showAlert}
              title={this.state.alertTitle}
              message={this.state.alertMessage}
              icon={this.state.alertIcon}
            />
            <TabsBar
              selectedTab={this.state.selectedTab}
              onSelectTab={(i: number) => this.setState({ selectedTab: i })}
            />
            {this.state.id ? this.renderTable(this.state.selectedTab) : null}
          </AlertContext.Provider>
        )}
      </div>
    );
  }
}
