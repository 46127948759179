import "./ReferrerSocialShare.scss";

import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export interface ReferrerSocialShareProps {
  code: string;
  className: string;
}

function RefererSocialShare(props: ReferrerSocialShareProps) {
  const { code, className } = props;

  const signupUrl = `https://www.buzzbike.cc/signup?promo_code=${code || ""}`;
  const socialShareBody =
    `I subscribe to a bike subscription service called Buzzbike and thought you’d be interested. You get your own city bike & lock plus repairs & theft cover, all included in 1 easy subscription that you can cancel any time. ` +
    `\n\nUse my code to get free delivery and a special rate of £19.99/month for your 1st 4 months: ${
      code || ""
    }. If you use my code, I also get a discount so we both win.` +
    `\n\nFind out more and sign up here: `;
  const emailBody = `Hey!\n\n` + socialShareBody;
  return (
    <div className={className}>
      <EmailShareButton
        url={signupUrl}
        subject="See what all the Buzz is about"
        body={emailBody}
      />
      <FacebookShareButton url={signupUrl} quote={socialShareBody} />
      <TwitterShareButton url={signupUrl} title={socialShareBody} />
      <WhatsappShareButton url={signupUrl} title={socialShareBody} />
    </div>
  );
}

export default RefererSocialShare;
