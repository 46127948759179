import moment from "moment";
import React from "react";

import Form from "../../components/Form";
import {
  DateFormField,
  FieldRow,
  FormSubmitProps,
  SelectFormField,
  SubmitHandler,
} from "../../components/Form/formTypes";
import {
  COLLECTION_LEAD_TIME_WEEKDAYS,
  DATE_FORMAT,
  DISABLED_COLLECTION_DAYS_OF_WEEKS,
  DISABLED_DAYS_DELIVERY,
} from "../../constants";
import { addMonth, addWeekdays, dateToMoment } from "../../utils/dateHelpers";
import * as postcodes from "../../utils/postcodes";
import { chain, checkNonEmpty } from "../../utils/validateHelpers";

export type CollectionDetailsForm = {
  first_name: string;
  last_name: string;
  postcode: string;
  findAddress?: string;
  address_line_one: string;
  address_line_two: string;
  city: string;
  collection_date?: string;
};

export type CollectionDetailsSubmitHandler =
  SubmitHandler<CollectionDetailsForm>;

type Props = {
  data: CollectionDetailsForm;
  onSubmit: CollectionDetailsSubmitHandler;
  submitProps: FormSubmitProps;
};

const collectionAddressUdprn =
  (udprn: typeof postcodes.udprn) => async (p?: string) => {
    const x = await udprn(p);
    if (!x) {
      return {};
    }
    return {
      findAddress: "",
      postcode: x.postcode,
      city: x.city,
      address_line_two: x.address_line_two,
      address_line_one: x.address_line_one,
    };
  };

const CollectionDetails = (props: Props) => {
  const earliestAvailDelDate = moment("2019-09-02");
  let minCollectionDate = addWeekdays(moment(), COLLECTION_LEAD_TIME_WEEKDAYS);
  const maxCollectionDate = addMonth(moment(), 1);
  let delDateMsg = undefined;

  if (minCollectionDate.isBefore(earliestAvailDelDate)) {
    minCollectionDate = earliestAvailDelDate;
    delDateMsg = `The earliest collection date is ${minCollectionDate.format(
      "DD/MM/YYYY"
    )}`;
  }

  const fields: FieldRow<CollectionDetailsForm>[] = [
    [
      {
        name: "first_name",
        label: "First Name",
        type: "text",
        validate: checkNonEmpty(),
      },
      {
        name: "last_name",
        label: "Last Name",
        type: "text",
        validate: checkNonEmpty(),
      },
    ],
    [
      {
        name: "findAddress",
        label: "Find address",
        loadOptions: (inputValue: string) =>
          postcodes.autocomplete(inputValue).then((options) =>
            options.map((o) => ({
              label: o.suggestion,
              value: o.udprn,
            }))
          ),
        props: { externalLabel: true },
        isSearchable: true,
        updateOthers: collectionAddressUdprn(postcodes.udprn),
      } as SelectFormField<
        CollectionDetailsForm,
        string | undefined,
        "findAddress"
      >,
      { html: <div /> },
    ],
    [
      {
        name: "address_line_one",
        label: "Address Line 1",
        type: "text",
        validate: checkNonEmpty(),
      },
    ],
    [{ name: "address_line_two", label: "Address Line 2", type: "text" }],
    [
      {
        name: "city",
        label: "City",
        type: "text",
        validate: checkNonEmpty(),
        style: { textTransform: "capitalize" },
      },
    ],
    [
      {
        name: "postcode",
        label: "Postcode",
        type: "text",
        validate: checkNonEmpty(),
      },
    ],
    [{ html: <p className="f3 mt4 mb3 tl">Select collection date</p> }],
    [
      {
        name: "collection_date",
        label: delDateMsg ? (
          <span>
            Select date
            <br />
            <span className="delDateMsg">{delDateMsg}</span>{" "}
          </span>
        ) : (
          "Select date"
        ),
        type: "date",
        tooltip:
          "Collections are made Monday - Friday 9:30am - 5:30pm excluding public holidays.",
        props: {
          min: minCollectionDate.format("YYYY-MM-DD"),
          max: maxCollectionDate.format("YYYY-MM-DD"),
          disabledDays: DISABLED_DAYS_DELIVERY,
          disabledDaysOfWeek: DISABLED_COLLECTION_DAYS_OF_WEEKS,
        },
        placeholder: "DD/MM/YYYY",
        validate: chain([
          checkNonEmpty(),
          (v) => {
            const date = dateToMoment(v);
            if (date === null || !date.isValid()) {
              return "Please input a valid date in the format DD/MM/YYYY";
            }
            if (
              date.isBefore(minCollectionDate) ||
              date.day() === 0 ||
              date.day() === 6
            ) {
              return `Please enter a weekday no earlier than ${minCollectionDate.format(
                "dddd, DD MM YYYY"
              )}`;
            }
            if (
              date.isAfter(maxCollectionDate) ||
              date.day() === 0 ||
              date.day() === 6
            ) {
              return `Please enter a weekday no later than ${maxCollectionDate.format(
                "dddd, DD MM YYYY"
              )}`;
            }

            if (DISABLED_COLLECTION_DAYS_OF_WEEKS.includes(date.day())) {
              return "Please select another day";
            }

            let dayIsBlocked = false;
            for (const disabledDay of DISABLED_DAYS_DELIVERY) {
              if (moment(disabledDay, DATE_FORMAT.SERVER).isSame(date)) {
                dayIsBlocked = true;
                break;
              }
            }

            if (dayIsBlocked) {
              return "Please select another day";
            }

            return null;
          },
        ]),
      } as DateFormField<CollectionDetailsForm, "collection_date">,
    ],
    [{ html: <div className="Form-row-separator" /> }],
  ];
  return (
    <Form<CollectionDetailsForm>
      key={"DeliveryCollectionDetails"}
      id={"DeliveryCollectionDetails"}
      className="UserDetails-form"
      fields={fields}
      submitProps={props.submitProps}
      onSubmit={props.onSubmit}
      data={props.data}
    />
  );
};

export default CollectionDetails;
