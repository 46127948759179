import "core-js/stable";
import "./App.scss";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Helmet from "react-helmet";
import { Router } from "react-router-dom";

import { AuthWrapper, synchroniseOptimizelyId } from "./components/auth";
import CookieBanner from "./components/CookieBanner/index";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/ScrollToTop";
import { buildTsString } from "./components/VersionNumber";
import { LOWEST_SUBSCRIPTION_PRICE } from "./constants";
import usePageTracking from "./hooks/usePageTracking";
import useTracking from "./hooks/useTracking";
import Routes from "./Router/routes";

const pages = [
  {
    navName: "Blog",
    path: "/blog",
  },
];
const options = {
  timeout: 5000,
  position: "bottom center",
};

const title = "Buzzbike",
  description = LOWEST_SUBSCRIPTION_PRICE
    ? `Your very own premium bike and everything you need to ride for a subscription from only £${LOWEST_SUBSCRIPTION_PRICE} per month. Get cycling today!`
    : "Your very own premium bike and everything you need to ride for a £29.99 per month subscription. Get cycling today!",
  keywords = "site, keywords";

const history = createBrowserHistory();

console.log(`Website version ${buildTsString}`);

const useSetOptimizelyIdentityFromSegment = () => {
  useEffect(() => {
    synchroniseOptimizelyId();
  }, []);
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_CMS_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_GRAPH_CMS_ACCESS_TOKEN}`,
  },
});

const App = () => {
  useTracking(history);
  usePageTracking(history);
  useSetOptimizelyIdentityFromSegment();

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content={Array.isArray(keywords) ? keywords.join(", ") : keywords}
          />
          <meta name="view" content="width=device-width, initial-scale=1" />
          <script
            src={
              "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            }
            async
          />
        </Helmet>
        <AuthWrapper>
          {() => {
            return (
              <OptimizelyProvider optimizely={window.optimizelyClientInstance}>
                <Router history={history}>
                  <Navbar pages={pages} />
                  <ScrollToTop>
                    <Routes />
                  </ScrollToTop>
                </Router>
                <Provider template={AlertTemplate} {...options}>
                  <Footer />
                </Provider>
                <CookieBanner />
              </OptimizelyProvider>
            );
          }}
        </AuthWrapper>
      </div>
    </ApolloProvider>
  );
};

export default App;
