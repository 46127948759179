import React from "react";

import styles from "./RadioButton.module.scss";

export interface RadioButtonProps {
  className?: string;
  buttonSize?: string;
  buttonInnerSize?: string;
  label: string;
  labelClassName?: string;
  selected: boolean;
  onSelect?: () => void;
}

function RadioButton(props: RadioButtonProps) {
  const {
    className,
    buttonSize,
    buttonInnerSize,
    label,
    labelClassName,
    selected,
    onSelect,
  } = props;
  const buttonStyle = buttonSize
    ? { width: buttonSize, height: buttonSize }
    : {};
  const buttonInnerStyle = buttonInnerSize
    ? { width: buttonSize, height: buttonInnerSize }
    : {};

  return (
    <div
      className={`${styles.container} ${className || ""}`}
      onClick={onSelect}
    >
      <div
        className={`${styles.button} ${selected ? styles.buttonSelected : ""}`}
        style={buttonStyle}
      >
        {selected && (
          <div className={styles.innerButton} style={buttonInnerStyle} />
        )}
      </div>
      <span className={`${styles.label} ${labelClassName || ""}`}>{label}</span>
    </div>
  );
}

export default RadioButton;
