import { useCallback, useRef, useState } from "react";

/**
 * Creates a state value with the given default, and provides stable
 * getter and setter functions for accessing it. These functions do not change
 * even as the underlying value does.
 */
export function useGetterSetter<T>(
  defaultValue: T
): [value: T, getValue: () => T, setValue: (value: T) => void] {
  const [valueState, setValueState] = useState(defaultValue);

  const valueRef = useRef(valueState);
  valueRef.current = valueState;

  const getValueFn = useCallback(() => {
    return valueRef.current;
  }, []);

  const setValueFn = useCallback<(value: T) => void>((newValue) => {
    setValueState(newValue);
    valueRef.current = newValue;
  }, []);

  return [valueState, getValueFn, setValueFn];
}
