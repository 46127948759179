export interface User {
  display_name: string;
  email: string;
  first_name: string;
  hdyhau: string;
  id: string;
  is_admin: boolean;
  last_name: string;
  referrer_code: string;
  test_mode: boolean;
}

export interface SegmentProduct {
  product_id: string;
  sku?: string;
  category?: string;
  name: string;
  brand?: string;
  variant?: string;
  price: number;
  quantity?: number;
  coupon?: string;
  position?: number;
  url?: string;
  image_url?: string;
}

export enum SEGMENT_EVENT {
  SIGN_UP = "sign_up",
  LOGIN = "login",
  ONBOARDING_FUNNEL = "Start onboarding funnel step",
  // https://segment.com/docs/connections/spec/ecommerce/v2/#order-completed
  CANCELLATION_FUNNEL_SCREEN_VIEWED = "Cancellation Funnel Screen Viewed",
  CANCELLATION_FUNNEL_STARTED = "Cancellation Funnel Started",
  CANCELLATION_FUNNEL_COMPLETED = "Cancellation Funnel Completed",
  PRODUCT_VIEWED = "Product Viewed",
  DELIVERY_AVAILABILITY_LOADED = "Delivery Availability Loaded",
  WALLET_PAYMENT_STARTED = "Wallet payment started",
  CLAIM_MY_DISCOUNT = "Claim My Discount",
}
