import * as Sentry from "@sentry/react";
import { History } from "history";
import { isEmpty, shuffle } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { Api, storeName, storeTemporaryAccessToken } from "../api";
import { ApiSignup } from "../api/types";
import { useAuthDetails } from "../components/auth";
import Form from "../components/Form";
import {
  InputFormField,
  SubmitHandler,
  SyncSelectFormField,
} from "../components/Form/formTypes";
import Header from "../components/Header";
import headerImage from "../static/images/img-header--signup-n.jpg";
import headerImagePreview from "../static/images/thumbnails/img-header--signup-n.jpg";
import { SEGMENT_EVENT } from "../types";
import { saveLoginIdentity } from "../utils";
import {
  chain,
  checkEmail,
  checkLong,
  checkNonEmpty,
} from "../utils/validateHelpers";
import styles from "./SignUp.module.scss";

const headerImageMob = headerImage;
const headerImageMobPreview = headerImagePreview;

type SignUpFormData = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  hdyhau: string;
  wants_marketing_emails: boolean;
};

const fields = (hdyhauValues: { label: string; value: string }[]) => [
  [
    {
      name: "first_name",
      type: "text",
      label: "First Name",
      validate: chain([checkNonEmpty()]),
    } as InputFormField<SignUpFormData, "first_name">,
    {
      name: "last_name",
      type: "text",
      label: "Last Name",
      validate: chain([checkNonEmpty()]),
    } as InputFormField<SignUpFormData, "last_name">,
  ],
  [
    {
      name: "email",
      type: "email",
      label: "Email address",
      validate: chain([checkNonEmpty(), checkEmail()]),
    } as InputFormField<SignUpFormData, "email">,
  ],
  [
    {
      name: "password",
      type: "password",
      label: "Password",
      validate: checkLong(8)(),
    } as InputFormField<SignUpFormData, "password">,
  ],
  [
    {
      name: "password_confirmation",
      type: "password",
      label: "Password confirmation",
      crossValidate: {
        name: "password",
        error: "Passwords must match",
      },
    } as InputFormField<SignUpFormData, "password_confirmation">,
  ],
  [
    {
      html: <div style={{ height: "3rem" }} />,
    },
  ],
  [
    {
      name: "hdyhau",
      options: hdyhauValues,
      placeholder: "Select",
      label: <span className="pink">How did you hear about us?</span>,
      validate: checkNonEmpty(),
    } as SyncSelectFormField<SignUpFormData, string, "hdyhau">,
  ],
  [
    {
      html: <div style={{ height: "2rem" }} />,
    },
  ],
  [
    {
      name: "wants_marketing_emails",
      type: "checkbox",
      props: { data: { wants_marketing_emails: false } },
      label: (
        <span
          style={{
            lineHeight: "1.5rem",
            letterSpacing: "0.005em",
            marginTop: "-2px",
            marginLeft: "5px",
          }}
        >
          Stay in touch. Send me occasional emails and SMS&apos;s about
          Buzzbike&apos;s special offers, free events and latest news. Check out
          our privacy policy{" "}
          <Link to="/privacy-policy" style={{ textDecoration: "underline" }}>
            here
          </Link>
          .
        </span>
      ),
    } as InputFormField<SignUpFormData, "wants_marketing_emails">,
  ],
  [
    {
      html: <div style={{ height: "2rem" }} />,
    },
  ],
];

const defaultHdyhauOptions = [{ value: "Other", label: "Other" }];

const useHdyhauValues = (api: Api) => {
  const apiRef = useRef(api);
  const [values, setValues] =
    useState<{ label: string; value: string }[]>(defaultHdyhauOptions);

  useEffect(() => {
    let applyChanges = true;
    const loadValues = async () => {
      const res = await apiRef.current.getHDYHAUValues();
      if (
        res.status === 200 &&
        res.body &&
        Array.isArray(res.body) &&
        !isEmpty(res.body)
      ) {
        const options = shuffle(
          res.body
            .map((v) => ({
              value: v.description,
              label: v.description,
            }))
            // Make sure there's no Other duplicates
            .filter((option) => option.value !== "Other")
        ).concat(defaultHdyhauOptions);

        if (applyChanges) {
          setValues(options);
        }
      } else {
        if (applyChanges) {
          setValues(defaultHdyhauOptions);
        }
      }
    };
    loadValues();
    return () => {
      // avoid state change after unmount
      applyChanges = false;
    };
  }, []);

  return values;
};

type SignUpPageProps = {
  api: Api;
  history: History;
};

const applySignUp = async (
  api: Api,
  formData: SignUpFormData,
  { access_token }: ApiSignup
) => {
  try {
    const { first_name: firstName, last_name: lastName, email } = formData;
    const user = {
      firstName,
      lastName,
      email,
    };

    const data = await api.getUserData();
    const { id: userId, hdyhau } = data.body.user;
    saveLoginIdentity({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      id: userId,
    });
    window.analytics.track(SEGMENT_EVENT.SIGN_UP, { ...user, hdyhau });
  } catch (error) {
    Sentry.captureException(error);
  }
  storeTemporaryAccessToken(access_token);
  storeName(formData.first_name);
};

const SignUp: FC<SignUpPageProps> = ({ api, history }) => {
  const apiRef = useRef(api);
  const hdyhauValues = useHdyhauValues(apiRef.current);
  const { refresh } = useAuthDetails();

  const onSubmit: SubmitHandler<SignUpFormData> = useCallback(
    async (formData, event, additionalData, errorResponseHandler) => {
      try {
        const o = await apiRef.current.signup(formData);
        if (o.status === 200) {
          await applySignUp(apiRef.current, formData, o.body);
          refresh();
          history.push("/details");
        } else {
          errorResponseHandler(o);
        }
      } catch (e) {
        errorResponseHandler(e);
      }
    },
    [refresh, history]
  );

  return (
    <div className="UserDetailsPage">
      <Header
        headerCopy="Sign Up"
        background={headerImage}
        backgroundPreview={headerImagePreview}
        backgroundMob={headerImageMob}
        backgroundMobPreview={headerImageMobPreview}
        className="half-height tc noBanner white-angle"
      />
      <div className={styles["sign-up-container"]}>
        <h1 className="tc">Sign up to Buzzbike</h1>
        <Form<SignUpFormData>
          id="SignUp0"
          fields={fields(hdyhauValues)}
          submitText="SIGN UP"
          onSubmit={onSubmit}
          submitProps={{ hasPrev: false, rightArrow: false }}
        />
      </div>
      <div className={`mid-gray ${styles["sign-up-login-section"]}`}>
        Already got an account?{" "}
        <Link to="/log_in" className="pink">
          Log in here
        </Link>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default withRouter(SignUp as any);
