export type BlogContent = {
  raw: {
    children: Item[];
  };
};

export type Blog = {
  id: string;
  title?: string;
  slug?: string;
  excerpt?: string;
  coverImage?: ImageType;
  author?: AuthorType;
  publishedAt?: string;
  content: BlogContent;
};

export enum ItemType {
  PARAGRAPH = "paragraph",
  IMAGE = "image",
  SPAN = "span",
  BULLETED_LIST = "bulleted-list",
  NUMBERED_LIST = "numbered-list",
  LINK = "link",
  QUOTE = "block-quote",
  SECTION_DIVIDER = "section-divider",
  HEADING_ONE = "heading-one",
  HEADING_TWO = "heading-two",
  HEADING_THREE = "heading-three",
  HEADING_FOUR = "heading-four",
  HEADING_FIVE = "heading-five",
  HEADING_SIX = "heading-six",
  TABLE = "table",
  TABLE_HEAD = "table-head",
  TABLE_BODY = "table-body",
}

export interface Item {
  type?: ItemType;
  lastItem?: boolean;
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  children?: Item[];
  href?: string;
  src?: string;
  width?: number;
  height?: number;
}

export type AuthorType = {
  id: string;
  name: string;
  picture: ImageType;
  biography: string;
};

export type ImageType = {
  id?: string;
  url: string;
  fromAssets?: boolean;
};
