import * as Sentry from "@sentry/react";
import { AxiosResponse } from "axios";
import jwt_decode from "jwt-decode";
import moment, { Moment } from "moment";
import React from "react";

import { UTM_PARAMS_STORAGE_KEY } from "../constants";
import { Maybe } from "../ts-types";
import axios from "./base";
import {
  ApiCancellationSummary,
  ApiCancelSubscription,
  ApiCancelSubscriptionData,
  ApiChangePassword,
  ApiCreateRider,
  ApiCreateRiderData,
  ApiCreateStripeSubscription,
  ApiGetPaymentInfo,
  ApiGetSubscription,
  ApiGetUserData,
  ApiHDYHAUValues,
  ApiKitAvailability,
  ApiLogin,
  ApiOrderSummary,
  ApiPostContactForm,
  ApiPostContactFormData,
  ApiPostSubscribeMail,
  ApiPostSubscribeMailData,
  ApiRawRegistrationData,
  ApiRedeemResetPasswordToken,
  ApiResponse,
  ApiScheduleDelivery,
  ApiScheduleDeliveryData,
  ApiSendResetPasswordEmail,
  ApiSignup,
  ApiSignupData,
  ApiStartPaymentProcess,
  ApiUpdatePaymentInfo,
  ApiUpdateRegistrationData,
  ApiUpdateRegistrationDataInput,
  ApiUpdateRiderInfo,
  DeliveryRestriction,
  OptionalRiderInfo,
  SubscriptionPlan,
  UtmParamsValue,
} from "./types";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const originalLocalSetItem = localStorage.setItem;
const originalSessionSetItem = sessionStorage.setItem;

localStorage.setItem = function (key: string, value: string) {
  const event = new Event("storage");
  document.dispatchEvent(event);
  originalLocalSetItem.apply(this, [key, value]);
};

sessionStorage.setItem = function (key: string, value: string) {
  const event = new Event("storage");
  document.dispatchEvent(event);

  originalSessionSetItem.apply(this, [key, value]);
};

const getHeaders = (
  accessToken?: Maybe<string>,
  others: Record<string, unknown> = {}
) => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: accessToken ? `JWT ${accessToken}` : ``,
    ...others,
  };
};

async function getResponse<TResponse>(
  response: AxiosResponse<TResponse>
): ApiResponse<TResponse> {
  const body = await response.data;
  return { status: response.status, body };
}

async function post<TResponse, TData>(
  endpoint: string,
  data: TData,
  accessToken?: Maybe<string>
) {
  const response = await axios.post<TResponse>(`${baseURL}/${endpoint}`, data, {
    headers: getHeaders(accessToken),
  });
  return getResponse(response);
}

async function get<TResponse>(endpoint: string, accessToken: Maybe<string>) {
  const response = await axios.get<TResponse>(`${baseURL}/${endpoint}`, {
    headers: getHeaders(accessToken),
  });
  return getResponse(response);
}

async function patch<TResponse, TData>(
  endpoint: string,
  data: TData,
  accessToken?: Maybe<string>
) {
  const response = await axios.patch<TResponse>(
    `${baseURL}/${endpoint}`,
    data,
    {
      headers: getHeaders(accessToken),
    }
  );
  return getResponse(response);
}

const extractTokenFromResponse = (res: {
  status: number;
  body?: {
    access_token: string;
  };
}) => (res.status === 200 && res.body ? res.body.access_token : null);

const TOKEN_KEY = "BUZZBIKE_ACCESS_TOKEN";

const EXPIRY_KEY = "BUZZBIKE_TOKEN_EXPIRY";

const NAME_KEY = "BUZZBIKE_NAME";

const CONTINUE_KEY = "BUZZBIKE_CONTINUE_SIGNUP";

export const storeAccessToken = (accessToken: string) => {
  localStorage.setItem(TOKEN_KEY, accessToken);
  localStorage.setItem(EXPIRY_KEY, moment().toISOString());
};

export const storeTemporaryAccessToken = (accessToken: string) => {
  removeAccessToken();
  sessionStorage.setItem(TOKEN_KEY, accessToken);
};

export const removeAccessToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRY_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

export const retrieveAccessToken = () => {
  const temporaryToken = sessionStorage.getItem(TOKEN_KEY);
  if (temporaryToken) {
    return temporaryToken;
  }

  const expiry = localStorage.getItem(EXPIRY_KEY);
  if (!expiry) {
    return null;
  }
  if (moment().diff(moment(expiry), "days") > 14) {
    removeAccessToken();
    return null;
  }
  return localStorage.getItem(TOKEN_KEY);
};

export const retrieveName = () => {
  const name = localStorage.getItem(NAME_KEY);
  if (name && name !== "") {
    return name;
  }
  return null;
};

export const retrieveUserId = (accessToken: string | null): string | null => {
  if (!accessToken) {
    return null;
  }
  try {
    const decoded = jwt_decode<{ identity: number | string }>(accessToken);
    return `${decoded.identity}`;
  } catch {
    return null;
  }
};

export const storeName = (name: string) => localStorage.setItem(NAME_KEY, name);

export const retrieveContinueSignup = () => localStorage.getItem(CONTINUE_KEY);

export const setContinueSignup = (p: boolean) =>
  p
    ? localStorage.setItem(CONTINUE_KEY, "true")
    : localStorage.removeItem(CONTINUE_KEY);

export const api = (
  getAccessToken: () => Maybe<string>,
  setAccessToken: (token?: Maybe<string>) => void
) => ({
  setSignupCompleted: () => {
    setContinueSignup(false);
  },

  auth: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): ApiResponse<ApiLogin> =>
    await post<ApiLogin, { username: string; password: string }>("auth/", {
      username,
      password,
    }).then((res) => {
      setAccessToken(extractTokenFromResponse(res));
      return res;
    }),

  signup: async (data: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirmation: string;
    hdyhau: string;
    wants_marketing_emails: boolean;
  }): ApiResponse<ApiSignup> => {
    let parsedUtmParams: UtmParamsValue = {};
    try {
      const utmParams = localStorage.getItem(UTM_PARAMS_STORAGE_KEY) || "{}";
      parsedUtmParams = JSON.parse(utmParams);
    } catch (error) {
      const scope = new Sentry.Scope();
      scope.setTag("section", "signUp");
      Sentry.captureException(error, scope);
    }
    const res = await post<ApiSignup, ApiSignupData>("create_user", {
      ...data,
      ...parsedUtmParams,
    });
    setAccessToken(extractTokenFromResponse(res));
    return res;
  },

  createRider: (data: ApiCreateRiderData): ApiResponse<ApiCreateRider> =>
    post("create_rider", data, getAccessToken()),

  putRegistrationData: (
    data: ApiUpdateRegistrationDataInput
  ): ApiResponse<ApiUpdateRegistrationData> =>
    post("registration", data, getAccessToken()),
  getRegistrationData: (): ApiResponse<{
    data: ApiRawRegistrationData;
  }> => get("registration", getAccessToken()),
  getKitAvilability: (): ApiResponse<ApiKitAvailability> =>
    get("kit_availability", getAccessToken()),

  scheduleDelivery: (
    data: ApiScheduleDeliveryData
  ): ApiResponse<ApiScheduleDelivery> =>
    post("kit_order", data, getAccessToken()),
  getOrderSummary: (
    subscriptionPlanId?: number
  ): ApiResponse<ApiOrderSummary> => {
    const url = subscriptionPlanId
      ? `kit_order/${subscriptionPlanId}`
      : "kit_order";

    return get(url, getAccessToken());
  },
  getHDYHAUValues: (): ApiResponse<ApiHDYHAUValues> =>
    get("hdyhau", getAccessToken()),
  getUserData: (): ApiResponse<ApiGetUserData> =>
    get("auth/", getAccessToken()),
  changePassword: (
    current_password: string,
    new_password: string
  ): ApiResponse<ApiChangePassword> =>
    post(
      "change_password/",
      { current_password, new_password },
      getAccessToken()
    ),
  getSubscription: (): ApiResponse<ApiGetSubscription> =>
    get("subscription_details", getAccessToken()),
  getCancellationSummary: (
    expectedCancellationDate: Moment
  ): ApiResponse<ApiCancellationSummary> => {
    const cancellation_date_utc = expectedCancellationDate.unix();
    return get(
      `cancellation_summary?cancellation_date_utc=${cancellation_date_utc}`,
      getAccessToken()
    );
  },
  cancelSubscription: (
    data: ApiCancelSubscriptionData
  ): ApiResponse<ApiCancelSubscription> =>
    post(`cancel_subscription`, data, getAccessToken()),
  getPaymentInfo: (): ApiResponse<ApiGetPaymentInfo> =>
    get("payment_details", getAccessToken()),
  logout: () => {
    removeAccessToken();
    setAccessToken(undefined);
    removeAccessToken();
  },
  updateRiderInfo: (
    rider_id: string,
    info: OptionalRiderInfo
  ): ApiResponse<ApiUpdateRiderInfo> =>
    patch(`riders/${rider_id}/about-you/`, { ...info }, getAccessToken()),
  getSubscriptionPlans: (): ApiResponse<SubscriptionPlan> =>
    get("default_payment_plans", getAccessToken()),
  updatePromotionCode: (
    promotion_code: string
  ): ApiResponse<SubscriptionPlan> =>
    patch("promotion_code", { promotion_code }, getAccessToken()),
  getDeliveryRestriction: (): ApiResponse<DeliveryRestriction> =>
    get("delivery_availability", getAccessToken()),
});

export type Api = ReturnType<typeof api>;

export const withApi =
  <TProps extends unknown>(
    getAccessToken: () => Maybe<string>,
    setAccessToken: (token?: Maybe<string>) => void
  ) =>
  (Component: React.ComponentType<TProps>) =>
  (props: TProps) =>
    <Component {...props} api={api(getAccessToken, setAccessToken)} />;

export const postSubscribeMail = (
  data: ApiPostSubscribeMailData
): Promise<AxiosResponse<ApiPostSubscribeMail>> => {
  return axios.post(baseURL + "/mailing_list_subscriptions", data);
};

export const postContactForm = (
  data: ApiPostContactFormData
): Promise<AxiosResponse<ApiPostContactForm>> => {
  return axios.post(baseURL + "/contact_form/", data);
};

export const sendResetPasswordEmail = (
  addr: string
): Promise<AxiosResponse<ApiSendResetPasswordEmail>> => {
  return axios.post(baseURL + "/reset_password_tokens/", {
    user_email: addr,
    next_path: null,
  });
};

export const redeemResetPasswordToken = (
  newPassword: string,
  token: string
): Promise<AxiosResponse<ApiRedeemResetPasswordToken>> => {
  return axios.patch(baseURL + `/reset_password_tokens/${token}`, {
    new_password: newPassword,
  });
};

export const updatePaymentInfo = (
  access_token: Maybe<string>
): ApiResponse<ApiUpdatePaymentInfo> => {
  return post("payment_details", {}, access_token);
};

export const createStripeSubscription = (
  access_token: Maybe<string>,
  subscriptionPlanId?: number
): ApiResponse<ApiCreateStripeSubscription> => {
  return post(
    "create_stripe_subscription",
    { promotion_code_type_id: subscriptionPlanId },
    access_token
  );
};

export const startPaymentProcess =
  async (): Promise<ApiStartPaymentProcess> => {
    const access_token = retrieveAccessToken();
    const res = await post<ApiStartPaymentProcess, unknown>(
      "start_setup_stripe_payment",
      {},
      access_token
    );

    return res.body;
  };

const signupStateToStep = {
  RegistrationSteps: 0,
  KitOrder: 3,
  Delivery: 5,
  PaymentRequired: 6,
  Complete: 7,
  Unknown: 0,
  Terms: 2,
};

export const getSignupStateKey = (state: keyof typeof signupStateToStep) => {
  return signupStateToStep[state] || 0;
};

export const isSignupComplete = (state: string) => state === "Complete";
