import React from "react";
import Helmet from "react-helmet";
import { getDeepValue } from "utils";

import { Blog } from "../types";

export interface BlogMetaProps {
  data: Blog;
}

function BlogMeta(props: BlogMetaProps) {
  const {
    data: { title = "", slug = "", coverImage, author, publishedAt = "" },
  } = props;
  const mainImageUrl = getDeepValue<string>(coverImage, "url");
  const mainImageFromAssets = getDeepValue<boolean>(coverImage, "fromAssets");
  const authorName = getDeepValue<string>(author, "name");
  const authorAvatarUrl = getDeepValue<string>(author, "avatar.url");
  const authorAvatarFromAssets = getDeepValue<boolean>(
    author,
    "avatar.fromAssets"
  );

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const organizationLogoSrc = require(`static/images/logo-sm--pink.png`);

  const mainImgSrc = mainImageFromAssets
    ? require(`static/images/${mainImageUrl}`)
    : mainImageUrl;
  const authorAvatarSrc = authorAvatarFromAssets
    ? require(`static/images/${authorAvatarUrl}`)
    : authorAvatarUrl;

  const googleSearchScript = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://google.com/article",
    },
    headline: title,
    image: [mainImgSrc],
    datePublished: publishedAt,
    dateModified: publishedAt,
    author: {
      "@type": "Person",
      name: authorName,
      image: authorAvatarSrc,
    },
    publisher: {
      "@type": "Organization",
      name: "Buzzbike",
      logo: {
        "@type": "ImageObject",
        url: organizationLogoSrc,
      },
    },
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={slug} />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={slug} />
      <meta itemProp="image" content={mainImgSrc} />

      <script type="application/ld+json">
        {JSON.stringify(googleSearchScript)}
      </script>
    </Helmet>
  );
}

export default BlogMeta;
