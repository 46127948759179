import React from "react";

import { SvgSizeProps } from "./types";

export default ({ width, height }: SvgSizeProps) => (
  <svg
    width={width || "10px"}
    height={height || "9px"}
    viewBox="0 0 10 9"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <path
        d="M7.86440032,2.7112 C7.8628,4.115 6.725,5.2526 5.3212,5.254 C3.2724,5.2563 1.6122,6.9165 1.6099,8.9653 L0.9227,8.9653 C0.924,7.0904 2.1117,5.4216 3.8829,4.8065 C2.8858,4.1223 2.5084,2.8304 2.9807,1.7171 C3.4527,0.6037 4.6438,-0.0231 5.8288,0.2182 C7.0138,0.4595 7.865,1.5018 7.86440032,2.7112 Z M5.3216,4.5669 L5.3212,4.5669 C6.3453,4.565 7.1752,3.7353 7.1773,2.7112 C7.1773,1.6863 6.3465,0.8555 5.3216,0.8555 C4.2967,0.8555 3.466,1.6863 3.466,2.7112 C3.466,3.7361 4.2967,4.5669 5.3216,4.5669 Z M9.7238,6.4903 L8.1061,8.1079 L7.6202,7.622 L8.4251,6.8171 L7.8301,6.8171 C7.0054,6.8184 6.337,7.4865 6.3356,8.3112 L6.3356,8.9657 L5.6476,8.9657 L5.6476,8.3104 C5.6503,7.1068 6.6261,6.132 7.8297,6.1304 L8.3912,6.1304 L7.6206,5.3598 L8.1065,4.8739 L9.7238,6.4903 Z"
        id="path-sgarhrteah51"
      />
    </defs>
    <g
      id="Page-twareg28"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Buzzbike_v5ghset" transform="translate(-338.000000, -257.000000)">
        <g id="Group-eht3008" transform="translate(338.000000, 257.000000)">
          <mask id="mask-ay4ger2" fill="white">
            <use xlinkHref="#path-sgarhrteah51" />
          </mask>
          <g id="Clip-eraeht3007" />
          <polygon
            id="Fill-hey5a3006"
            fill="#000000"
            mask="url(#mask-ay4ger2)"
            points="-1.1432 -1.8992 11.7898 -1.8992 11.7898 11.0317 -1.1432 11.0317"
          />
        </g>
      </g>
    </g>
  </svg>
);
