import React, { HTMLAttributes } from "react";

import { ImageType } from "../types";

export interface BlogImageProps extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  image: ImageType;
}

function BlogImage(props: BlogImageProps) {
  const {
    className,
    image: { url, fromAssets },
    ...rest
  } = props;
  const imgSrc = fromAssets ? require(`static/images/${url}`) : url;

  return (
    <img className={className} src={imgSrc} alt={"blog-image"} {...rest} />
  );
}

export default BlogImage;
