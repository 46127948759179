/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import "./style.scss";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { get, head, isEmpty, isNil, merge, sortBy, uniq } from "lodash";
import moment, { Moment } from "moment";
import React, { RefObject, SyntheticEvent } from "react";
import ReactLoading from "react-loading";
import { RouteComponentProps } from "react-router-dom";
import { Maybe } from "ts-types";
import { SEGMENT_EVENT } from "types";
import { getDeepValue } from "utils";

import {
  ApiKitAvailability,
  ApiKitItemType,
  ApiKitSpec,
  KitCategory,
  SelectedKitItemType,
  SubscriptionPlan,
} from "api/types";
import { KitItemType } from "components/KitShop/KitShop";
import { DATE_FORMAT, DISABLED_DAYS_DELIVERY } from "constants/index";
import {
  getEarliestDeliveryDate,
  getWeekDaysFromNowToMinDeliveryDate,
} from "utils/dateHelpers";

import { gtm } from "../../Analytics";
import { Api, getSignupStateKey } from "../../api";
import Form from "../../components/Form";
import { SubmitHandler } from "../../components/Form/formTypes";
import Header from "../../components/Header";
import headerImage from "../../static/images/img-header--signup-n.jpg";
import headerImagePreview from "../../static/images/thumbnails/img-header--signup-n.jpg";
import ApplicationThankYou from "./ApplicationThankYou";
import formFields, {
  DynamicForm,
  DynamicFormKey,
  FormState,
  getMinDeliveryDate,
  RegistrationData,
  stepTabs,
} from "./formFields";

const headerImageMob = headerImage;
const headerImageMobPreview = headerImagePreview;

interface Props extends RouteComponentProps {
  api: Api;
}

type State = FormState & {
  currentStepKey: number;
  kitsItem?: KitItemType[];
  carryThrough: Pick<RegistrationData, "selected_items">;
  fieldValues?: any;
  referrerCode: string;
  [k: string]: any;
  deliveryLeadDays: number;
  registrationDataLoaded: boolean;
  subscriptionPlans: SubscriptionPlan[];
  selectedSubscriptionPlanIndex: number | null;
  discountAmount: number;
  totalAmount: number;
  deliveryDateAvailable: boolean;
};

class UserDetails extends React.Component<Props, State> {
  steps: DynamicForm<any>[];
  tabRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || "");

    this.state = {
      currentStepKey: 0,
      registrationData: {},
      kitItems: [],
      carryThrough: {},
      referrerCode: "",
      fieldValues: undefined,
      deliveryLeadDays: 0,
      registrationDataLoaded: false,
      subscriptionPlans: [],
      selectedSubscriptionPlanIndex: null,
      discountAmount: 0,
      totalAmount: 0,
      deliveryDateAvailable: true,
    };

    this.steps = formFields(
      this.nextStep,
      this.props.api,
      this.getRegData,
      this.isCompany,
      this.kitItems,
      0,
      [],
      0,
      this.onSubscriptionPlanSelected,
      this.onSelectKitItem,
      this.stripe,
      this.onPromotionCodeApplied,
      0,
      0,
      this.onSetDiscountAmount,
      this.onTotalChange
    );

    if (!this.done()) {
      this.analytics(this.state.currentStepKey);
      this.stepInterceptor(this.steps[this.state.currentStepKey].onLoad);
    }

    this.tabRef = React.createRef<HTMLDivElement>();
  }

  stripe: Promise<Stripe | null>;

  isCompletedApplication = () => {
    return this.props.match.path === "/completed-application";
  };

  refreshSteps = (newState?: State) => {
    const {
      deliveryLeadDays,
      subscriptionPlans,
      selectedSubscriptionPlanIndex,
      discountAmount,
      totalAmount,
    } = newState || this.state;

    this.steps = formFields(
      this.nextStep,
      this.props.api,
      this.getRegData,
      this.isCompany,
      this.kitItems,
      deliveryLeadDays,
      subscriptionPlans,
      selectedSubscriptionPlanIndex || 0,
      this.onSubscriptionPlanSelected,
      this.onSelectKitItem,
      this.stripe,
      this.onPromotionCodeApplied,
      discountAmount,
      totalAmount,
      this.onSetDiscountAmount,
      this.onTotalChange
    );
  };

  done = () => {
    return this.state.currentStepKey >= this.steps.length;
  };

  stepInterceptor = (
    stepHandler: DynamicForm<any>["onLoad"],
    data?: unknown
  ) => {
    if (stepHandler) {
      const { fieldValues, registrationData } = stepHandler(this.state, data);
      this.setState((prevState) => {
        const state = Object.keys(fieldValues || {}).reduce((acc, formKey) => {
          acc[formKey] = { ...prevState[formKey], ...fieldValues[formKey] };
          return acc;
        }, {} as any);
        return {
          registrationData: {
            ...prevState.registrationData,
            ...registrationData,
          },
          ...state,
        };
      });
    }
  };

  onTotalChange = (totalAmount: number) => {
    this.setState(
      {
        totalAmount,
      },
      () => {
        this.refreshSteps();
        this.forceUpdate();
      }
    );
  };

  onSetDiscountAmount = (discountAmount: number) => {
    this.setState(
      {
        discountAmount,
      },
      () => {
        this.refreshSteps();
        this.forceUpdate();
      }
    );
  };

  onSubscriptionPlanSelected = (index: number) => {
    this.setState(
      {
        selectedSubscriptionPlanIndex: index,
      },
      () => {
        this.refreshSteps();
        this.forceUpdate();
      }
    );
  };

  onSelectKitItem = (id: number, sizeId?: number) => {
    const { selected_items = [] } = this.state.registrationData;
    let newSelectedItems = selected_items;

    let currentSelectedItem: Maybe<SelectedKitItemType> = null;
    let currentSelectedItemIndex = -1;
    selected_items.forEach((item, index) => {
      if (item.kit_inventory_id === id) {
        currentSelectedItem = item;
        currentSelectedItemIndex = index;
      }
    });
    const isSameSize =
      currentSelectedItem &&
      sizeId === (currentSelectedItem as SelectedKitItemType).kit_size_id;

    if (currentSelectedItem && isSameSize) {
      newSelectedItems = selected_items.filter(
        (item) => item.kit_inventory_id !== id
      );
    } else if (currentSelectedItem && !isSameSize) {
      newSelectedItems[currentSelectedItemIndex].kit_size_id = sizeId;
    } else {
      const newItem: SelectedKitItemType = {
        kit_inventory_id: id,
      };
      if (sizeId) {
        newItem.kit_size_id = sizeId;
      }
      newSelectedItems.push(newItem);
    }
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          registrationData: {
            ...prevState.registrationData,
            selected_items: newSelectedItems,
          },
        };
      },
      () => {
        this.refreshSteps();
        this.forceUpdate();
      }
    );
  };

  getItemsListFromData = (kitsData: ApiKitAvailability): KitItemType[] => {
    let result: KitItemType[] = [];
    Object.keys(kitsData).forEach((key) => {
      const keysToSkip = [
        "Bike Size",
        "selected_delivery_lead_time_in_weekdays",
      ];
      if (keysToSkip.includes(key)) {
        return;
      }
      const itemsSet: ApiKitItemType[] = kitsData[key as KitCategory];
      itemsSet.forEach((item) => {
        result.push({
          ...item,
          type: key as KitCategory,
        });
      });
    });

    result = sortBy(result, ["display_order", "name"]);
    return result;
  };

  onPromotionCodeApplied = async () => {
    await this.fetchRegistrationData();
    await this.getSubscriptionPlans();
  };

  fetchRegistrationData = async () => {
    try {
      const { body } = await this.props.api.getRegistrationData();
      this.setState({
        registrationData: {
          ...this.state.registrationData,
          ...body.data,
          promotion_code: getDeepValue<string>(body, "data.promotion_code"),
        },
        registrationDataLoaded: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  displayDeliveryDateNotAvailableMessage = () => {
    const element = document.getElementById("delivery_date_err");
    if (element) {
      const minDeliveryDate = getMinDeliveryDate(this.state.deliveryLeadDays);
      element.innerHTML = `Due to high demand the earliest delivery date is ${minDeliveryDate.format(
        "DD/MM/YYYY"
      )}`;
    }
  };

  populateState = async () => {
    const { api, history } = this.props;
    const { body: userDataResult } = await api.getUserData();
    let newRegistrationState: RegistrationData = {};
    let newState: Partial<State> = {};
    let deliveryDateAvailable = true;

    if (
      userDataResult.signup_state !== "Complete" &&
      this.isCompletedApplication()
    ) {
      return history.replace("/account");
    }

    if (userDataResult.signup_state === "Complete") {
      newState.currentStepKey = this.isCompletedApplication()
        ? 8
        : getSignupStateKey(userDataResult.signup_state);
    } else {
      newState.currentStepKey = getSignupStateKey(userDataResult.signup_state);
      // Data for About You step
      newRegistrationState.height_cm = get(
        userDataResult,
        "rider.height_cm",
        null
      );
      newRegistrationState.gender = get(userDataResult, "rider.gender", null);
      newRegistrationState.confident = get(
        userDataResult,
        "rider.confident",
        null
      );

      // Data for the first 3 user info steps
      const { body: registrationDataResult } = await api.getRegistrationData();
      newRegistrationState = this.extractRegistrationData(
        { ...newRegistrationState, ...registrationDataResult.data },
        {
          body: userDataResult,
        }
      );

      // Data for payment step
      newRegistrationState.promotion_code = getDeepValue<string>(
        registrationDataResult,
        "data.promotion_code"
      );

      // Data for kit shop and delivery steps
      const { body: kitAvailabilityResult } = await api.getKitAvilability();
      newState.kitItems = this.getItemsListFromData(kitAvailabilityResult);
      let defaultBikeSizeId = undefined;
      const bikeSizes = getDeepValue<ApiKitItemType[]>(
        kitAvailabilityResult,
        "Bike Size"
      );
      if (!isEmpty(bikeSizes)) {
        defaultBikeSizeId = getDeepValue<number>(head(bikeSizes), "id");
      }
      newState.deliveryLeadDays = get(
        kitAvailabilityResult,
        "selected_delivery_lead_time_in_weekdays",
        0
      );
      newRegistrationState.selected_items = !isNil(defaultBikeSizeId)
        ? [{ kit_inventory_id: defaultBikeSizeId }]
        : [];

      // Data for kit shop
      const { body: kitOrderResult } = await api.getOrderSummary();
      const isOutdatedOrder =
        "id" in kitOrderResult &&
        moment(kitOrderResult.delivery_date).isBefore(moment());

      if (isOutdatedOrder) {
        newState.currentStepKey = getSignupStateKey("Delivery");
      } else {
        const kitSpec: ApiKitSpec | Record<string, never> =
          "kit_spec" in kitOrderResult ? kitOrderResult.kit_spec : {};

        const selected_items: SelectedKitItemType[] = Object.values(
          kitSpec
        ).map((item: ApiKitItemType) => {
          const selectedItem: SelectedKitItemType = {
            kit_inventory_id: item.id,
          };
          if (!isEmpty(item.sizes)) {
            selectedItem.kit_size_id = item.sizes[0].kit_size_id;
          }
          return selectedItem;
        });
        const selectedItemsInState = newRegistrationState.selected_items || [];

        let address_line_one,
          address_line_two,
          city,
          postcode,
          first_name,
          last_name,
          delivery_date = undefined;

        if ("id" in kitOrderResult) {
          address_line_one = kitOrderResult.address_line_one;
          address_line_two = kitOrderResult.address_line_two;
          city = kitOrderResult.city;
          postcode = kitOrderResult.postcode;
          first_name = kitOrderResult.first_name;
          last_name = kitOrderResult.last_name;
          delivery_date = kitOrderResult.delivery_date;
        }

        newRegistrationState = {
          ...newRegistrationState,
          selected_items: !isEmpty(selectedItemsInState)
            ? merge(selectedItemsInState, selected_items)
            : selected_items,
          delivery_address_line_one: address_line_one,
          delivery_address_line_two: address_line_two,
          delivery_city: city,
          delivery_postcode: postcode,
          delivery_first_name: first_name,
          delivery_last_name: last_name,
          delivery_date: delivery_date
            ? moment(delivery_date, "YYYY-MM-DD", false)
            : undefined,
        };
      }

      // Go back to Delivery screen when delivery date is not valid anymore
      if (userDataResult.signup_state === "PaymentRequired") {
        const { delivery_date } = newRegistrationState;
        if (
          delivery_date &&
          (delivery_date as Moment).isBefore(
            getMinDeliveryDate(newState.deliveryLeadDays)
          )
        ) {
          newState.currentStepKey = getSignupStateKey("Delivery");
          deliveryDateAvailable = false;
        }
      }
    }

    // Get delivery restriction
    const {
      body: { restricted_dates },
    } = await api.getDeliveryRestriction();
    newRegistrationState.restricted_dates = DISABLED_DAYS_DELIVERY;
    if (!isEmpty(restricted_dates)) {
      newRegistrationState.restricted_dates = uniq([
        ...restricted_dates,
        ...DISABLED_DAYS_DELIVERY,
      ]);
    }

    // Check if selected delivery date is blocked
    const { delivery_date } = newRegistrationState;
    const selectedDeliveryDateIsBlocked =
      delivery_date &&
      restricted_dates.find((date) =>
        (delivery_date as Moment).isSame(moment(date), "day")
      );

    if (selectedDeliveryDateIsBlocked) {
      deliveryDateAvailable = false;
    }

    newState = {
      ...newState,
      registrationData: newRegistrationState,
      registrationDataLoaded: true,
    };

    this.setState(
      (prevState) => ({
        ...prevState,
        ...newState,
      }),
      () => {
        this.trackDRREffectiveness();
        this.refreshSteps();
        this.forceUpdate(() => {
          if (!deliveryDateAvailable) {
            this.displayDeliveryDateNotAvailableMessage();
          }
        });
      }
    );
  };

  trackDRREffectiveness = () => {
    const {
      registrationData: { restricted_dates },
      deliveryLeadDays,
    } = this.state;

    const number_of_blocked_days = restricted_dates
      ? restricted_dates.length
      : 0;
    const selected_items_lead_time = deliveryLeadDays;
    const earliest_available_delivery_date = getEarliestDeliveryDate(
      deliveryLeadDays,
      restricted_dates
    );
    const earliest_lead_time_week_days = getWeekDaysFromNowToMinDeliveryDate(
      earliest_available_delivery_date,
      deliveryLeadDays,
      restricted_dates
    );
    const earliest_lead_time_calendar_days =
      earliest_available_delivery_date.diff(moment().startOf("day"), "day");

    const payload = {
      "number of blocked days": number_of_blocked_days,
      "selected items lead time": selected_items_lead_time,
      "earliest available delivery date":
        earliest_available_delivery_date.format(DATE_FORMAT.SERVER),
      "earliest lead time week days": earliest_lead_time_week_days,
      "earliest lead time calendar days": earliest_lead_time_calendar_days,
    };

    window.analytics.track(SEGMENT_EVENT.DELIVERY_AVAILABILITY_LOADED, payload);
  };

  async componentDidMount() {
    await this.populateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      this.state.currentStepKey !== prevState.currentStepKey &&
      this.state.currentStepKey === 6
    ) {
      void this.getSubscriptionPlans();
    }
  }

  getSubscriptionPlans = async () => {
    const res = await this.props.api.getSubscriptionPlans();
    const plans = getDeepValue<SubscriptionPlan[]>(res, "body") || [];
    this.setState(
      {
        subscriptionPlans: plans,
        selectedSubscriptionPlanIndex: !isEmpty(plans) ? 0 : null,
      },
      () => {
        this.refreshSteps();
        this.forceUpdate();
      }
    );
  };

  extractRegistrationData = (existingState: any, { body }: { body: any }) => {
    if (!body) {
      return;
    }
    let userUpdate = {},
      riderUpdate = {};
    if (body.user) {
      const { first_name, last_name } = body.user;
      if (first_name && first_name !== "" && last_name && last_name !== "") {
        userUpdate = { first_name, last_name };
      }
    }
    if (body.rider) {
      const company_name = body.rider.company_name || "NULL";
      const { birth_date, gender, phone_number_mobile, id } = body.rider;

      if (body.rider.homeplaces && body.rider.homeplaces[0]) {
        const { address_line_one, address_line_two, city, postcode } =
          body.rider.homeplaces[0];
        riderUpdate = {
          address_line_one,
          address_line_two,
          city,
          postcode,
          company_name: company_name !== "BCC" ? company_name : undefined,
          gender,
          birth_date,
          phone_number_mobile,
          riderId: id,
        };
      }
    }
    return {
      ...existingState,
      ...userUpdate,
      ...riderUpdate,
    };
  };

  cleanData = (data: any, stateData: any = {}, allowNulls = true) => {
    //combines data on this page with state data, and sets some defaults.
    //fields that are blanked on current page override previously stored values.
    const res: any = {};
    Object.keys({ ...data, ...stateData }).forEach((k) => {
      if (k in data) {
        //only copy if not blank or undefined
        if (
          data[k] !== undefined &&
          data[k] !== null &&
          (!data[k].trim || data[k].trim() !== "")
        ) {
          res[k] = data[k];
        } else if (allowNulls) {
          res[k] = null;
        }
      } else {
        res[k] = stateData[k];
      }
    });

    return res;
  };

  getRegData = () => {
    return { ...this.state.registrationData };
  };

  isCompany = () => {
    const { company_name } = this.state.registrationData;
    return (
      company_name !== null &&
      company_name !== undefined &&
      company_name !== "NULL"
    );
  };

  kitItems = (): KitItemType[] => {
    return this.state.kitItems;
  };

  nextStep = () => {
    let nextKey = this.state.currentStepKey + 1;
    this.analytics(nextKey);
    if (nextKey < this.steps.length) {
      this.stepInterceptor(this.steps[nextKey].onLoad);
    } else {
      nextKey = this.steps.length;
    }
    this.setState(
      {
        currentStepKey: nextKey,
      },
      () => {
        if (this.tabRef.current) {
          window.scrollTo(
            0,
            this.tabRef.current.offsetTop - this.tabRef.current.clientHeight
          );
        }
      }
    );
  };

  previousStep = () => {
    const nextKey = this.state.currentStepKey - 1;
    this.analytics(nextKey);
    this.stepInterceptor(this.steps[nextKey].onLoad);
    this.setState({ currentStepKey: nextKey });
  };

  //track event via gtm
  analytics(nextKey: number) {
    if (nextKey <= this.steps.length) {
      const step = this.steps[nextKey];
      if (step && step.trackingEvent) {
        gtm.event(step.trackingEvent());
      }
    }
  }

  getFormKey = (): DynamicFormKey | string =>
    (!this.done() && this.steps[this.state.currentStepKey].key) ||
    `Form_${this.state.currentStepKey}`;

  handleSubmit: SubmitHandler<RegistrationData> = (
    formData: RegistrationData,
    event: SyntheticEvent<HTMLElement>,
    additionalData: any
  ) => {
    const cleanedData = this.cleanData(formData);
    const allRegData = this.cleanData(formData, this.state.registrationData);
    const { currentStepKey, carryThrough } = this.state;
    const { onSubmit, onResponseData } = this.steps[currentStepKey];
    const key = this.getFormKey();
    this.setState({
      [key]: { ...formData, key },
      registrationData: allRegData,
    });

    this.stepInterceptor(
      this.steps[currentStepKey].beforeSubmit as any,
      formData
    );

    const goBack = additionalData && additionalData.direction === "prev";

    if (goBack) {
      return Promise.resolve(this.previousStep());
    }

    if (!onSubmit) {
      this.setState({ carryThrough: formData });
      return Promise.resolve(this.nextStep());
    }

    //call the step's onSubmit
    const fromOnSubmit = onSubmit(
      { formData: cleanedData, carryThrough, allData: allRegData },
      event,
      additionalData
    );
    return fromOnSubmit
      .then((response) => {
        this.extractDataFromResponse(
          onResponseData,
          response ? response.body : {}
        );
        return response;
      })
      .then(
        //return function that provides a handler to be executed after the form has finished processing results.
        (response) => {
          return { response, onSuccess: this.nextStep };
        }
      );
  };

  extractDataFromResponse = (handler: any, responseOrError: any) => {
    const data = get(
      responseOrError,
      "data",
      responseOrError && responseOrError.status >= 400 ? null : responseOrError
    );
    if (data && handler) {
      this.stepInterceptor(handler, data);
    }
    if (responseOrError && responseOrError.status >= 400) {
      throw responseOrError;
    }
    return responseOrError;
  };

  renderTabs = () => {
    const transformedStepTabs = stepTabs.map((stepTab) => {
      if (stepTab === "BIKE + KIT") {
        return "KIT";
      }
      return stepTab;
    });

    return (
      <>
        <div ref={this.tabRef} key={1} className="UserDetails-tab-wrapper">
          {transformedStepTabs.map((tab, i) => (
            <div
              key={`step_name_${i}`}
              className={`UserDetails-tab ${
                Math.min(this.state.currentStepKey, stepTabs.length - 1) === i
                  ? "tab-active"
                  : "tab-inactive"
              }`}
            >
              {i + 1}. {tab}
            </div>
          ))}
        </div>
        <div key={2}>
          <div className="UserDetails-progress-step-container">
            <div className="UserDetails-progress-step">
              <div
                className={`UserDetails-check-icon ${
                  this.state.currentStepKey >= 2 ? "check-active" : ""
                } `}
              />
              <span>Account</span>
            </div>
            <div className="UserDetails-progress-spacer-step" />
            <div className="UserDetails-progress-step">
              <div
                className={`UserDetails-check-icon ${
                  this.state.currentStepKey >= 3 ? "check-active" : ""
                } `}
              />
              <span>Delivery</span>
            </div>
            <div className="UserDetails-progress-spacer-step" />
            <div className="UserDetails-progress-step">
              <div
                className={`UserDetails-check-icon ${
                  this.state.currentStepKey >= 6 &&
                  this.state.currentStepKey < 8
                    ? "check-active"
                    : ""
                } `}
              />
              <span>Payment</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  onLoadedReferrerCode = (referrerCode: string) => {
    this.setState({ referrerCode });
  };

  renderForm = () => {
    const {
      currentStepKey,
      registrationData,
      registrationDataLoaded,
      fieldValues,
    } = this.state;
    if (currentStepKey > this.steps.length) {
      this.props.history.push("/account");
      return (
        <div className="UserDetails">
          <div className="UserDetails-content" />
        </div>
      );
    } else if (
      currentStepKey === this.steps.length &&
      !this.isCompletedApplication()
    ) {
      this.props.history.push("/completed-application");
      return null;
    }

    if (!registrationDataLoaded) {
      return null;
    }

    const updatedValues = fieldValues;
    let formData: RegistrationData = (this.state[this.getFormKey()] ||
      {}) as any;
    if (updatedValues) {
      formData = this.cleanData({ ...formData, ...updatedValues }, {}, false);
      this.setState({ fieldValues: {} });
    }
    formData = this.cleanData(formData, registrationData); //{ ...registrationData, ...formData}

    const currentStep = this.steps[currentStepKey];

    if (currentStepKey === this.steps.length) {
      return (
        <ApplicationThankYou
          api={this.props.api}
          onLoadedApplicationThankYou={this.props.api.setSignupCompleted}
          onLoadedReferrerCode={this.onLoadedReferrerCode}
          registrationData={this.state.registrationData}
          referrerCode={this.state.referrerCode}
        />
      );
    }

    const id = `SignUp${currentStepKey}`;

    return (
      <div className="UserDetails">
        <div className="UserDetails-content">
          <h1 className="UserDetails-header">{currentStep.header}</h1>
          {currentStep.subheader && (
            <h1 className="UserDetails-subheader">{currentStep.subheader}</h1>
          )}

          {currentStep.copy && (
            <p className="UserDetails-copy db">{currentStep.copy}</p>
          )}

          {currentStep.copyHtml && (
            <p
              className="UserDetails-copy db"
              dangerouslySetInnerHTML={{ __html: currentStep.copyHtml }}
            />
          )}

          <div className="UserDetails-form-wrapper">
            <Form
              key={id}
              id={id}
              className="UserDetails-form"
              fields={currentStep.fields}
              submitProps={currentStep.submitProps}
              onSubmit={this.handleSubmit}
              data={formData}
            />
          </div>
          {currentStepKey === 4 && <div style={{ height: "10rem" }} />}
        </div>
      </div>
    );
  };

  renderContent = () => {
    const { registrationDataLoaded } = this.state;
    if (!registrationDataLoaded) {
      return (
        <div className={"UserDetailsLoadingContainer"}>
          <ReactLoading
            type={"bubbles"}
            color={"#ff4074"}
            height={"4rem"}
            width={"4rem"}
          />
        </div>
      );
    }

    return (
      <>
        {this.renderTabs()}
        {this.renderForm()}
      </>
    );
  };

  render() {
    return (
      <div className="UserDetailsPage">
        <Header
          headerCopy={
            this.state.currentStepKey === this.steps.length
              ? "Welcome"
              : "Sign Up"
          }
          background={headerImage}
          backgroundPreview={headerImagePreview}
          backgroundMob={headerImageMob}
          backgroundMobPreview={headerImageMobPreview}
          className="half-height tc noBanner white-angle"
        />
        {this.renderContent()}
      </div>
    );
  }
}
export default UserDetails;
