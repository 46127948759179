/* eslint-disable @typescript-eslint/no-explicit-any */
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { every, isEmpty, round } from "lodash";
import moment from "moment";
import React, { CSSProperties, SyntheticEvent } from "react";
import { Link } from "react-router-dom";

import KitShop, { KitItemType } from "components/KitShop/KitShop";
import PromotionCodeSection from "components/PromotionCodeSection";
import SubscriptionPlans from "components/SubscriptionPlans";

import { gtm } from "../../Analytics";
import { Api } from "../../api";
import {
  OptionalRiderInfo,
  RegistrationData as ApiRegistrationData,
  SubscriptionPlan,
} from "../../api/types";
import { CreateStripeSubscriptionForm } from "../../components/CheckoutForm/";
import {
  DynamicFormField,
  SubmitResponse,
} from "../../components/Form/formTypes";
import {
  CYCLE_SCHEME_MODE,
  DATE_FORMAT,
  ENABLED_DAYS_DELIVERY,
  INVALID_POSTCODE_MESSAGE,
  PROMO_CODE_STORAGE_KEY,
} from "../../constants";
import questionMark2 from "../../static/images/icons/icon-questionmark2.png";
import { addMonth, addWeekdays, dateToMoment } from "../../utils/dateHelpers";
import * as postcodes from "../../utils/postcodes";
import {
  chain,
  checkNonEmpty,
  checkPostalCode,
  checkTicked,
} from "../../utils/validateHelpers";
import { CustomSubmitProps } from "./customSubmit";
import OrderSummary from "./OrderSummary";

type ValuesOf<T> = T[keyof T];

export type RegistrationData = ApiRegistrationData & {
  riderId?: string;
  restricted_dates?: string[];
};

export function getMinDeliveryDate(leadDays = 0) {
  return addWeekdays(moment(), leadDays);
}

const rowSeparator = (height?: CSSProperties["height"]) => [
  { html: <div style={{ height }} className="Form-row-separator" /> },
];

const deliveryAddressUdprn =
  (udprn: typeof postcodes.udprn) => async (p?: string) => {
    const x = await udprn(p);
    if (!x) {
      return;
    }
    return {
      delivery_findAddress: "",
      delivery_postcode: x.postcode,
      delivery_city: x.city,
      delivery_address_line_two: x.address_line_two,
      delivery_address_line_one: x.address_line_one,
    };
  };

type HomePlace = { name: "home1" } & Pick<
  RegistrationData,
  "address_line_one" | "address_line_two" | "city" | "postcode"
>;

//probably not the ideal place for this function... We only want to call it on create_rider api, not registration
const setPlacesData = (
  data: RegistrationData
): RegistrationData & {
  homeplaces: HomePlace[];
} => {
  const res: RegistrationData & {
    homeplaces: HomePlace[];
  } = Object.assign({ homeplaces: [] }, data);
  res.homeplaces = [
    {
      name: "home1",
      address_line_one: data.address_line_one || "",
      address_line_two: data.address_line_two || "",
      city: data.city,
      postcode: data.postcode,
    },
  ];
  return res;
};

type DynamicFormKeyBase = {
  AccountForm: "AccountForm";
  ContactForm: "ContactForm";
  Terms: "Terms";
  KitForm: "KitForm";
  DeliveryForm: "DeliveryForm";
  Payment: "Payment";
  AboutYouForm: "AboutYouForm";
};

export type DynamicFormKey = keyof DynamicFormKeyBase;

type DynamicFormRow = ValuesOf<
  {
    [FieldKey in keyof RegistrationData]: DynamicFormField<
      RegistrationData,
      RegistrationData[FieldKey],
      FieldKey
    >;
  }
>[];

export type FormState = {
  registrationData: RegistrationData;
  fieldValues: any;
  AccountForm?: {
    promotion_code?: string;
  };
};

export type DynamicForm<T extends SubmitResponse> = {
  name: string;
  trackingEvent: () => { event: string };
  onLoad?: (state: FormState, data?: unknown) => Partial<FormState>;
  key: DynamicFormKey;
  header: string;
  subheader?: string;
  copy?: string;
  copyHtml?: string;
  beforeSubmit?: (
    state: FormState,
    formData: {
      promotion_code?: string;
    }
  ) => unknown;
  fields: DynamicFormRow[];
  submitProps?: Omit<CustomSubmitProps, "onSubmit">;
  onSubmit?: (
    data: {
      formData: RegistrationData;
      carryThrough: Pick<RegistrationData, "selected_items">;
      allData: RegistrationData;
    },
    e: SyntheticEvent<HTMLElement>,
    options: {
      direction: "prev" | "next";
    }
  ) => Promise<T | void>;
  onResponseData?: (state: unknown, data: unknown) => unknown;
};

type CheckoutSubmitHandler = (
  e: SyntheticEvent<HTMLElement>
) => Promise<SubmitResponse | void>;

// NOTE: this needs to be a global, otherwise the handler used for a rendered component can be lost.
let getCheckoutSubmit: undefined | (() => CheckoutSubmitHandler);

const registerCheckoutSubmit = (f: CheckoutSubmitHandler) => {
  getCheckoutSubmit = () => f;
};

const earliestAvailDelDate = moment("2020-08-17");

const formFields = (
  onNext: () => void,
  api: Api,
  getRegData: () => RegistrationData,
  isCompany: () => boolean,
  kitItems: () => KitItemType[],
  deliveryLeadDays: number,
  subscriptionPlans: SubscriptionPlan[],
  selectedSubscriptionPlanIndex = 0,
  onSubscriptionPlanSelected: (index: number) => void,
  onSelectKitItem: (id: number, sizeId?: number) => void,
  stripe: Promise<Stripe | null>,
  onPromotionCodeApplied: () => void,
  discountAmount = 0,
  totalAmount = 0,
  onSetDiscountAmount: (discountAmount: number) => void,
  onTotalChange: (amount: number) => void
): DynamicForm<SubmitResponse>[] => {
  let minDeliveryDate = getMinDeliveryDate(deliveryLeadDays);
  const delDateErrorMsg = `Due to high demand the earliest delivery date is ${minDeliveryDate.format(
    "DD/MM/YYYY"
  )}`;
  let delDateMsg = undefined;
  if (minDeliveryDate.isBefore(earliestAvailDelDate)) {
    minDeliveryDate = earliestAvailDelDate;
    delDateMsg = delDateErrorMsg;
  }
  const maxDeliveryDate = addMonth(moment(), 4);
  const maxDeliveryDateErrorMsg = `The latest delivery date is ${maxDeliveryDate.format(
    DATE_FORMAT.SHORT
  )}`;

  function createRider(allData: RegistrationData) {
    const asMoment = dateToMoment(allData.birth_date);
    return api.createRider({
      ...setPlacesData(allData),
      ...allData,
      birth_date: asMoment ? asMoment.format("YYYY-MM-DD") : undefined,
    });
  }

  function updateRiderInfo(info: OptionalRiderInfo) {
    const { riderId } = getRegData();
    if (isEmpty(info) || !riderId) {
      return;
    }
    return api.updateRiderInfo(riderId, info);
  }
  const bikeAndKitTitle = "Kit";

  const selectedSubscriptionPlanId = !isEmpty(subscriptionPlans)
    ? subscriptionPlans[selectedSubscriptionPlanIndex].id
    : undefined;

  const isMonthlyPlan =
    !!selectedSubscriptionPlanId &&
    subscriptionPlans[selectedSubscriptionPlanIndex].term_length_in_months <= 1;

  const finalAmount =
    isMonthlyPlan && selectedSubscriptionPlanId
      ? round(
          Math.max(
            0,
            Number.parseFloat(
              subscriptionPlans[selectedSubscriptionPlanIndex].amount_to_pay_now
            ) - discountAmount
          ),
          2
        )
      : undefined;
  return [
    {
      name: "Account",
      trackingEvent: () => {
        return { event: `Signup - 1. ACCOUNT` };
      },
      key: "AccountForm",
      header: "1. Account",
      copy: CYCLE_SCHEME_MODE
        ? `Please enter your details to create an account, adding the redemption code provided by Cyclescheme in the promo code box below.`
        : `Please enter your details to create an account. Make sure to put in your company's promo code`,
      beforeSubmit: (state, formData) => {
        const { registrationData, AccountForm } = state;

        const compVersion = AccountForm
          ? AccountForm.promotion_code
          : registrationData.promotion_code;
        //has promo code changed?
        if (compVersion === formData.promotion_code) {
          return {};
        }
        //promo code has changed.
        //clear the company sections for next page; from both the form data & reg data.
        const newValues = { company_name: null, national_insurance: null };
        return {
          fieldValues: { ContactForm: newValues },
          registrationData: newValues,
        };
      },
      fields: [
        [
          {
            name: "nickname",
            type: "text",
            label: "Username",
            tooltip:
              "Please enter a username that will be visible publicly on leaderboards. This does not need to be personally identifiable. If you choose to leave this blank your information will not be shared publicly.",
          },
        ],
        [
          {
            name: "birth_date",
            label: "Date of Birth",
            tooltip: "You need to be 18 years or older to become a Buzzbiker.",
            type: "text",
            placeholder: "DD/MM/YYYY",
            inputMask: {
              mask: "99/99/9999",
              maskPlaceholder: "DD/MM/YYYY",
              inputMode: "numeric",
            },
            validate: chain([
              checkNonEmpty(),
              (v) => {
                const date = dateToMoment(v);
                if (date === null || !date.isValid()) {
                  return "Please input a valid date in the format DD/MM/YYYY";
                }
                const age = moment.duration(moment().diff(date)).asYears();

                if (age < 18) {
                  return "You must be over 18 to become a Buzzbike member";
                }

                const minDate = dateToMoment("1900-01-01");
                if (minDate !== null && date.isBefore(minDate)) {
                  return "Please input a date from 01/01/1900";
                }
                return null;
              },
            ]),
          },
        ],
        [
          {
            name: "promotion_code",
            label: CYCLE_SCHEME_MODE ? (
              <span className="pink">Redemption Code</span>
            ) : (
              <span className="pink">Promo Code</span>
            ),
            type: "text",
            tooltip: CYCLE_SCHEME_MODE
              ? "Add the redemption code provided by Cyclescheme in the promo code box below."
              : "Add your company's promo code in the promo code box below.",
            tooltipImage: questionMark2,
            defaultValue: localStorage.getItem(PROMO_CODE_STORAGE_KEY) || "",
            props: {
              autoComplete: "off",
            },
            validate: (value: string) => {
              if (isEmpty(value)) {
                return "Please input your company's promo code";
              }
              return null;
            },
          },
          { html: <div /> },
        ],
        rowSeparator(),
      ],
      submitProps: {
        hasPrev: false,
      },
      onSubmit: (data) => {
        const { formData } = data;
        const asMoment = dateToMoment(formData.birth_date);
        return api.putRegistrationData({
          ...formData,
          birth_date: asMoment ? asMoment.format("YYYY-MM-DD") : undefined,
          ...{ company_name: null, national_insurance: null },
        });
      },
      onResponseData: (state, data: { company_name: string }) => {
        const { company_name } = data;

        //promo code has changed.
        //populate the company name for next page; from both the form data & reg data.

        return {
          fieldValues: { ContactForm: { company_name } },
          registrationData: { company_name },
        };
      },
    } as DynamicForm<SubmitResponse>,

    {
      name: "Contact",
      trackingEvent: () => {
        return { event: `Signup - 2. CONTACT` };
      },

      key: "ContactForm",
      header: "2. Your contact details",
      copyHtml: `<p>Share your home address and phone
      number so we can send your prizes
      and service your ride.</p><p>Please note Buzzbike is currently London-based only. Want Buzzbike in your city? <a href="mailto:hello@buzzbike.cc?subject=Buzzbike in my city">Let us know!</a></p>`,
      fields: [
        [
          {
            name: "findAddress",
            label: "Find Address",
            loadOptions: (inputValue: string) =>
              postcodes.autocomplete(inputValue).then((options) =>
                options.map((o) => ({
                  label: o.suggestion,
                  value: o.udprn,
                }))
              ),
            updateOthers: postcodes.udprn,
            props: { externalLabel: true },
            isSearchable: true,
          },
        ],

        [
          {
            name: "address_line_one",
            label: "Address Line 1",
            type: "text",
            validate: checkNonEmpty(),
          },
        ],
        [{ name: "address_line_two", label: "Address Line 2", type: "text" }],
        [
          {
            name: "city",
            label: "City",
            type: "text",
            validate: checkNonEmpty(),
          },
        ],
        [
          {
            name: "postcode",
            label: "Postcode",
            validate: chain([
              checkNonEmpty(),
              checkPostalCode(INVALID_POSTCODE_MESSAGE),
            ]),
          },
        ],
        [
          {
            name: "phone_number_mobile",
            label: "Mobile Number",
            type: "tel",
            tooltip:
              "We’ll only call you to provide quality customer support when you need it.",
            validate: checkNonEmpty(),
          },
          { html: <div /> },
        ],
        rowSeparator("1rem"),

        [
          {
            condition: isCompany,
            html: (
              <h1 className="tl" style={{ fontSize: "1.75rem" }}>
                {CYCLE_SCHEME_MODE ? "Provider" : "Company"}
              </h1>
            ),
          },
        ],
        [
          {
            condition: isCompany,
            name: "company_name",
            label: CYCLE_SCHEME_MODE ? "Provider Name" : "Company Name",
            type: "text",
            disabled: "disabled",
            validate: checkNonEmpty(),
          },
        ],
        [
          {
            condition: () => {
              return !CYCLE_SCHEME_MODE && isCompany();
            },
            name: "national_insurance",
            label: "Payroll Number",
            type: "text",
            validate: checkNonEmpty(),
          },
          {
            condition: () => {
              return !CYCLE_SCHEME_MODE && isCompany();
            },
            html: <div />,
          },
        ],
        rowSeparator(),
      ],
      onSubmit: ({ formData }) => {
        return api.putRegistrationData(formData);
      },
    } as DynamicForm<SubmitResponse>,
    {
      name: "Terms",
      trackingEvent: () => {
        return { event: `Signup - 3. TERMS` };
      },

      key: "Terms",
      header: "3. Terms of use",
      copy: `Please read carefully through our
      Terms of Use and Privacy Policy before
      continuing. You can refer back to them
      later on our site.`,
      fields: [
        [
          {
            name: "agreement",
            type: "checkbox",
            label: (
              <span>
                I have read and agree to the{" "}
                <Link
                  to="/privacy-policy"
                  target={"_blank"}
                  style={{ textDecoration: "underline" }}
                >
                  Privacy policy
                </Link>{" "}
                and{" "}
                <Link
                  to="/documents/terms.pdf"
                  target={"_blank"}
                  style={{ textDecoration: "underline" }}
                >
                  Terms of use
                </Link>
              </span>
            ),
            validate: checkTicked(
              "You need to read and agree to the terms of use"
            ),
          },
        ],
        [
          {
            name: "commercial-agreement",
            type: "checkbox",
            label: (
              <span>
                I acknowledge that Buzzbike cannot be used for commercial
                purposes (e.g. delivery services).
              </span>
            ),
            validate: checkTicked(
              "You need to agree to not use the Buzzbike for commercial purposes"
            ),
          },
        ],
        [{ html: <div style={{ height: "1rem" }} /> }],
      ],
      onSubmit: ({ allData }) => {
        return createRider(allData);
      },
      onResponseData: (state, data: { rider: any; user: any }) => {
        const { rider } = data;

        return {
          registrationData: { riderId: rider.id },
        };
      },
    } as DynamicForm<SubmitResponse>,
    {
      name: "About You",
      trackingEvent: () => {
        return { event: `Signup - 3.1 About You` };
      },
      key: "AboutYouForm",
      header: "4. About You",
      copy: "Getting to know you so we can provide a more personal service",
      fields: [
        [
          {
            type: "height",
            name: "height_cm",
            label: "How tall are you?",
            validate: (value: string) => {
              const number = parseFloat(value);
              if (value && (isNaN(number) || number <= 30 || number >= 240)) {
                return "Please enter a valid height";
              } else if (!value || isEmpty(value.trim())) {
                return "We use your height to assess our range of frame options";
              }
              return null;
            },
          },
        ],
        [
          {
            name: "confident",
            label: "How confident a city cyclist are you?",
            options: [
              {
                label: "Select...",
                value: null,
                disabled: true,
              },
              {
                label: "Never cycled before",
                value: "never_before",
              },
              {
                label: "Beginner",
                value: "beginner",
              },
              {
                label: "Moderately confident",
                value: "moderate",
              },
              {
                label: "Experienced rider",
                value: "experienced",
              },
            ],
            validate: (value: string) => {
              if (isEmpty(value)) {
                return "We use your riding confidence to help you get the most out of your Buzzbike";
              }
              return null;
            },
          },
        ],
        [
          {
            name: "gender",
            label: "What is your gender?",
            options: [
              {
                label: "Select...",
                value: null,
                disabled: true,
              },
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "other/non-binary", label: "Other / Non Binary" },
            ],
            validate: (value: string) => {
              if (isEmpty(value)) {
                return "We use your gender to assess our range of frame options";
              }
              return null;
            },
          },
        ],
        rowSeparator(),
      ],
      submitProps: {
        prevCopy: "Prev",
        nextCopy: "Next",
        leftArrow: true,
        rightArrow: true,
      },
      onSubmit: ({ allData }) => {
        const { gender, height_cm, confident } = allData;
        const riderInfo: OptionalRiderInfo = { gender, height_cm, confident };
        if (every(Object.values(riderInfo), isEmpty)) {
          return Promise.resolve();
        }
        Object.keys(riderInfo).forEach((key) => {
          if (isEmpty(riderInfo[key as keyof OptionalRiderInfo])) {
            delete riderInfo[key as keyof OptionalRiderInfo];
          }
        });
        return updateRiderInfo(riderInfo);
      },
    } as DynamicForm<SubmitResponse>,
    {
      name: bikeAndKitTitle,
      trackingEvent: () => {
        return { event: `Signup - 4. BIKE + KIT` };
      },

      key: "KitForm",
      header: `5. ${bikeAndKitTitle}`,
      copy: "Please select any additional kit",
      fields: [
        [
          {
            html: <p className={`tl f3 mb3`}>Buzzbike Kit Shop</p>,
          },
        ],
        [
          {
            html: (
              <KitShop
                data={kitItems()}
                selectedItems={getRegData().selected_items}
                onSelectItem={onSelectKitItem}
              />
            ),
          },
        ],
        rowSeparator(),
      ],
      submitProps: {
        hasPrev: (getRegData().first_name || "").trim().length > 0,
      },
    },
    {
      name: "Delivery",
      trackingEvent: () => {
        return { event: `Signup - 5. DELIVERY` };
      },

      onLoad: (state) => {
        //default to home address etc.
        const {
          address_line_one,
          address_line_two,
          city,
          postcode,
          first_name,
          last_name,
          delivery_address_line_one,
          delivery_address_line_two,
          delivery_city,
          delivery_postcode,
          delivery_last_name,
          delivery_first_name,
          delivery_date,
        } = state.registrationData;

        const regData = {
          ...state.registrationData,
          ...{
            delivery_address_line_one:
              delivery_address_line_one || address_line_one,
            delivery_address_line_two:
              delivery_address_line_two || address_line_two,
            delivery_city: delivery_city || city,
            delivery_postcode: delivery_postcode || postcode,
            delivery_first_name: delivery_first_name || first_name,
            delivery_last_name: delivery_last_name || last_name,
            delivery_date,
          },
        };

        return { registrationData: regData };
      },
      key: "DeliveryForm",
      header: "6. Delivery",
      copy: `Your Buzzbike and kit can be delivered to your home or work address.`,
      fields: [
        [{ html: <p className="tl f3 mb3">Delivery address</p> }],
        [
          {
            name: "delivery_first_name",
            label: "First Name",
            type: "text",
            validate: checkNonEmpty(),
          },
          {
            name: "delivery_last_name",
            label: "Last Name",
            type: "text",
            validate: checkNonEmpty(),
          },
        ],
        [
          {
            name: "delivery_findAddress",
            label: "Find Address",
            loadOptions: (inputValue: string) =>
              postcodes.autocomplete(inputValue).then((options) =>
                options.map((o) => ({
                  label: o.suggestion,
                  value: o.udprn,
                }))
              ),
            props: { externalLabel: true },
            isSearchable: true,
            updateOthers: deliveryAddressUdprn(postcodes.udprn),
          },
        ],
        [
          {
            name: "delivery_address_line_one",
            label: "Address Line 1",
            type: "text",
            validate: checkNonEmpty(),
          },
        ],
        [
          {
            name: "delivery_address_line_two",
            label: "Address Line 2",
            type: "text",
          },
        ],
        [
          {
            name: "delivery_city",
            label: "City",
            validate: checkNonEmpty(),
            style: { textTransform: "capitalize" },
          },
        ],
        [
          {
            name: "delivery_postcode",
            label: "Postcode",
            validate: chain([
              checkNonEmpty(),
              checkPostalCode(INVALID_POSTCODE_MESSAGE),
            ]),
          },
        ],
        [
          {
            html: (
              <p className="f3 mt4 mb3 tl">Select preferred delivery date</p>
            ),
          },
        ],
        [
          {
            name: "delivery_date",
            label: delDateMsg ? (
              <span>
                Select date
                <br />
                <span className="delDateMsg">{delDateMsg}</span>{" "}
              </span>
            ) : (
              "Select date"
            ),
            type: "date",
            tooltip:
              "Deliveries are made Monday - Friday 9:30am - 5:30pm excluding public holidays.",
            props: {
              min: minDeliveryDate.format(DATE_FORMAT.SERVER),
              disabledDays: getRegData().restricted_dates || [],
              max: maxDeliveryDate.format(DATE_FORMAT.SERVER),
              enabledDays: ENABLED_DAYS_DELIVERY,
            },
            placeholder: `DD/MM/${moment(
              minDeliveryDate,
              DATE_FORMAT.SERVER
            ).format("YYYY")}`,
            disableWeekend: true,
            validate: chain([
              checkNonEmpty(),
              (v) => {
                const date = dateToMoment(v);
                if (!date || !date.isValid()) {
                  return "Please input a valid date in the format DD/MM/YYYY";
                }
                if (date.isBefore(minDeliveryDate)) {
                  return delDateErrorMsg;
                }
                if (date.isAfter(maxDeliveryDate)) {
                  return maxDeliveryDateErrorMsg;
                }
                if (
                  (date.day() === 0 || date.day() === 6) &&
                  !ENABLED_DAYS_DELIVERY.includes(
                    date.format(DATE_FORMAT.SERVER)
                  )
                ) {
                  return "Please do not select weekend days";
                }

                const restrictedDates = getRegData().restricted_dates || [];
                const dayIsBlocked = !!restrictedDates.find((disabledDay) =>
                  moment(disabledDay, DATE_FORMAT.SERVER).isSame(date, "day")
                );
                if (dayIsBlocked) {
                  return "Please select another day";
                }

                return null;
              },
            ]),
          },
          { html: <div /> },
        ],
        [
          {
            html: (
              <p
                className="tl lh-copy"
                style={{
                  textAlign: "justify",
                  marginBottom: "1rem",
                }}
              >
                We’ll email you the day before your delivery date with a 2 hour
                time slot.
              </p>
            ),
          },
        ],
        [{ html: <div style={{ height: "3rem" }} /> }],
      ],
      submitProps: {
        hasPrev: (getRegData().first_name || "").trim().length > 0,
      },
      onSubmit: ({ formData, carryThrough, allData }) => {
        const {
          delivery_address_line_one,
          delivery_address_line_two,
          delivery_city,
          delivery_postcode,
          delivery_first_name,
          delivery_last_name,
        } = formData;

        const { selected_items } = {
          ...allData,
          ...carryThrough,
        };

        const asMoment = dateToMoment(formData.delivery_date);
        const data = {
          address_line_one: delivery_address_line_one,
          address_line_two: delivery_address_line_two,
          city: delivery_city,
          postcode: delivery_postcode,
          first_name: delivery_first_name,
          last_name: delivery_last_name,
          delivery_date: asMoment ? asMoment.format("YYYY-MM-DD") : undefined,
          selected_items,
        };
        return api.scheduleDelivery({ ...(data as any) });
      },
    } as DynamicForm<SubmitResponse>,
    {
      name: "Payment",
      trackingEvent: () => {
        return { event: `Signup - 6. PAYMENT` };
      },

      key: "Payment",
      header: "7. Payment",
      copy: CYCLE_SCHEME_MODE
        ? "Review your order and set up payment"
        : "Select plan, review your order and set up payments.",
      fields: [
        [
          {
            html: (
              <SubscriptionPlans
                selectedIndex={selectedSubscriptionPlanIndex}
                onSelectedIndexChange={onSubscriptionPlanSelected}
                plans={subscriptionPlans}
              />
            ),
          },
        ],
        [{ html: <p className="tl f3 mb3">Order summary</p> }],
        [
          {
            html: (
              <OrderSummary
                api={api}
                selectedSubscriptionPlan={
                  subscriptionPlans[selectedSubscriptionPlanIndex]
                }
                promotionCode={getRegData().promotion_code}
                onSetDiscountAmount={onSetDiscountAmount}
                onTotalChange={onTotalChange}
              />
            ),
          },
        ],
        [
          {
            html: (
              <PromotionCodeSection
                api={api}
                onApplied={onPromotionCodeApplied}
                promotionCode={getRegData().promotion_code}
                isMonthlyPlan={isMonthlyPlan}
                finalAmount={finalAmount}
              />
            ),
          },
        ],
        [{ html: <p className="tl f3 mt5 mb3">Payment Method</p> }],
        [
          {
            renderHtml: (state?: RegistrationData) => {
              if (!state) {
                return <></>;
              }
              return (
                <Elements stripe={stripe}>
                  <CreateStripeSubscriptionForm
                    subscriptionPlanId={selectedSubscriptionPlanId}
                    totalAmount={Math.round(totalAmount)}
                    data={state}
                    onCreated={registerCheckoutSubmit}
                    onSuccess={() => {
                      gtm.event({
                        event: `Signup - 7. COMPLETED`,
                      });
                      onNext();
                    }}
                    displayButton={false}
                    showError={false}
                    disablePaymentRequestButton={false}
                  />
                </Elements>
              );
            },
          },
        ],
        rowSeparator(),
      ],
      submitProps: {
        prevCopy: "Prev",
        nextCopy: "Pay Now",
        leftArrow: true,
        rightArrow: false,
      },
      onSubmit: (formData, e, { direction }) => {
        if (getCheckoutSubmit && direction === "next") {
          const fn = getCheckoutSubmit();
          return fn(e);
        } else if (direction === "next") {
          throw new Error("Checkout submission function not set!");
        } else {
          return Promise.resolve({});
        }
      },
    },
  ];
};

export const stepTabs = [
  "ACCOUNT",
  "CONTACT",
  "TERMS",
  "ABOUT YOU",
  "BIKE + KIT",
  "DELIVERY",
  "PAYMENT",
];

export default formFields;
