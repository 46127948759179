import React from "react";
import { match, Redirect, Route, RouteProps } from "react-router-dom";
import { getDeepValue } from "utils";

interface Props extends RouteProps {
  computedMatch?: match<{ tab?: string }>;
  authorized: boolean;
}

function AuthRoute(props: Props) {
  const { authorized, computedMatch, render } = props;

  return (
    <Route
      {...props}
      path={getDeepValue<string>(computedMatch, "url")}
      render={({ location, ...rest }) =>
        authorized ? (
          render && render({ location, ...rest })
        ) : (
          <Redirect
            to={{
              pathname: "/log_in",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default AuthRoute;
