import "./index.scss";

import { useThrottledFn, useWindowResize } from "beautiful-react-hooks";
import React, { useEffect, useState } from "react";

import Button from "../Button";
import ButtonLink from "../ButtonLink";
import PinkBanner from "../PinkBanner/PinkBanner";
import ProgressiveImage from "../ProgressiveImage";

const isMob = (innerWidth: number) => innerWidth < 769;

type Props = {
  background: string;
  backgroundMob: string;
  bgClassName?: string;
  home?: boolean;

  togglePopUp?: () => void;
  buttonText?: React.ReactChild;
  linkTo?: string;
  headerCopy?: string;

  backgroundPreview?: string;
  backgroundMobPreview?: string;
  className?: string;

  noBanner?: boolean;
};

const Header = (props: Props) => {
  const [isMobile, setIsMobile] = useState(true);

  useWindowResize(
    useThrottledFn(() => {
      setIsMobile(isMob(window.innerWidth));
    })
  );

  useEffect(() => {
    setIsMobile(isMob(window.innerWidth));
  }, []);

  const headline = props.headerCopy && (
    <h2
      className="f-headline ttu white"
      dangerouslySetInnerHTML={{
        __html: props.headerCopy,
      }}
    />
  );

  const button = props.buttonText && props.linkTo && (
    <ButtonLink
      to={props.linkTo}
      className="w-100 w-auto-ns tc tl-ns bg-pink"
      text={props.buttonText}
    />
  );

  const toggler = props.togglePopUp ? (
    <Button
      className="w-100 w-auto-ns tc tl-ns bg-pink"
      text="Get Started"
      onClick={props.togglePopUp}
    />
  ) : (
    ""
  );
  return (
    <div className={`${props.className || ""} Header`}>
      <div className="image-wrapper flex items-end items-center-l vh-100">
        {isMobile ? (
          <ProgressiveImage
            className={`${props.bgClassName || ""} header-bg `}
            preview={props.backgroundMobPreview}
            image={props.backgroundMob}
            alt=""
          />
        ) : (
          <ProgressiveImage
            className={`${props.bgClassName || ""} header-bg `}
            image={props.background}
            preview={props.backgroundPreview}
            alt=""
          />
        )}
        {props.headerCopy ||
          props.linkTo ||
          (props.togglePopUp && (
            <div className="pb5 pb0-l mw9 w-90 center header__content">
              {headline}
              {button}
              {toggler}
            </div>
          ))}
        {props.noBanner === undefined || props.noBanner ? null : <PinkBanner />}
      </div>
    </div>
  );
};

export default Header;
