import { History } from "history";
import { isArray, isEmpty } from "lodash";
import queryString from "query-string";
import { useEffect } from "react";

import { PROMO_CODE_STORAGE_KEY, UTM_PARAMS_STORAGE_KEY } from "../constants";

export interface ParsedQuery<T = string> {
  [key: string]: T | T[] | undefined;
}

function useTracking(history: History) {
  useEffect(() => {
    const { location } = history;
    const queryParams = queryString.parse(location.search) as ParsedQuery;

    function storeUtmParams(queryParams: ParsedQuery) {
      const utmParams = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ];
      const utmParamsValue: {
        [key in typeof utmParams[number]]?: string;
      } = {};
      utmParams.forEach((param) => {
        if (Object.prototype.hasOwnProperty.call(queryParams, param)) {
          const fromUrl = queryParams[param];
          utmParamsValue[param] =
            isArray(fromUrl) && !isEmpty(fromUrl)
              ? fromUrl[0]
              : (fromUrl as string);
        }
      });

      if (!isEmpty(utmParamsValue)) {
        localStorage.setItem(
          UTM_PARAMS_STORAGE_KEY,
          JSON.stringify(utmParamsValue)
        );
      }
    }

    function storePromoCodeParam(queryParams: ParsedQuery) {
      const promoCodeParam = "promo_code";
      if (Object.prototype.hasOwnProperty.call(queryParams, promoCodeParam)) {
        const fromUrl = queryParams[promoCodeParam];
        localStorage.setItem(
          PROMO_CODE_STORAGE_KEY,
          isArray(fromUrl) && !isEmpty(fromUrl)
            ? fromUrl[0]
            : (fromUrl as string)
        );
      }
    }

    storeUtmParams(queryParams);
    storePromoCodeParam(queryParams);
  });
}

export default useTracking;
