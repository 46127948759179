import React from "react";

import { SvgComponentProps } from "./types";

export default ({ width, height, className, fill }: SvgComponentProps) => (
  <svg
    width={width || "6px"}
    height={height || "4px"}
    viewBox="0 0 600 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className || ""}
  >
    <g id="Page-2knkbnvtxgtcvhbjk8">
      <polygon points="0,400 300,0 600,400" fill={fill} />
    </g>
  </svg>
);
