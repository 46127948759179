import React, { useCallback } from "react";

import Tick from "../../../../components/svg/tick";
import styles from "./ComparisionTable.module.scss";

export interface ComparisionTableProps {
  data: {
    [key: string]: string[] | boolean[];
  };
  onContinue: () => void;
  onCancel: () => void;
}

function ComparisionTable(props: ComparisionTableProps) {
  const { data, onContinue, onCancel } = props;

  const renderBooleanRow = useCallback((value: boolean, color: string) => {
    return value ? (
      <Tick stroke={color} className={styles.yesIcon} />
    ) : (
      <img
        src={require("../../../../static/images/icons/icon-close.png")}
        className={styles.noIcon}
      />
    );
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.headerTitle}>Bike purchase vs. Buzzbike</h1>
        <span className={styles.headerSubTitle}>
          Before you make the investment to purchase your own bike we wanted to
          remind you of the hassle and cost involved
        </span>
      </div>

      <div className={styles.table}>
        <div className={styles.featureCol}>
          {Object.keys(data).map((key) => (
            <span key={key} className={styles.row}>
              {key}
            </span>
          ))}
        </div>
        <div className={styles.bikePurchaseCol}>
          <span className={styles.curvedHeader}>Bike Purchase</span>
          {Object.keys(data).map((key, index) => {
            const value = data[key][0];
            if (typeof value === "boolean") {
              return (
                <div className={styles.row} key={`bikePurchaseCol-${index}`}>
                  {renderBooleanRow(value, "white")}
                </div>
              );
            }
            return (
              <span key={key} className={`${styles.row} tc`}>
                {value}
              </span>
            );
          })}
        </div>
        <div className={styles.buzzbikeCol}>
          <div className={styles.curvedHeader}>
            <img
              className={styles.buzzbikeLogo}
              src={require("../../../../static/images/logo-sm--pink.png")}
            />
          </div>
          {Object.keys(data).map((key, index) => {
            const value = data[key][1];

            if (typeof value === "boolean") {
              return (
                <div className={styles.row} key={`buzzbikeCol-${index}`}>
                  {renderBooleanRow(value, "white")}
                </div>
              );
            }
            return (
              <span key={key} className={`${styles.row} tc`}>
                {value}
              </span>
            );
          })}
        </div>
        <img
          src={require("../../../../static/images/woman-with-bike-no-bg.svg")}
          className={styles.bottomImage}
        />
      </div>

      <div className={styles.bottomNotes}>
        <span className={styles.note}>
          * Estimated annual cost of maintenance and parts, cyclescheme.co.uk
        </span>
        <span className={styles.note}>
          ** Estimated annual cost of insurance, cycleinsurance.co.uk
        </span>
      </div>

      <div className={styles.bottomButtons}>
        <div
          className={`${styles.button} ${styles.continueButton}`}
          onClick={onCancel}
        >
          Continue Subscription
        </div>
        <div
          className={`${styles.button} ${styles.cancelButton}`}
          onClick={onContinue}
        >
          I still want to cancel
        </div>
      </div>
    </div>
  );
}

export default ComparisionTable;
