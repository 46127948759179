import * as Sentry from "@sentry/react";
import { useEffect, useRef, useState } from "react";

import { Api } from "../api";
import { ApiUser } from "../api/types";
import { useAuthDetails } from "../components/auth";

// TODO use provider/context to make this available throughout the app
const useCurrentUser = (api: Api) => {
  const auth = useAuthDetails();
  const apiRef = useRef(api);

  const isLoading = auth.loading;
  const userId = auth.loading ? null : auth.userId;
  const accessToken = auth.loading ? null : auth.accessToken;

  const [user, setUser] = useState<ApiUser | null>(null);

  const [userHasBeenLoaded, setUserHasBeenLoaded] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (isLoading) {
      // do nothing
    } else if (userId && accessToken) {
      // user is logged in
      const loadUser = async () => {
        const {
          body: { user },
        } = await apiRef.current.getUserData();
        if (mounted) {
          setUser(user);
          setUserHasBeenLoaded(true);
        }
      };
      loadUser();
    } else {
      // user is logged out
      if (mounted) {
        setUser(null);
        setUserHasBeenLoaded(true);
      }
    }
    return () => {
      mounted = false;
    };
  }, [userId, accessToken, isLoading]);

  return { isLoading: !userHasBeenLoaded, currentUser: user };
};

export const useSentry = (api: Api) => {
  const { isLoading, currentUser } = useCurrentUser(api);
  const email = currentUser ? currentUser.email : null;
  const userId = currentUser ? currentUser.id : null;

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (userId && email) {
      // user is logged in
      Sentry.setUser({ id: userId, email });
    } else {
      // user is logged out
      Sentry.setUser(null);
    }
  }, [userId, email, isLoading]);
};
