import { History, LocationDescriptor } from "history";
import React, { FC, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

import {
  Api,
  isSignupComplete,
  removeAccessToken,
  setContinueSignup,
  storeAccessToken,
  storeName,
  storeTemporaryAccessToken,
} from "../../api";
import { ApiLogin, assertRiderLoaded } from "../../api/types";
import { useAuthDetails } from "../../components/auth";
import Form from "../../components/Form";
import { InputFormField, SubmitHandler } from "../../components/Form/formTypes";
import Header from "../../components/Header";
import headerImage from "../../static/images/img-header--signup-n.jpg";
import headerImagePreview from "../../static/images/thumbnails/img-header--signup-n.jpg";
import { saveLoginIdentity, trackLoginSuccess } from "../../utils";
import SignUpStyles from "../SignUp.module.scss";
import styles from "./Login.module.scss";

const headerImageMob = headerImage;
const headerImageMobPreview = headerImagePreview;

type LoginFormData = {
  email: string;
  password: string;
  remember: boolean;
};

const fields = [
  [
    {
      name: "email",
      type: "email",
      label: "Email address",
    } as InputFormField<LoginFormData, "email">,
  ],
  [
    {
      name: "password",
      type: "password",
      label: "Password",
      noError: true,
    } as InputFormField<LoginFormData, "password">,
  ],
  [
    {
      name: "remember",
      type: "checkbox",
      label: "Remember me",
      className: `flex ${styles["LogIn-remember"]}`,
      after: {
        html: (
          <div className={styles["LogIn-forgot"]}>
            <Link
              className={`pink ${styles["LogIn-forgot-link"]} Form-label`}
              to="/forgot_password"
            >
              Forgot your password?
            </Link>
          </div>
        ),
      },
    } as InputFormField<LoginFormData, "remember">,
  ],
  [{ html: <div className="Form-row-separator" /> }],
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LocationState = any;

type LoginPageProps = {
  api: Api;
  history: History;
  location: LocationState;
};

const applyLogIn = (
  { user, access_token, signup_state, rider }: ApiLogin,
  remember: boolean,
  currentLocationState: LocationState
): LocationDescriptor<LocationState> => {
  saveLoginIdentity(user);
  trackLoginSuccess(user);
  if (remember) {
    storeAccessToken(access_token);
  } else {
    storeTemporaryAccessToken(access_token);
  }

  storeName(user.first_name);
  setContinueSignup(!isSignupComplete(signup_state));

  const { from } = currentLocationState || { from: { pathname: "/" } };

  if (from.pathname === "/details") {
    from.pathname = "/account";
  }

  let newHistory = from;

  try {
    assertRiderLoaded(rider);
    if (!rider.id || !rider.payment_method_created) {
      newHistory = {
        pathname: "/details",
        state: {
          first_name: user.first_name,
          last_name: user.last_name,
        },
      };
    }
  } catch {
    // pass
  }

  return newHistory;
};

const LoginPage: FC<LoginPageProps> = ({ api, history, location }) => {
  const apiRef = useRef(api);
  const { refresh } = useAuthDetails();

  const locationState = location.state;

  const onSubmit: SubmitHandler<LoginFormData> = useCallback(
    async (data) => {
      try {
        const response = await apiRef.current.auth({
          username: data.email,
          password: data.password,
        });
        if (response.body) {
          const newHistory = applyLogIn(
            response.body,
            data.remember,
            locationState
          );
          history.replace(newHistory);
          refresh();
        } else {
          throw { response };
        }
      } catch (err) {
        removeAccessToken();
        throw err;
      }
    },
    [refresh, history, locationState]
  );

  return (
    <div className="UserDetailsPage">
      <Header
        headerCopy="Log In"
        background={headerImage}
        backgroundPreview={headerImagePreview}
        backgroundMob={headerImageMob}
        backgroundMobPreview={headerImageMobPreview}
        className="half-height tc noBanner white-angle"
      />
      <div className={`${SignUpStyles["sign-up-container"]}`}>
        <h1 className="f3 mv5 tc">Log in to Buzzbike to continue</h1>
        <div className={`center ${styles["LogIn-form"]}`}>
          <Form<LoginFormData>
            className=""
            fields={fields}
            submitText="Log in"
            onSubmit={onSubmit}
            submitProps={{
              hasPrev: false,
              nextCopy: "Log In",
              rightArrow: false,
            }}
          />
        </div>
        <p className={`${styles["LogIn-noAccount"]} tc`}>
          Don&apos;t have an account?{" "}
          <Link className="pink" to="/signup">
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
