import React from "react";

import { AlertHandler } from "./types";

export const AccountGrid = ({
  label,
  value,
}: {
  label: string;
  value?: React.ReactChild;
}) => (
  <>
    <div key={label} className={`Account-data-label`}>
      {label}
    </div>
    <div className={`Account-data-value`}>{value}</div>
  </>
);
export type AccountDataBlockProps = {
  horizontal?: boolean;
  label: string;
  value?: React.ReactChild;
  className?: string;
};
export const AccountDataBlock = ({
  horizontal,
  label,
  value,
  className,
}: AccountDataBlockProps) => (
  <div
    className={`Account-data-single${horizontal ? "-horizontal" : ""} ${
      className || ""
    }`}
  >
    <span className={`Account-data-label`}>{label}</span>
    <span className={`Account-data-value`}>{value}</span>
  </div>
);

export const AlertContext =
  React.createContext<AlertHandler | undefined>(undefined);
