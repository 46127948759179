import "./index.scss";

import React, { ButtonHTMLAttributes } from "react";
import ReactLoading from "react-loading";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  text: React.ReactChild;
}

const Button = (props: Props) => {
  const { loading = false, text, ...rest } = props;
  const buttonText = loading ? "Please wait" : text;

  return (
    <button {...rest} className={`${props.className || ""} Button pa3 ttu f5`}>
      {loading && (
        <div className={"loading-indicator"}>
          <ReactLoading
            type={"bubbles"}
            color={"white"}
            height={"2rem"}
            width={"2rem"}
          />
        </div>
      )}
      {buttonText}
    </button>
  );
};

export default Button;
