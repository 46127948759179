import { gql } from "@apollo/client";

export const GET_POSTS = gql`
  query GetPosts {
    posts(stage: ${process.env.REACT_APP_GRAPH_CMS_STAGE}, orderBy: publishedAt_DESC) {
      id
      title
      slug
      excerpt
      author {
        id
        name
      }
      coverImage {
        id
        url(
          transformation: {
            image: { resize: { width: 1200, height: 800, fit: clip } }
          }
        )
      }
    }
  }
`;

export const GET_POST = gql`
  query GetPost($slug: String!) {
    post(where: { slug: $slug }, stage: ${process.env.REACT_APP_GRAPH_CMS_STAGE}) {
      id
      title
      slug
      author {
        id
        name
        biography
        picture {
          id
          url(
            transformation: {
              image: { resize: { width: 100, height: 100, fit: clip } }
            }
          )
        }
      }
      coverImage {
        id
        url(
          transformation: {
            image: { resize: { width: 1200, height: 800, fit: clip } }
          }
        )
      }
      excerpt
      publishedAt
      content {
        raw
      }
    }
  }
`;
