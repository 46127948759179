import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";

import { UpdatePaymentInfoForm } from "components/CheckoutForm";
import cardUpdateIcon from "static/images/icons/ccardUpdate.png";

import CustomSubmit, { CustomSubmitHandler } from "../UserDetails/customSubmit";
import { AccountDataBlock, AlertContext } from "./components";

export type PaymentDetails = {
  cardholder_name: string;
  last4: number;
  exp_month: number;
  exp_year: number;
};

const CardUpdateIcon = (
  <img src={cardUpdateIcon} alt="" style={{ width: "60px" }} />
);

const PaymentInfoSummaryTable = ({
  onStartChangePaymentDetails,
  paymentDetails,
}: {
  paymentDetails: PaymentDetails | undefined;
  onStartChangePaymentDetails: () => void;
}) => {
  const { cardholder_name, last4, exp_month, exp_year } = paymentDetails || {};
  return (
    <div key="account_payment_info_table_0" className="Account-data-wrapper">
      <div className="Account-payment">
        <h1 className="Account-header">Payment Info</h1>

        <div className="Account-payment-info">
          <AccountDataBlock
            label="Cardholder Name"
            value={cardholder_name}
            className="Account-payment-firstName"
          />
          <AccountDataBlock
            label="Card Number"
            value={`**** **** **** ${last4}`}
            className="Account-payment-cardNo"
          />
          <AccountDataBlock
            label="Expiry date"
            value={`${exp_month}/${exp_year ? exp_year % 100 : "??"}`}
            className="Account-payment-expiry"
          />
          <a
            className="Account-payment-edit"
            onClick={onStartChangePaymentDetails}
          >
            EDIT PAYMENT INFO
          </a>
        </div>
      </div>
    </div>
  );
};

const EditPaymentInfoTable = ({
  nextDisabled,
  paymentDetails,
  onGoBackToHomeScreen,
  onPaymentDetailsUpdated,
}: {
  paymentDetails: PaymentDetails | undefined;
  nextDisabled?: boolean;
  onGoBackToHomeScreen: () => void;
  onPaymentDetailsUpdated: (details: PaymentDetails) => void;
}) => {
  const alertFn = React.useContext(AlertContext);
  const triggerFormSubmitFn = React.useRef<undefined | (() => void)>(undefined);

  const stripe = React.useRef(
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY || "")
  );

  const setTriggerFormSubmitFn = (fn: () => void) => {
    triggerFormSubmitFn.current = fn;
  };

  const onSubmitUpdatePaymentDetails: CustomSubmitHandler = async (
    e,
    { direction }
  ) => {
    if (triggerFormSubmitFn.current && direction === "next") {
      return triggerFormSubmitFn.current();
    } else {
      return onGoBackToHomeScreen();
    }
  };

  const handleUpdatedPaymentInfo = (data: PaymentDetails) => {
    onPaymentDetailsUpdated(data);
    onGoBackToHomeScreen();
    if (alertFn) {
      alertFn("Your payment details have been updated.", "", CardUpdateIcon);
    }
  };

  return (
    <div key="account_payment_info_table_0" className="Account-data-wrapper">
      <div className="Account-payment">
        <h1 className="Account-header">Edit Payment Info</h1>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Elements stripe={stripe.current}>
            <UpdatePaymentInfoForm
              data={paymentDetails}
              onCreated={setTriggerFormSubmitFn}
              onSuccess={handleUpdatedPaymentInfo}
              onFailure={() => {}}
              displayButton={false}
              buttonText="Save Payment Method"
              showError={true}
              disablePaymentRequestButton={false}
            />
          </Elements>
          <CustomSubmit
            onSubmit={onSubmitUpdatePaymentDetails}
            prevCopy="Cancel"
            nextCopy="Confirm"
            leftArrow={false}
            rightArrow={false}
            nextDisabled={nextDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export const ManagePayments = ({
  paymentDetails,
  nextDisabled,
  onPaymentDetailsUpdated,
}: {
  paymentDetails: PaymentDetails | undefined;
  nextDisabled?: boolean;
  onPaymentDetailsUpdated: (details: PaymentDetails) => void;
}) => {
  const [changingPaymentDetails, setChangingPaymentDetails] = React.useState(0);

  const startChangePaymentDetails = React.useCallback(() => {
    const newStep = Math.min(1, changingPaymentDetails + 1);
    setChangingPaymentDetails(newStep);
  }, [changingPaymentDetails]);

  const goBackToHomeScreen = React.useCallback(() => {
    setChangingPaymentDetails(0);
  }, []);

  switch (changingPaymentDetails) {
    case 0:
      return (
        <PaymentInfoSummaryTable
          paymentDetails={paymentDetails}
          onStartChangePaymentDetails={startChangePaymentDetails}
        />
      );
    default:
      return (
        <EditPaymentInfoTable
          paymentDetails={paymentDetails}
          nextDisabled={nextDisabled}
          onPaymentDetailsUpdated={onPaymentDetailsUpdated}
          onGoBackToHomeScreen={goBackToHomeScreen}
        />
      );
  }
};
