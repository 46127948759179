import React, { useCallback, useMemo, useState } from "react";
import { SEGMENT_EVENT } from "types";

import { LOWEST_SUBSCRIPTION_PRICE } from "constants/index";
import WhatsIncludedTable from "pages/Account/InterstitialCancellation/components/WhatsIncludedTable";
import { useCancellationFunnelTracking } from "pages/Account/tracking";

import CanWeHelp from "./components/CanWeHelp";
import ComparisionTable from "./components/ComparisionTable";

export interface InterstitialCancellationProps {
  onCancel: () => void;
  onContinue: () => void;
  cancellationStep: number;
}

function InterstitialCancellation(props: InterstitialCancellationProps) {
  const { onCancel, onContinue: onContinueProp, cancellationStep } = props;

  useCancellationFunnelTracking(cancellationStep, "Interstitial");

  const [step, setStep] = useState(0);

  const onContinue = useCallback(() => {
    setStep((step) => ++step);
  }, []);

  const claimDiscount = useCallback(() => {
    window.analytics.track(SEGMENT_EVENT.CLAIM_MY_DISCOUNT);
    const emailContent =
      "mailto:hello@buzzbike.cc?" +
      "subject=Cancellation: Claim my discount" +
      "&body=Dear Rider Happiness Team,\n\n" +
      "I would like to accept the offer of a discounted rate of £14.99 for the next 2 months.\n\n" +
      "Please confirm that this new rate has been applied to my account.\n\n" +
      "Thank you.";
    window.open(encodeURI(emailContent));
  }, []);

  const resolveIssue = useCallback(() => {
    const emailContent =
      "mailto:hello@buzzbike.cc?" +
      "subject=Help solve my issue" +
      "&body=Dear Rider Happiness Team,\n\n" +
      "I was in the process of cancelling because I am unhappy with the service.\n\n" +
      "My specific problem was <insert issue here>\n\n" +
      "Can you help?\n\n" +
      "Thanks.";
    window.open(encodeURI(emailContent));
  }, []);

  const comparisionData = useMemo(
    () => ({
      Cost: [
        "£700",
        LOWEST_SUBSCRIPTION_PRICE
          ? `from £${LOWEST_SUBSCRIPTION_PRICE}/month`
          : "Just £29.99 / Month",
      ],
      "Maintenance and parts": ["£150*", "£0"],
      "Theft protection": ["£72**", "£0"],
      "Gold standard lock": ["£70", "£0"],
      "Flexibility - cancel any time": [false, true],
      "Discount on essential kit": [false, true],
      "Ongoing advice & support": [false, true],
      "Motivation & prizes": [false, true],
    }),
    []
  );

  const whatsIncludedData = useMemo(
    () => ({
      "A Buzzbike 24/7": true,
      "Protection against theft": true,
      "On-demand maintenance": true,
      "Award winning lock": true,
      "Heavily discounted kit": true,
      "Rewards the more you ride": true,
    }),
    []
  );

  switch (step) {
    case 0:
      return (
        <ComparisionTable
          data={comparisionData}
          onContinue={onContinue}
          onCancel={onCancel}
        />
      );

    case 1:
      return (
        <WhatsIncludedTable
          data={whatsIncludedData}
          onContinue={onContinue}
          onCancel={claimDiscount}
        />
      );

    case 2:
      return <CanWeHelp onContinue={onContinueProp} onCancel={resolveIssue} />;
  }
  return null;
}

export default InterstitialCancellation;
