import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { GET_POST } from "graphql-root/graphcms";
import React from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { getDeepValue } from "utils";

import BlogAuthor from "components/BlogPage/components/BlogAuthor";
import BlogFooter from "components/BlogPage/components/BlogFooter";
import BlogImage from "components/BlogPage/components/BlogImage";
import BlogItem from "components/BlogPage/components/BlogItem";
import { Blog, Item } from "components/BlogPage/types";
import PreFooter from "components/PreFooter/PreFooter";

import styles from "./BlogPage.module.scss";
import BlogMeta from "./components/BlogMeta";

type RouteParams = {
  slug?: string;
};

function BlogPage() {
  const routeParams = useParams<RouteParams>();
  const { loading, error, data } = useQuery<Blog>(GET_POST, {
    variables: {
      slug: routeParams.slug,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={"Header"} />
        <ReactLoading
          type={"bubbles"}
          color={"#ff4074"}
          height={"4rem"}
          width={"4rem"}
        />
      </div>
    );
  }

  if (error) {
    Sentry.captureException(error);
    return null;
  }

  const post = getDeepValue<Blog>(data, "post");

  const {
    title,
    excerpt,
    coverImage,
    content,
    author,
    publishedAt = "",
  } = post || {};

  const blogItems = getDeepValue<Item[]>(content, "raw.children", []) || [];

  return (
    <>
      <div className={"Header"} />
      {post && <BlogMeta data={post} />}
      <div className={styles.container}>
        <h2 className={styles.title}>{title}</h2>
        {author && (
          <BlogAuthor
            className={styles.author}
            author={author}
            publishedAt={publishedAt}
          />
        )}
        {coverImage && (
          <BlogImage image={coverImage} className={styles.mainImage} />
        )}
        <span className={styles.excerpt}>{excerpt}</span>
        <div className={styles.body}>
          {blogItems.map((item, index) => (
            <BlogItem key={index} item={item} />
          ))}
        </div>
        <BlogFooter className={styles.footer} author={author} />
      </div>
      <PreFooter
        headingText={"Start Cycling today."}
        linkTo={"/signup"}
        buttonText={"Sign up now"}
      />
    </>
  );
}

export default BlogPage;
