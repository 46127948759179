import "./index.scss";

import React from "react";

const CONSENT_KEY = "COOKIE_CONSENT";

const isMob = () => global.innerWidth < 769;

const content = isMob()
  ? "This site uses cookies. Our "
  : "Our website uses cookies to ensure that we give you the best " +
    "experience on and off our website. By clicking “I AGREE”, you " +
    "agree to the storing of first- and third-party cookies. To learn " +
    "more, read our";

class CookieBanner extends React.Component {
  state = { consent: !!localStorage.getItem(CONSENT_KEY) };

  componentDidMount() {
    document.addEventListener("storage", this.onStorage);
  }

  componentWillUnmount() {
    document.removeEventListener("storage", this.onStorage);
  }

  onStorage = () =>
    this.setState({ consent: !!localStorage.getItem(CONSENT_KEY) });

  agree = (e) => {
    e.preventDefault();
    localStorage.setItem(CONSENT_KEY, true);
    this.setState({
      consent: true,
    });
  };

  render() {
    if (this.state.consent) {
      return null;
    }
    return (
      <div className="CookieBanner">
        <div className="Form-row">
          <div
            className="Form-row-wrapper flex-column"
            style={{ paddingRight: "2rem" }}
          >
            <div style={{ lineHeight: "1.5" }}>
              {!isMob() && "Cookie policy:"}
              {!isMob() && <br />}
              <div
                style={{
                  textAlign: "justify",
                  textJustify: "inter-character",
                  lineHeight: "1.5",
                  whiteSpace: "pre-line",
                  fontSize: "1rem",
                }}
              >
                {content}{" "}
                <a
                  href={`${window.location.origin}/termsofuse`}
                  className="pink"
                  style={{
                    lineHeight: "1.5",
                    fontSize: "1rem",
                  }}
                >
                  Terms and conditions
                </a>
              </div>
            </div>
          </div>
          <a className="CookieBanner-agree" href="#" onClick={this.agree}>
            I agree
          </a>
        </div>
      </div>
    );
  }
}

export default CookieBanner;
