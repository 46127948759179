import React, { useCallback, useRef } from "react";
import ReactTooltip from "react-tooltip";

export interface CopyableTextProps {
  text: string;
  className: string;
}

function CopyableText(props: CopyableTextProps) {
  const { text, className } = props;
  const targetRef = useRef<HTMLSpanElement>(null);
  const tooltipId = `copyable-text-${Date.now()}`;

  const onClick = useCallback(() => {
    if (targetRef.current) {
      ReactTooltip.hide(targetRef.current);
    }
    navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <>
      <span
        ref={targetRef}
        data-tip="Copied!"
        data-for={tooltipId}
        className={className}
        onClick={onClick}
      >
        {text}
      </span>
      <ReactTooltip
        id={tooltipId}
        clickable={true}
        event={"click"}
        effect={"solid"}
        afterShow={onClick}
        delayHide={1000}
        place={"top"}
      />
    </>
  );
}

export default CopyableText;
